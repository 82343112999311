import { ButtonContainer } from '@data-c/ui'

import { FormaPagamentoModel } from 'hooks/queries/useFormaPagamento'
import Table from './components/Table'
import Form from './components/Form'
import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'

export interface TabCondicoesPagamentoProps {
  formaPagamento: FormaPagamentoModel
}

export default function TabCondicoesPagamento(
  props: TabCondicoesPagamentoProps,
) {
  const { formaPagamento } = props
  return (
    <>
      <ButtonContainer sx={{ mb: 2 }}>
        <FormProvider>
          <Form formaPagamento={formaPagamento} />
        </FormProvider>
      </ButtonContainer>

      <Table formaPagamento={formaPagamento} />
    </>
  )
}
