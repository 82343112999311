import { useEffect } from 'react'

import { onlyNumbers } from '@data-c/hooks'
import { useFormApi, useFormData } from '@data-c/providers'
import {
  Checkbox,
  FormContainer,
  ButtonContainer,
  Button,
} from '@data-c/ui'

import { Grid2 as Grid } from '@mui/material'
import { isCPFOrCNPJ } from 'brazilian-values'

import { CidadeModel } from 'hooks/queries/useCidade'
import useVendedores, { VendedorModel } from 'hooks/queries/useVendedores'
import { CepModel } from 'hooks/useConsultaCep'
import useValidations from 'hooks/useValidations'

import AutoCompleteCidade from 'components/AutoCompleteCidade'
import CEPTextField from 'components/CEPTextField'
import DropDownUF from 'components/Inputs/DropDownUF'

import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import TextField from 'components/Inputs/TextField'
import DocumentoTextField from 'components/DocumentoTextField'
import { CnpjRetornoModel } from 'hooks/useConsultaCnpj'
import TelefoneTextField from 'components/TelefoneTextField'
import Divider from 'components/Divider'
// import CondicaoPagamentoTransporter from 'components/Transporters/CondicaoPagamentoTransporter'
import FormProps from 'interfaces/FormProps'

const schema = yup.object().shape(
  {
    cnpjcpf: yup
      .string()
      .required('Informe um CNPJ/CPF')
      .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
      .test(
        'CPF/CNPJ-Validator',
        'Informe um CNPJ/CPF válido',
        (cnpjcpf: any) => {
          return isCPFOrCNPJ(cnpjcpf)
        },
      ),
    cep: yup.string().nullable(),
    nome: yup.string().when('usual', {
      is: (value: string) => !value || value.length === 0,
      then: yup.string().required('Informe a razão social ou o nome fantasia'),
      otherwise: yup.string().nullable(),
    }),
    usual: yup.string().when('nome', {
      is: (value: string) => !value || value.length === 0,
      then: yup.string().required('Informe a razão social ou o nome fantasia'),
      otherwise: yup.string().nullable(),
    }),
  },
  [['nome', 'usual']],
)

function extractDDD(phoneNumber: string) {
  const ddd = phoneNumber.substring(0, 2)
  const number = phoneNumber.substring(2)
  return { ddd, number }
}

export default function Form(props: FormProps<VendedorModel>) {
  const { view, data: vendedor } = props
  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    toggleSubmit,
    closeForm,
  } = useFormApi<VendedorModel>()
  const {
    formValues: data,
    isOpen,
    isSubmitting,
  } = useFormData<VendedorModel>()
  const { validationProps, setValidationErrors } = useValidations()
  const { useSubmit } = useVendedores()
  const { mutateAsync } = useSubmit()

  const navigate = useNavigate()

  useEffect(() => {
    if (vendedor) {
      changeValues(vendedor)
    }
  }, [vendedor])

  useEffect(() => {
    if (data?.cidadeModel?.uf) {
      changeValue('uf', data.cidadeModel.uf)
    }
  }, [changeValue, data?.cidadeModel?.uf, data?.uf, isOpen])

  function handleSubmitForm(formValues: VendedorModel, isUpdate?: boolean) {
    setValidationErrors(null)
    schema
      .validate(formValues, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        delete formValues?.uf

        await mutateAsync({
          ...formValues,
          isvendedor: 1,
        })
        closeForm()
        if (isUpdate) {
          navigate(`/vendedores/vendedor/${formValues.uuid}`)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      view={view}
      dialogProps={{
        title: 'Cadastro de Vendedores',
        maxWidth: 'lg',
        disableRestoreFocus: true,
      }}
      actions={
        <ButtonContainer>
          {view === 'dialog' && (
            <Button color="error" onClick={closeForm}>
              Cancelar
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => handleSubmitForm(data)}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </ButtonContainer>
      }
    >
      <Grid container spacing={2} sx={{ pb: 2, pt: 0.5 }}>
        <Grid size={{ xs: 3, sm: 3, md: 2, lg: 2, xl: 2 }}>
          <TextField
            disabled
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
            onChange={onChangeFormValue}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 9, md: 4, lg: 4, xl: 4 }}>
          <DocumentoTextField
            name="cnpjcpf"
            disabled={Boolean(data?.uuid)}
            value={data?.cnpjcpf || ''}
            onChange={(e) => {
              const cnpjcpf = onlyNumbers(e.target.value)
              changeValue('cnpjcpf', cnpjcpf)
            }}
            onDocumentoFound={(value: CnpjRetornoModel) => {
              const { ddd, number } = extractDDD(value.telefone || '')

              changeValues({
                ...data,
                nome: value.nome,
                usual: value.fantasia,
                numero: value.numero,
                complemento: value.complemento,
                bairro: value.bairro,
                cep: value.cep,
                email: value.email,
                telefoneAsText: value.telefone,
                ddd: ddd,
                telefone: number,
                // endereco: cep.endereco,
                // bairro: cep.bairro,
                // cidadeModel: cep.cidadeModel,
                // cidade: cep.cidadeModel?.uuid,
              })
            }}
            inputProps={{
              label: 'CPF/CNPJ',
              // InputProps: { disabled: !podeEditar },
            }}
            {...validationProps('cnpjcpf')}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
          <TextField
            autoFocus
            name="usual"
            label="Nome"
            value={data?.usual || ''}
            onChange={onChangeFormValue}
            {...validationProps('usual')}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Divider label="Local / Contato" />
        </Grid>

        <Grid size={{ xs: 12, sm: 4, md: 2, lg: 2, xl: 2 }}>
          <CEPTextField
            name="cep"
            inputProps={{ label: 'CEP' }}
            value={data?.cep || ''}
            onChange={(e) => {
              const cep = onlyNumbers(e.target.value)
              changeValue('cep', cep)
            }}
            {...validationProps('cep')}
            onFindCep={(cep: CepModel) => {
              if (cep) {
                changeValues({
                  ...data,
                  endereco: cep.endereco,
                  bairro: cep.bairro,
                  // complemento: cep.complemento,
                  cidadeModel: cep.cidadeModel,
                  cidade: cep.cidadeModel?.uuid,
                })
              }
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 8, md: 5, lg: 5, xl: 5 }}>
          <DropDownUF
            name="uf"
            label="Estado"
            value={data?.uf || ''}
            onChange={(e) => {
              const uf = e.target.value
              changeValue('uf', uf)
              changeValue('cidade', null)
              changeValue('cidadeModel', null)
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }}>
          <AutoCompleteCidade
            name="cidade"
            label="Cidade"
            value={data?.cidadeModel}
            uf={data?.uf || null}
            onChange={(_, value: CidadeModel | null) => {
              const cidadeId = value?.uuid || null
              const cidadeModel = value || null
              changeValue('cidade', cidadeId)
              changeValue('cidadeModel', cidadeModel)
            }}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 5, lg: 5, xl: 5 }}>
          <TextField
            name="endereco"
            label="Endereço"
            value={data?.endereco || ''}
            inputProps={{ maxLength: 40 }}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <TextField
            name="numero"
            label="Número"
            value={data?.numero || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }}>
          <TextField
            name="bairro"
            label="Bairro"
            value={data?.bairro || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <TextField
            name="complemento"
            label="Complemento"
            value={data?.complemento || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <TextField
            name="contato"
            label="Contato"
            value={data?.contato || ''}
            inputProps={{ maxLength: 30 }}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <TelefoneTextField
            name="telefoneAsText"
            inputProps={{
              label: 'Telefone',
            }}
            value={data?.telefoneAsText || ''}
            onChange={(e) => {
              const telefone = onlyNumbers(e.target.value)
              const { ddd, number } = extractDDD(telefone || '')
              changeValues({
                ...data,
                telefoneAsText: telefone,
                ddd: ddd,
                telefone: number,
              })
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <TelefoneTextField
            name="celularAsText"
            inputProps={{
              label: 'Celular',
            }}
            value={data?.celularAsText || ''}
            onChange={(e) => {
              const celular = onlyNumbers(e.target.value)
              const { ddd, number } = extractDDD(celular || '')
              changeValues({
                ...data,
                celularAsText: celular,
                dddcelular: ddd,
                celular: number,
              })
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <TextField
            name="email"
            label="E-mail"
            value={data?.email || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            name="observacao"
            label="Observação"
            multiline
            rows={4}
            value={data?.observacao || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          sx={{ textAlign: 'right' }}
        >
          <Checkbox
            label="Habilitar Cliente"
            name="iscliente"
            onChange={onChangeFormValue}
            checked={Boolean(data?.iscliente)}
          />

          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Divider label="Configurações" />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <CondicaoPagamentoTransporter
              // clienteUuid={data.uuid}
              value={data?.condicaoPagamento || null}
              onChange={(value) => {
                changeValues({
                  ...data,
                  condicaoPagamento: value || null,
                  condicaoPagamentoUuid: value?.uuid || null,
                })
              }}
            />
          </Grid> */}
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}