import { TableFooterProps, TableFooter } from '@mui/material'
export default function Footer(props: TableFooterProps) {
  return (
    <TableFooter
      {...props}
      sx={{
        height: '54px',
        paddingX: '12px',
        gap: '12px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderTop: '1px solid #edf2f9',
      }}
    />
  )
}
