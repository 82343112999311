import { useCallback, useMemo } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { TransportableDataTableProps, usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useClasseProdutos, {
  ClasseProdutosModel,
} from 'hooks/queries/useClasseProdutos'
import { useDialogs } from '@toolpad/core/useDialogs'
import Form from '../Form'

export default function Table(
  props: TransportableDataTableProps<ClasseProdutosModel>,
) {
  const dialog = useDialogs()
  const { query, onTransport, enableTransporter } = props
  const { changePage, changePageSize, pagination } = usePagination()
  const { openForm, openConfirm } = useFormApi()
  const { useQuery } = useClasseProdutos()
  const { data, isLoading, isFetching } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination,
  })

  const findData = useCallback(
    (value: string): ClasseProdutosModel | undefined => {
      return data?.data.find((d: ClasseProdutosModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: ClasseProdutosModel) => {
      switch (event) {
        case 'edit':
          dialog.open(Form, data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm, onTransport],
  )
  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps() {
            return { style: { width: '96px', textAlign: 'center' } }
          },
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, enableTransporter],
  )

  return (
    <DataTable
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
