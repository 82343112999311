import { Checkbox, theme } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { ItemTabelaPrecoFilter1 } from 'hooks/queries/useTabelaPreco'
export default function FilterForm() {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { data: configuracoes } = useQuery()
  const { filterValues } = useFilter<ItemTabelaPrecoFilter1>()

  return (
    <Stack spacing={2}>
      <TextField
        autoFocus
        name="codigoProduto"
        value={filterValues?.codigoProduto}
        label="Codigo do Produto"
        onChange={onChangeFilterValue}
      />
      <TextField
        name="nomeProduto"
        value={filterValues?.nomeProduto}
        label="Nome do Produto"
        onChange={onChangeFilterValue}
      />
      {configuracoes?.modulo_pedido === 'COR' ||
        (configuracoes?.modulo_pedido === 'GRADE' && (
          <TextField
            name="codigoNomeCor"
            value={filterValues?.codigoNomeCor}
            label={'Variação 1'}
            onChange={onChangeFilterValue}
            placeholder="Código ou nome da variação 1"
          />
        ))}

      {configuracoes?.modulo_pedido === 'GRADE' && (
        <TextField
          name="codigoNomeGrade"
          value={filterValues?.codigoNomeGrade}
          label={'Variação 2'}
          onChange={onChangeFilterValue}
          placeholder="Código ou nome da variação 2"
        />
      )}

      {Boolean(configuracoes?.usa_grade_fechada) && (
        <Checkbox
          name="apenasQuantidadeMinima"
          label="Apenas grade fechada"
          checked={filterValues?.apenasQuantidadeMinima}
          onChange={onChangeFilterValue}
        />
      )}

      <Stack
        sx={{
          gap: 2,
          border: `solid 1px ${theme.palette.primary.main}`,
          padding: 2,
          borderRadius: '4px',
        }}
      >
        <TextField
          select
          name="tipoCategoria"
          label="Tipo Categoria"
          value={filterValues?.categoriaKey || 'nenhuma'}
          onChange={(e) => {
            const key = e.target.value
            changeFilterValue('categoriaKey', key)
          }}
        >
          <MenuItem value="nenhuma">Todas</MenuItem>
          <MenuItem value="classe">Classe</MenuItem>
          <MenuItem value="familia">Família</MenuItem>
          <MenuItem value="grupo">Grupo</MenuItem>
          <MenuItem value="linha">Linha</MenuItem>
        </TextField>

        {filterValues?.categoriaKey === 'classe' && (
          <ClasseProdutoTransporter
            name="categoria"
            value={filterValues?.categoriaValue || null}
            onChange={(value: ClasseProdutosModel | null) => {
              changeFilterValue('categoriaValue', value)
            }}
          />
        )}

        {filterValues?.categoriaKey === 'familia' && (
          <FamiliaProdutoTransporter
            name="categoria"
            value={filterValues?.categoriaValue || null}
            onChange={(value: FamiliaProdutosModel | null) => {
              changeFilterValue('categoriaValue', value)
            }}
          />
        )}

        {filterValues?.categoriaKey === 'grupo' && (
          <GrupoProdutoTransporter
            name="categoria"
            value={filterValues?.categoriaValue || null}
            onChange={(value: GrupoProdutosModel | null) => {
              changeFilterValue('categoriaValue', value)
            }}
          />
        )}

        {filterValues?.categoriaKey === 'linha' && (
          <LinhaProdutoTransporter
            name="categoria"
            value={filterValues?.categoriaValue || null}
            onChange={(value: LinhaProdutosModel | null) => {
              changeFilterValue('categoriaValue', value)
            }}
          />
        )}
      </Stack>

      <Checkbox
        name="apenasQuantidadeMinima"
        label="Apenas grade fechada"
        checked={filterValues?.apenasQuantidadeMinima}
        onChange={onChangeFilterValue}
      />
    </Stack>
  )
}
