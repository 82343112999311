import { GetApp as TransportIcon } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'

interface CellActionTransportProps {
  disabled?: boolean
  title?: string
  onClick?: (event: 'transport') => void
}

export default function CellActionTransport({
  onClick,
  ...rest
}: CellActionTransportProps) {
  return (
    <GridActionsCellItem
      icon={<TransportIcon />}
      label="Transport"
      color="primary"
      onClick={() => onClick && onClick('transport')}
      {...rest}
    />
  )
}
