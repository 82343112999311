import { useFormApi, useFormData } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'
import { Button } from '@data-c/ui'
import { FormContainer } from '@data-c/ui'
import { Alert, Collapse, Grid2 as Grid, Stack, TextField } from '@mui/material'
import axios, { AxiosError } from 'axios'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import {
  Configs,
  UsuarioModel,
  useCadastrarUsuario,
  useVincularUsuario,
} from 'hooks/queries/useMioAuth'
import { TimeVendasModel } from 'hooks/queries/useTimeVendas'
import { onlyNumbers, useCredentials } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import useNotification from 'hooks/useNotifications'
import useValidations from 'hooks/useValidations'
import { useState } from 'react'
import * as yup from 'yup'
import DocumentoTextField from 'components/DocumentoTextField'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Informe um e-mail válido')
    .nullable(true)
    .required('Informe o email'),
  name: yup.string().nullable(true).required('Informe o Nome'),
  documento: yup.string().nullable(true).required('Informe o CNPJ/CPF'),
})

export default function Form() {
  const { setValidationErrors, validationProps } = useValidations()
  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    toggleSubmit,
    closeForm,
  } = useFormApi<UsuarioModel>()
  const { formValues: data } = useFormData<UsuarioModel>()
  const [vendedor, setVendedor] = useState<TimeVendasModel | null>(null)
  const [apenasVincularForm, setApenasVincular] = useState<boolean>(false)
  const { userLogged } = useCredentials(credentialsConfig)
  const { mutateAsync: cadastrarUsuario } = useCadastrarUsuario()
  const { mutateAsync: vincularUsuario } = useVincularUsuario()
  const notifications = useNotification()

  const handleSubmitPermissao = async () => {
    toggleSubmit(true)
    try {
      await vincularUsuario({
        empresaUuid: userLogged?.empresaSelecionada || '',
        usuario: data,
      })
      notifications.notifySuccess(
        'Permissão para acessar o Força de Vendas com sucesso',
      )
      setApenasVincular(false)
      closeForm()
    } catch (e) {
      notifications.notifyException(e)
    } finally {
      toggleSubmit(false)
    }
  }

  const handleSubmit = async () => {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        try {
          await cadastrarUsuario({
            empresaUuid: userLogged?.empresaSelecionada || '',
            usuario: data,
          })
          notifications.notifySuccess(
            'A conta do usuário foi criada e vinculada com sucesso. Vamos enviar um email com as informações de acesso.',
          )
          closeForm()
        } catch (e) {
          if (axios.isAxiosError(e)) {
            const axiosError = e as AxiosError
            if (axiosError.response?.status === 400) {
              setApenasVincular(true)
              return
            }
          }
          notifications.notifyException(e)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      dialogProps={{ title: 'Vincular Usuário', maxWidth: 'sm' }}
      actions={
        <ButtonContainer>
          <Button
            onClick={() => {
              if (apenasVincularForm) {
                setApenasVincular(false)
              } else {
                closeForm()
              }
            }}
            color="error"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (apenasVincularForm) {
                handleSubmitPermissao()
              } else {
                handleSubmit()
              }
            }}
            variant="contained"
          >
            {apenasVincularForm ? 'Sim' : 'Vincular'}
          </Button>
        </ButtonContainer>
      }
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TimeVendasTransporter
            disabled={apenasVincularForm}
            label="Usuário do Força de Vendas"
            onChange={(value: TimeVendasModel | null) => {
              setVendedor(value)

              let configs: Configs | null = null

              if (value) {
                configs = {
                  fdv_empresa_id:
                    userLogged?.empresa.configs.fdv_empresa_id || -1,
                  fdv_vendedor_codigo: value.codigo,
                  fdv_vendedor_uuid: value?.uuid || '',
                }
              }

              changeValues({
                ...data,
                name: value?.nome || null,
                email: value?.email || null,
                documento: value?.cnpjcpf || null,
                permissao: { configs },
              })
            }}
            value={vendedor}
          />
        </Grid>
      </Grid>

      <Stack paddingTop={2}>
        <Collapse in={Boolean(vendedor)}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <DocumentoTextField
                name="documento"
                required
                value={data?.documento || ''}
                onChange={(e) => {
                  const cnpjcpf = onlyNumbers(e.target.value)
                  changeValue('documento', cnpjcpf)
                }}
                inputProps={{
                  label: 'CNPJ/CPF',
                  ...validationProps('documento'),
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <TextField
                disabled={apenasVincularForm}
                name="email"
                label="E-mail"
                required
                value={data?.email || ''}
                onChange={onChangeFormValue}
                inputProps={{ maxLength: 40 }}
                {...validationProps('email')}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <TextField
                disabled={apenasVincularForm}
                name="name"
                label="Nome"
                required
                value={data?.name || ''}
                onChange={onChangeFormValue}
                inputProps={{ maxLength: 40 }}
                {...validationProps('name')}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Stack>
      <Collapse in={Boolean(apenasVincularForm)}>
        <Stack paddingTop={2}>
          <Alert severity="warning">
            Este usuário/email já possui uma Conta Data C mas ainda não possui
            permissão para acessar o aplicativo Força de Vendas. <br />
            <br />
            <b>
              Você quer dar permissão para o usuário acessar o aplicativo Força
              de Vendas?
            </b>
          </Alert>
        </Stack>
      </Collapse>
    </FormContainer>
  )
}