import { ReactNode, useEffect, useRef, useState } from 'react'
import { Stack, StackProps } from '@mui/material'
import TableRootProvider from './DataTableRootProvider'

interface RootProps extends StackProps {
  children: ReactNode
}

export default function Root({ children, ...rest }: RootProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number>(0)

  useEffect(() => {
    const calculateHeight = () => {
      const container = tableContainerRef.current
      if (!container) return

      const containerTop = container.getBoundingClientRect().top
      setTableHeight(window.innerHeight - containerTop)
    }

    calculateHeight()
    window.addEventListener('resize', calculateHeight)

    return () => window.removeEventListener('resize', calculateHeight)
  }, [])

  return (
    <Stack
      ref={tableContainerRef}
      sx={{
        backgroundColor: '#f4f6fa',
        border: '1px solid #edf2f9',
        borderRadius: 2,
        height: `calc(${tableHeight}px - 16px)`,
        ...rest.sx,
      }}
      {...rest}
    >
      <TableRootProvider>{children}</TableRootProvider>
    </Stack>
  )
}
