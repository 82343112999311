import { ButtonContainer, Content } from '@data-c/ui'

import MioFilter from 'components/MioCandidate/Filter'
import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
interface TabPrecosProps {
  tabelaPrecoUuid: string
}
import { filtersItens1 } from 'hooks/queries/useTabelaPreco'

export default function TabPrecos(props: TabPrecosProps) {
  const { tabelaPrecoUuid } = props

  return (
    <MioFilter.Provider
      filterValues={{ categoriaKey: filtersItens1.categoriaKey }}
    >
      <ButtonContainer sx={{ mb: 2 }}>
        <Filter />
        <Form tabelaPrecoUuid={tabelaPrecoUuid} />
      </ButtonContainer>
      <Content style={{ margin: 1 }}>
        <Table tabelaPrecoUuid={tabelaPrecoUuid} />
      </Content>
    </MioFilter.Provider>
  )
}
