import { useObterUsuarios } from 'hooks/queries/useMioAuth'
import { useCredentials } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { LabelValue, Surface } from '@data-c/ui'
import Divider from 'components/Divider'

export interface MioAuthAccountProps {
  clienteUuid: string
}

export default function MioAuthAccount({ clienteUuid }: MioAuthAccountProps) {
  const { userLogged } = useCredentials(credentialsConfig)
  const { data, isLoading } = useObterUsuarios(
    userLogged?.empresaSelecionada || '',
    {
      plainQuery: '',
    },
    'app-fdv-mais',
  )

  const contaMio = data?.find(
    (c) => c.permissao?.configs?.fdv_vendedor_uuid === clienteUuid,
  )

  return (
    <Stack>
      {isLoading && (
        <Stack spacing={1} direction="row" alignItems="center">
          <CircularProgress size={16} />
          <Typography variant="subtitle2">Carregando...</Typography>
        </Stack>
      )}

      <>
        <Stack>
          <Typography sx={{ fontWeight: 'bolder', fontSize: '11pt' }}>
            Central de Contas Data C
          </Typography>
          <Typography
            sx={{ fontSize: '10pt', color: (theme) => theme.palette.grey[500] }}
          >
            O cliente está cadastrado na Central de Contas Data C com as
            informações abaixo
          </Typography>
          <Divider />
        </Stack>
        <Stack sx={{ mt: 1 }} direction="row" spacing={2}>
          <LabelValue
            showDivider
            labelProps={{
              fontSize: '11pt',
              color: (theme) => theme.palette.grey[500],
            }}
            label="Nome"
          >
            {contaMio?.name}
          </LabelValue>
          <LabelValue
            labelProps={{
              fontSize: '11pt',
              color: (theme) => theme.palette.grey[500],
            }}
            label="E-mail"
          >
            {contaMio?.email}
          </LabelValue>
        </Stack>
      </>
    </Stack>
  )
}
