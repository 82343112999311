import { findData, formatCurrency } from '@data-c/hooks'
import { CurrencyCellStyle, DataTable, DataTableOptions } from '@data-c/ui'
import { encontrarTipoVariacao } from 'hooks/business/pedido'
import { ItemPedidoModel, PedidoModel } from 'hooks/queries/usePedidos'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo } from 'react'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'

export interface DesktopViewProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  configuracoes?: ConfiguracoesModel
  onClickItem: (event: 'edit' | 'delete', data: ItemPedidoModel) => void
  podeEditar: boolean
}

export default function DesktopView(props: DesktopViewProps) {
  const { pedido, usuarioLogado, configuracoes, onClickItem, podeEditar } =
    props
  const maiorTipoVariacao = encontrarTipoVariacao(pedido.itens)
  let pesoTotalDoPedido = 0
  const _itens = useMemo(
    () =>
      pedido?.itens
        ? pedido.itens.map((item) => {
            pesoTotalDoPedido += item.pesoTotal || 0
            return {
              ...item,
              codigoProduto: item.produto?.codigo,
              nomeProduto: item.produto?.nome,
              nomeCor: item.cor?.nome,
              precoTotalLiquido: formatCurrency(item.precoTotalLiquido),
              precoUnitarioLiquido: formatCurrency(item.precoUnitarioLiquido),
              precoDeTabela: formatCurrency(item.precoDeTabela),
              precoTotalIcms: formatCurrency(item.precoTotalIcms),
              precoTotalIpi: formatCurrency(item.precoTotalIpi),
              precoTotalComImposto: formatCurrency(item.precoTotalLiquido),
              percentualDesconto: formatCurrency(item.percentualDesconto),
              fatorPrecificacaoNome: item.fatorPrecificacao?.descricao,
              pesoAsText: `${item?.pesoTotal || ''} Kg`,
            }
          })
        : [],
    [pedido],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
        options: {
          setCellProps: () => ({
            style: {
              minWidth: '240px',
            },
          }),
        },
      },
      {
        name: 'nomeCor',
        label: 'Variação 1',
        options: {
          display: maiorTipoVariacao > 0,
          setCellProps: () => ({
            style: {
              // minWidth: '240px',
            },
          }),
        },
      },
      {
        name: 'previsaoCompraAsBrazilianDate',
        label: 'Previsão',
        options: {
          display: Boolean(configuracoes?.exibe_previsaocompra),
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'pesoAsText',
        label: `Peso (${pesoTotalDoPedido} Kg)`,
        options: {
          display: Boolean(configuracoes?.exibe_peso),
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'fatorPrecificacaoNome',
        label: 'Fator',
        options: {
          display: Boolean(configuracoes?.usa_fator),
          setCellProps: () => ({
            style: {
              width: '96px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'precoDeTabela',
        label: 'Preço de Tabela',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'percentualDesconto',
        label: 'Desconto(%)',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoUnitarioLiquido',
        label: 'Preço de Venda',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalLiquido',
        label: `Total (R$${formatCurrency(pedido.totalLiquido)})`,
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalIpi',
        label: `Total IPI (R$${formatCurrency(pedido.totalIpi)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalComImposto',
        label: `Total Nota (R$${formatCurrency(pedido.totalComImposto)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      // {
      //   name: 'itemBloqueado',
      //   label: 'Bloqueado',
      //   options: {
      //     display: usuarioLogado.isadmin,
      //     setCellProps: () => ({
      //       style: {
      //         width: '70px',
      //         height: '42px',
      //         textAlign: 'center',
      //       },
      //     }),
      //     customBodyRender: (value, tableMeta) => {
      //       const rowIndex = tableMeta.rowIndex
      //       const itemPedidoId = pedido.itens[rowIndex].uuid || ''

      //       return (
      //         <>
      //           {itensPedidoSelecionados.some(
      //             (item) => item.itemPedidoId === itemPedidoId,
      //           ) ? (
      //             <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      //               <CircularProgress size={14} />
      //             </Box>
      //           ) : (
      //             <Checkbox
      //               disabled={!podeEditar}
      //               name="itemBloqueado"
      //               checked={Boolean(value)}
      //               onChange={(e) => {
      //                 const isChecked = e.target.checked

      //                 setItensPedidoSelecionados((prevState) => [
      //                   ...prevState,
      //                   { itemPedidoId, itemBloqueado: isChecked },
      //                 ])
      //               }}
      //             />
      //           )}
      //         </>
      //       )
      //     },
      //   },
      // },
      // {
      //   name: 'precoTotalIcms',
      //   label: 'ICMS',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          display: podeEditar,
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            style: {
              width: '200px',
              textAlign: 'center',
            },
            // textAlign: 'center',
          }),
          customBodyRender: (value) => {
            const _data = findData(pedido.itens || [], value)

            const itemBloqueadoParaNaoAdministradores =
              !usuarioLogado.isadmin && _data?.itemBloqueado === true
            return (
              <DataTableOptions
                disabled={itemBloqueadoParaNaoAdministradores}
                displayUpdateButton={true}
                value={_data}
                onClick={onClickItem}
                // extraOptions={[
                //   {
                //     id: 'perder_item',
                //     icon: 'archive',
                //     visible: true,
                //     iconButtonProps: {
                //       color: 'primary',
                //     },
                //   },
                // ]}
              />
            )
          },
        },
      },
    ],
    [pedido, onClickItem, configuracoes],
  )

  return (
    <DataTable
      columns={columns}
      data={_itens}
      options={{ pagination: false, responsive: 'standard' }}
    />
  )
}
