import { FormProvider } from '@data-c/providers'
import { Content } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import Loading from 'components/Displays/Loading'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import TabPanel from 'components/TabPanel'
import useFormaPagamento, {
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'
import useTabs from 'hooks/useTabs'
import { useParams } from 'react-router-dom'
import TabCadastro from './components/TabCadastro'
import TabCondicoesPagamento from './components/TabCondicoesPagamento'

export default function FormaPagamento() {
  const { id: formaPagamentoUuid } = useParams()
  const { useQueryByUuid } = useFormaPagamento()
  const { data: formaPagamento, isLoading } =
    useQueryByUuid<FormaPagamentoModel>(formaPagamentoUuid as string)

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Forma de Pagamento',
                link: 'forma-pagamento/lista',
              },
              {
                label: `${formaPagamento?.nome}`,
                link: `/forma-pagamento/forma-pagamento/${formaPagamento?.uuid}`,
              },
            ]}
          />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs tabela de preco"
          >
            <Tab label="Cadastro" value={0} />
            <Tab label="Condições de Pgto" value={1} />
          </Tabs>
        </ContentTitle.Root>
        <Content sx={{ margin: 1 }}>
          <TabPanel value={value} index={0}>
            <FormProvider>
              <TabCadastro
                formaPagamento={formaPagamento || ({} as FormaPagamentoModel)}
              />
            </FormProvider>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabCondicoesPagamento
              formaPagamento={formaPagamento || ({} as FormaPagamentoModel)}
            />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
