import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'

import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import MioFilter from 'components/MioCandidate/Filter'
import MixProdutosTransporter from 'components/Transporters/MixProdutosTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { RelatorioComparacaoPerformanceProdutosFilters1 } from 'hooks/queries/useRelatorioComparacaoPerformanceProdutos'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import FilterForm from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues, onChangeFilterValue } =
    useFilterApi<{
      plainQuery?: string
    }>()
  const { filterValues, appliedValues, isOpen } =
    useFilter<RelatorioComparacaoPerformanceProdutosFilters1>()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Performance de Produtos"
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="dateRange"
            label={
              appliedValues?.dateRange?.tipo === 'day'
                ? `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
                : 'Período - ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                    appliedValues?.dateRange?.secondDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
            }
          >
            <fieldset
              style={{
                border: '1px solid #cfcfcf',
                borderRadius: '4px',
              }}
            >
              <legend
                style={{
                  color: '#808080',
                  fontSize: '0.7rem',
                  paddingLeft: '4px',
                  paddingRight: '16px',
                }}
              >
                Período
              </legend>

              <Stack
                sx={{
                  gap: 2,
                  borderRadius: '4px',
                }}
              >
                <DateRangePicker
                  renderFieldSet={false}
                  onChange={(value) => {
                    changeFilterValue('dateRange', value)
                  }}
                  value={filterValues?.dateRange}
                />
              </Stack>
            </fieldset>
          </MioFilter.Chip>

          <MioFilter.Chip
            name="situacao"
            label={`Situação - ${
              appliedValues?.situacao === 'emissao'
                ? 'Faturados'
                : 'Em Processamento'
            }`}
          >
            <TextField
              select
              label="Situação do Pedido"
              value={filterValues?.situacao || ''}
              onChange={onChangeFilterValue}
              name="situacao"
            >
              <MenuItem value="emissao">Faturados</MenuItem>
              <MenuItem value="venda">Em processamento</MenuItem>
            </TextField>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="tipoDeCalculo"
            label={`Calcular por - ${
              appliedValues?.tipoDeCalculo === 'pesoliquido' ? 'Peso' : 'Valor'
            }`}
          >
            <TextField
              select
              label="Calcular por"
              value={filterValues?.tipoDeCalculo || ''}
              name="tipoDeCalculo"
              onChange={onChangeFilterValue}
            >
              <MenuItem value="totalliquido">Valor Total de Venda</MenuItem>
              <MenuItem value="pesoliquido">Peso Total de Venda</MenuItem>
            </TextField>
          </MioFilter.Chip>

          <MioFilter.Chip
            name="representante"
            label={appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="representanteUuid"
              label="Representante"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="mix1" label={appliedValues?.mix1?.nome}>
            <MixProdutosTransporter
              name="mix1Uuid"
              label="Mix de produtos 1"
              value={filterValues?.mix1 || null}
              onChange={(mix1) => {
                changeFilterValue('mix1', mix1)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip name="mix2" label={appliedValues?.mix2?.nome}>
            <MixProdutosTransporter
              name="mix2Uuid"
              label="Mix de produtos 1"
              value={filterValues?.mix2 || null}
              onChange={(mix2) => {
                changeFilterValue('mix1', mix2)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name={`etiquetaKeyValue`}
            label={`${
              appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta:'
                : 'Etiquetas:'
            } ${appliedValues?.etiquetaKeyValue?.map((etiqueta: any) => {
              return ' ' + etiqueta.label
            })}`}
          >
            <Autocomplete
              multiple
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>
        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
