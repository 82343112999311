import { Button, ButtonContainer } from '@data-c/ui'
import { AlarmOff, Check, DoneAll } from '@mui/icons-material'
import { Box, Grid2 as Grid, MenuItem, Stack, TextField } from '@mui/material'
import {
  AppNotificationsModel,
  LembreteDataEnum,
  LembreteTipoEnum,
  useCreateOrUpdateLembrete,
  useDeleteLembrete,
} from 'hooks/queries/useAppNotifications'

import useForm from 'hooks/useForm'
import { useEffect, useState } from 'react'

interface FormItemProps {
  notificacao: AppNotificationsModel
  onCancel: (index: number) => void
  onChange: (notificacao: AppNotificationsModel, index: number) => void
  index: number
  showCancelButton?: boolean
}

export default function FormItem(props: FormItemProps) {
  const { notificacao, index, onCancel, onChange, showCancelButton } = props
  const { data, setData } = useForm<AppNotificationsModel>(notificacao)
  const [mouseIn, setMouseIn] = useState<boolean>(false)
  const { mutateAsync: createOrUpdateLembrete, isLoading } =
    useCreateOrUpdateLembrete()

  const { mutateAsync: deleteLembrete, isLoading: isLoadingDelete } =
    useDeleteLembrete()

  useEffect(() => {
    if (notificacao) {
      setData(notificacao)
    }
  }, [notificacao])

  async function handleOnClickSave() {
    await createOrUpdateLembrete(data)
  }

  async function handleClickDelete(notificacaoUuid: string) {
    if (notificacaoUuid) {
      await deleteLembrete(notificacaoUuid)
      return
    }
    onCancel(index)
  }

  function renderStatusIcon() {
    if (data?.uuid && !data.notificadoWeb) return <Check />
    if (data?.uuid && data.notificadoWeb) return <DoneAll />
    if (!data?.uuid) return <AlarmOff />

    return null
  }

  return (
    <Box
      display="flex"
      alignContent="center"
      alignItems="center"
      sx={{
        '& .MuiBox-root': {
          pr: 1,
        },
        ':hover': {
          color: (theme) => theme.palette.primary.dark,
        },
      }}
      onMouseEnter={() => {
        setMouseIn(true)
      }}
      onMouseLeave={() => {
        setMouseIn(false)
      }}
    >
      <Grid container spacing={1}>
        <Grid size={{ xs: 4, sm: 2, lg: 2, xl: 2 }}>
          <Stack direction="row" spacing={0.5}>
            {renderStatusIcon()}
            <TextField
              disabled={data?.notificadoWeb}
              name="lembreteTempo"
              value={data?.lembreteTempo || ''}
              onChange={(e) => {
                const lembreteTempo = parseFloat(e.target.value)
                onChange({ ...data, lembreteTempo }, index)
              }}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 4, sm: 2, lg: 2, xl: 2 }}>
          <TextField
            label={data?.lembreteTempo || 0}
            disabled={data?.notificadoWeb}
            select
            name="lembreteTipo"
            value={data?.lembreteTipo || LembreteTipoEnum.DIAS}
            onChange={(e) => {
              const lembreteTipo = e.target.value as LembreteTipoEnum
              onChange({ ...data, lembreteTipo }, index)
            }}
          >
            <MenuItem value={LembreteTipoEnum.MINUTOS}>Minutos</MenuItem>
            <MenuItem value={LembreteTipoEnum.HORAS}>Horas</MenuItem>
            <MenuItem value={LembreteTipoEnum.DIAS}>Dias</MenuItem>
            <MenuItem value={LembreteTipoEnum.SEMANAS}>Semanas</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 4, sm: 2, lg: 2, xl: 2 }}>
          <TextField
            label="antes do"
            disabled={data?.notificadoWeb}
            select
            name="lembreteData"
            value={data?.lembreteData || LembreteDataEnum.INICIO}
            onChange={(e) => {
              const lembreteData = e.target.value as LembreteDataEnum
              onChange({ ...data, lembreteData }, index)
            }}
          >
            <MenuItem value={LembreteDataEnum.INICIO}>Início</MenuItem>
            <MenuItem value={LembreteDataEnum.FIM}>Fim</MenuItem>
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 2, xl: 2 }}>
          {(mouseIn || !data?.uuid) && !data?.notificadoWeb && (
            <ButtonContainer>
              <Button
                sx={{ width: '126px' }}
                variant={data?.uuid ? 'outlined' : 'contained'}
                isLoading={isLoading}
                onClick={handleOnClickSave}
              >
                {data?.uuid ? 'Atualizar' : 'Criar'}
              </Button>
              {showCancelButton && (
                <Button
                  isLoading={isLoadingDelete}
                  onClick={() => handleClickDelete(data.uuid)}
                  sx={{ width: '126px' }}
                  color="error"
                >
                  {data?.uuid ? 'Excluir' : 'Cancelar'}
                </Button>
              )}
            </ButtonContainer>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}