import { Delete as DeleteIcon } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'

interface CellActionDeleteProps {
  onClick?: (event: 'delete') => void
  disabled?: boolean
  title?: string
}

export default function CellActionDelete({
  onClick,
  ...rest
}: CellActionDeleteProps) {
  return (
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      color="primary"
      onClick={() => onClick('delete')}
      {...rest}
    />
  )
}
