import { Stack, StackProps } from '@mui/material'

export default function DialogContent({ children, ...rest }: StackProps) {
  return (
    <Stack component="main" margin="44px 0 55.75px" overflow="auto">
      <Stack padding="16px" {...rest}>
        {children}
      </Stack>
    </Stack>
  )
}
