import { useMemo, useState } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { Button, CurrencyCellStyle, DataTable } from '@data-c/ui'

import useRelatorioMetaVendas, {
  RelatorioMetaVendasFilters1,
} from 'hooks/queries/useRelatorioMetaVendas'

import { formatCurrency } from '@data-c/hooks'
import { useUserContext } from 'components/Contexts/UserContext'
import PercentageLinearProgress from 'components/PercentageLinearProgress'
import { SortProps } from 'interfaces/SortProps'
import { useNavigate } from 'react-router-dom'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'

export default function Table() {
  const navigate = useNavigate()
  const { temPermissao } = useUserContext()
  const [sort, setSort] = useState<SortProps>({
    column: 'nomeVendedor',
    direction: 'asc',
  })
  const { appliedValues, searchId } = useFilter<RelatorioMetaVendasFilters1>()

  const { representante, dateRange, ...rest } = appliedValues || {}
  const nFilters: RelatorioMetaVendasFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { useQueryObterRelatorioMetaVendas } = useRelatorioMetaVendas()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioMetaVendas({
      queryParams: nFilters,
      requestId: searchId,
      pagination: {
        page: 1,
        pageSize: 99999,
      },
    })

  const dataFormatada = useMemo(
    () =>
      data?.map((item) => {
        return {
          ...item,
          metaDeVenda: formatCurrency(item?.metaDeVenda || ''),
          totalVendido: formatCurrency(item?.totalVendido || ''),
        }
      }),
    [data],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoVendedor',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nomeVendedor',
        label: 'Nome',
      },
      {
        name: 'totalVendido',
        label: 'Total Vendido',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'metaDeVenda',
        label: 'Meta de Venda',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'porcentagemAtingidaDaMetaDeVenda',
        label: 'Progresso',
        options: {
          setCellProps: () => ({
            style: {
              width: '250px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <PercentageLinearProgress progressValue={value} />
          },
        },
      },
      {
        name: 'uuidVendedor',
        label: 'Ações',
        options: {
          display: temPermissao('vendedores.visibilidade'),
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => {
            return {
              style: {
                width: '160px',
                textAlign: 'center',
              },
            }
          },
          customBodyRender: (value) => {
            return (
              <Button
                variant="text"
                onClick={() =>
                  navigate(`/vendedores/vendedor/${value}?tab=meta-de-vendas`)
                }
              >
                Configurar Meta
              </Button>
            )
          },
        },
      },
    ],
    [data],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={dataFormatada || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        sort: true,
        sortOrder: {
          name: sort.column,
          direction: sort.direction,
        },
      }}
      changeSort={(column, direction) => {
        setSort({ column, direction: direction as 'asc' | 'desc' })
      }}
    />
  )
}
