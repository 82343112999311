import api from "services/api"

export interface Bounds{
     north: number, south: number, east: number, west: number
}

export async function obterMapaDeClientes(params: { north: number, south: number, east: number, west: number }){
    const response = await api.get(`/relatorio/mapa-clientes`, {
        params
    })
  return response.data
}
