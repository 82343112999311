import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
} from '@mui/material'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import RadioGroup from 'components/RadioGroup'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { situacoes } from 'hooks/queries/usePedidos'
import { RelatorioMetaVendasFilters1 } from 'hooks/queries/useRelatorioMetaVendas'
import { useEffect, useMemo } from 'react'

export default function FilterForm() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilter<RelatorioMetaVendasFilters1>()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <Stack spacing={1.5}>
      <fieldset
        style={{
          border: '1px solid #cfcfcf',
          borderRadius: '4px',
        }}
      >
        <legend
          style={{
            color: '#808080',
            fontSize: '0.7rem',
            paddingLeft: '4px',
            paddingRight: '16px',
          }}
        >
          Período
        </legend>

        <Stack
          sx={{
            gap: 2,
            borderRadius: '4px',
          }}
        >
          <RadioGroup
            name="tipoData"
            value={filterValues?.tipoData || 'venda'}
            defaultValue="venda"
            row
            onChange={(e) => {
              const key = e.target.value
              changeFilterValue('tipoData', key)
            }}
          >
            <FormControlLabel value="venda" label="Venda" control={<Radio />} />
            <FormControlLabel
              value="emissao"
              label="Faturamento"
              control={<Radio />}
            />
          </RadioGroup>
          <DateRangePicker
            renderFieldSet={false}
            onChange={(value) => {
              changeFilterValue('dateRange', value)
            }}
            value={filterValues?.dateRange}
          />
        </Stack>
      </fieldset>
      <Autocomplete
        multiple
        options={situacoes}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.situacao || []}
        disableCloseOnSelect
        onChange={(_, value) => {
          changeFilterValue('situacao', value)
        }}
        renderInput={(params) => <TextField {...params} label="Situação" />}
      />
      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="representanteUuid"
        label="Representante"
        value={filterValues?.representante}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        InputLabelProps={{ shrink: true }}
      />
      <Autocomplete
        multiple
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
  )
}
