import { FilterOptions } from '@data-c/providers'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from 'services/api'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import { TimeVendasModel } from './useTimeVendas'
import { EtiquetaModel } from './useEtiqueta'

export interface ABCProdutosFilters {
  percentualA: FilterOptions<number | null>
  percentualB: FilterOptions<number | null>
  percentualC: FilterOptions<number | null>
  dataInicial: FilterOptions<string | null>
  dataFinal: FilterOptions<string | null>
  dateRange?: FilterOptions<DateRange>
  situacaoPedido: FilterOptions<'venda' | 'emissao'>
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  metrica: FilterOptions<string | null>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaModel>>
}

export interface ABCProdutosFilters1 {
  percentualA: number | null
  percentualB: number | null
  percentualC: number | null
  dataInicial: string | null
  dataFinal: string | null
  dateRange?: DateRange
  situacaoPedido: 'venda' | 'emissao'
  representante?: TimeVendasModel | null
  representanteUuid?: string | null
  metrica: string | null
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaModel>
}

export interface ABCProdutosFiltersParams {
  percentualA?: number
  percentualB?: number
  percentualC?: number
  dataInicial?: string | null
  dataFinal?: string | null
  dateRange?: DateRange
  situacaoPedido?: 'venda' | 'emissao'
  representante?: TimeVendasModel
  representanteUuid?: string
  metrica: string
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaModel> | []
}

interface ABCProdutoModel {
  uuid: number
  codigo: string
  nome: string
}

export interface ProdutoTotalModel {
  produto: ABCProdutoModel
  total: number
  quantidade: number
  percentualAcumulado: number
  receitaAcumulada: number
  classificacao: 'A' | 'B' | 'C'
}

export interface RelatorioABCProdutosModel {
  A: ProdutoTotalModel[]
  B: ProdutoTotalModel[]
  C: ProdutoTotalModel[]
  Todos: ProdutoTotalModel[]
}

export const filters: ABCProdutosFilters = {
  percentualA: {
    label: 'Percentual A',
    value: 80,
  },
  percentualB: {
    label: 'Percentual B',
    value: 15,
  },
  percentualC: {
    label: 'Percentual C',
    value: 5,
  },
  dataInicial: {
    label: 'Data Inicial',
    value: null,
  },
  dataFinal: {
    label: 'Data Final',
    value: null,
  },
  situacaoPedido: {
    label: 'Situação',
    value: 'emissao',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  representante: {
    label: 'Vendedor',
    value: null,
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  metrica: {
    value: 'total',
    label: 'Métrica',
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export const filters1: ABCProdutosFilters1 = {
  percentualA: 80,
  percentualB: 15,
  percentualC: 5,
  dataInicial: null,
  dataFinal: null,
  situacaoPedido: 'emissao',
  dateRange: {
    tipo: 'month',
    firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
    secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
  },
  representante: null,
  representanteUuid: '',
  metrica: 'total',
  etiqueta: [],
  etiquetaKeyValue: [],
}

export async function obterRelatorioABCProdutos(
  params: HttpRequestInterface<ABCProdutosFiltersParams>,
): Promise<RelatorioABCProdutosModel> {
  if (!params.queryParams?.dataFinal) return { A: [], B: [], C: [], Todos: [] }
  const { queryParams } = params
  const etiquetas = queryParams?.etiquetaKeyValue
    ? queryParams?.etiquetaKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    etiqueta: etiquetas,
  }
  const response = await api.get<RelatorioABCProdutosModel>(
    `relatorio/abc-produtos`,
    {
      params: nQueryParams,
    },
  )
  return response.data
}
// requestId: searchId,
export function useQueryObterRelatorioABCProdutos(
  params: HttpRequestInterface<ABCProdutosFiltersParams>,
) {
  return useQuery<RelatorioABCProdutosModel, AxiosError<ErrorInterface>>(
    ['RELATORIO_ABC_PRODUTOS', params],
    () => obterRelatorioABCProdutos(params),
  )
}

export default function useRelatorioABCProdutos() {
  return {
    useQueryObterRelatorioABCProdutos,
  }
}
