import { Stack, Typography } from '@mui/material'
import notifyImage from 'assets/images/empty-data.svg'

export default function DataTableEmptyData() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={2}
      padding={2}
      maxWidth="400px"
      minWidth="200px"
    >
      <Stack
        width="250px"
        height="250px"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        sx={{
          background:
            'linear-gradient(to top, #ffffff, #fdfcfd, #fafafa, #f8f7f8, #f5f5f6);',
        }}
      >
        <img
          src={notifyImage}
          alt="Imagem de notificação"
          width="148px"
          height="148px"
        />
      </Stack>
      <Stack spacing={1} textAlign="center">
        <Typography variant="h4" component="h2" fontWeight="600">
          Nenhum registro encontrado
        </Typography>
        <Typography variant="body2" whiteSpace="normal">
          Tente outra pesquisa ou adicione um novo registro.
        </Typography>
      </Stack>
    </Stack>
  )
}
