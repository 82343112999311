import { TextField } from '@mui/material';

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext';


import MioFilter from 'components/MioCandidate/Filter';
import { FilterForm } from './FilterForm';

export default function Filter() {
  const { onChangeFilterValue, changeFilterValue, applyFilterValues } = useFilterApi()
  const { appliedValues, filterValues } = useFilter();

  return (
    <>
      <MioFilter.Root>
        <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar por Condição de Pagamento"
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="codigo"
            label={appliedValues?.codigo}
          >
            <TextField
                name="codigo"
                value={filterValues?.codigo?.value}
                label={filterValues?.codigo?.label}
                onChange={onChangeFilterValue}
              />
          </MioFilter.Chip>
          <MioFilter.Chip
            name='nome'
            label = {appliedValues?.nome}
          >
            <TextField
              name="nome"
              value={filterValues?.nome?.value}
              label={filterValues?.nome?.label}
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>

        </MioFilter.Chips>
          <MioFilter.AdvancedButton />
        </MioFilter.Container>
        <MioFilter.AdvancedContainer>
          <FilterForm />
          <MioFilter.AdvancedActionsContainer>
            <MioFilter.ResetButton />
            <MioFilter.ApplyButton />
          </MioFilter.AdvancedActionsContainer>
        </MioFilter.AdvancedContainer>
      </MioFilter.Root>
    </>
  )
}
