import { useParams } from 'react-router-dom'

import { Container, Tab, Tabs } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import useClientes, { ClientesModel } from 'hooks/queries/useClientes'
import useTabs from 'hooks/useTabs'

import { useUserContext } from 'components/Contexts/UserContext'
import Loading from 'components/Displays/Loading'
import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import TabPanel from 'components/TabPanel'
import { useQuery as useConfiguracoes } from 'hooks/queries/useConfiguracoes'
import _ from 'lodash'
import TabCadastroCliente from './components/TabCadastroCliente'
import TabCondicaoDePagamento from './components/TabCondicaoDePagamento'
import TabDuplicatas from './components/TabDuplicatas'
import TabFormaDePagamento from './components/TabFormaDePagamento'
import TabPontos from './components/TabPontos'
import TabTabelaDePrecos from './components/TabTabelaDePrecos'
import TabVendedores from './components/TabVendedores'

export default function Cliente() {
  const { id: clienteUuid } = useParams()
  const { temPermissao } = useUserContext()
  //const navigate = useNavigate()
  const { data: configuracoes } = useConfiguracoes()
  const { useQueryByUuid } = useClientes()
  const { data: cliente, isLoading } = useQueryByUuid<ClientesModel>(
    clienteUuid || '',
  )

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Clientes',
                link: '/clientes/lista',
              },
              {
                label: _.truncate(`${cliente?.nome || cliente?.usual}`, {
                  length: 20,
                }),
                link: `/clientes/cliente/${cliente?.uuid}`,
              },
            ]}
          />
          <Tabs value={value} onChange={handleChange} aria-label="tabs cliente">
            <Tab label="Cadastro" value={0} />
            {temPermissao('clientes.vincular') && (
              <Tab label="Vendedores" value={1} />
            )}
            {temPermissao('clientes.vincular') && (
              <Tab label="Tabela de Preços" value={2} />
            )}
            {temPermissao('clientes.vincular') && (
              <Tab label="Forma Pagamento" value={3} />
            )}

            {temPermissao('clientes.vincular') && (
              <Tab label="Condição Pagamento" value={4} />
            )}

            <Tab label="Duplicatas" value={5} />

            {configuracoes?.usa_programa_fidelidade != 'DESATIVADO' && (
              <Tab label="Pontos" value={6} />
            )}
          </Tabs>
        </ContentTitle.Root>

        <Content>
          <TabPanel value={value} index={0}>
            <TabCadastroCliente cliente={cliente || ({} as ClientesModel)} />
          </TabPanel>
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={1}>
              <TabVendedores clienteUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={2}>
              <TabTabelaDePrecos pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={3}>
              <TabFormaDePagamento pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}

          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={4}>
              <TabCondicaoDePagamento pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}

          <TabPanel value={value} index={5}>
            <TabDuplicatas pessoaUuid={cliente?.uuid || ''} />
          </TabPanel>

          {configuracoes?.usa_programa_fidelidade != 'DESATIVADO' && (
            <TabPanel value={value} index={6}>
              <TabPontos cliente={cliente || ({} as ClientesModel)} />
            </TabPanel>
          )}
        </Content>
      </FormProvider>
    </Container>
  )
}
