import { FilterContainer } from '@data-c/ui'
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { useEffect, useMemo } from 'react'

export function FilterForm() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilter()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  // const permissaoParaLerNegociacoes =
  //   temPermissao('negociacoes.read') || 'pessoal'

  // useEffect(() => {
  //   if (permissaoParaLerNegociacoes === 'pessoal') {
  //     changeFilterValue('representante', user)
  //   }
  // }, [permissaoParaLerNegociacoes, isOpen])
  return (
    // <FilterContainer
    //   title="Parâmetros"
    //   triggerButtonProps={{ variant: 'outlined' }}
    //   renderLabel={(filterLabel, filterValue) => {
    //     if (filterLabel === 'Vendedor') {
    //       return `${filterLabel} - ${filterValue?.nome || ''}`
    //     }

    //     if (filterLabel === 'Cliente') {
    //       return `${filterLabel} - ${filterValue?.nome || ''}`
    //     }

    //     if (filterLabel === 'Etiqueta') {
    //       const label = filterValue.length === 1 ? 'Etiqueta' : 'Etiquetas'
    //       let values = ''
    //       if (Array.isArray(filterValue)) {
    //         values = filterValue.map((f: any) => f.label).toString()
    //       }
    //       return `${label} - ${values}`
    //     }

    //     return `${filterLabel} - ${filterValue}`
    //   }}
    // >
    <Stack spacing={1.5}>
      <TextField
        label="Código do Pedido"
        value={filterValues?.codigoPedido || ''}
        onChange={(e) => changeFilterValue('codigoPedido', e.target.value)}
      />

      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="representanteUuid"
        label="representante"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        InputLabelProps={{ shrink: true }}
      />

      <ClientesTransporter
        name="clienteUuid"
        label="cliente"
        value={filterValues?.cliente || null}
        vendedorUuid={filterValues?.representante?.value?.uuid || ''}
        onChange={(cliente) => {
          changeFilterValue('cliente', cliente)
        }}
        InputLabelProps={{ shrink: true }}
      />

      <Autocomplete
        multiple
        aria-label="Etiquetas"
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
    // </FilterContainer>
  )
}
