import { FormProvider } from '@data-c/providers'
import { PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import Delete from 'components/Delete'
import useMotivosDePerda from 'hooks/queries/useMotivosDePerda'
import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'

export default function MotivosDePerda() {
  const { useDelete } = useMotivosDePerda()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[{ label: 'Motivos de Perda', link: '/motivos-de-perda' }]}
          />
          <Form />
        </ContentTitle.Root>
        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
