import useABCClientes, {
  ABCClientesFiltersParams,
  ClienteTotalModel,
} from 'hooks/queries/useRelatorioABCClientes'

import { Badge, BadgeProps, Box, Tab, Tabs, styled } from '@mui/material'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import Surface from 'components/Surface'
import TabPanel from 'components/TabPanel'
import useNotification from 'hooks/useNotifications'
import useTabs from 'hooks/useTabs'
import ABCTable, { ABCTotal } from 'pages/Relatorios/components/ABCTable'
import { memo } from 'react'

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    // right: -5,
    top: -5,
    border: `2px solid #FFFFFF`,
    padding: '0 4px',
  },
}))

function Table() {
  const { value, handleChange } = useTabs()
  const { extractAxiosError } = useNotification()
  const { appliedValues, searchId } = useFilter()
  const { dateRange, representante, ...rest } = appliedValues || {}

  const nFilters: ABCClientesFiltersParams = {
    ...rest,
    representanteUuid: representante?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { useQueryObterRelatorioABCClientes } = useABCClientes()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioABCClientes({
      queryParams: nFilters,
      requestId: searchId,
      pagination: {
        page: 1,
        pageSize: 99999,
      },
    })

  function parseToAbcTotal(item: ClienteTotalModel): ABCTotal {
    return {
      ...item,
      codigo: item.cliente.codigo,
      nome: item.cliente.nome,
    }
  }

  const nData: Record<string, ABCTotal[]> = {
    A: data?.A?.map((i) => parseToAbcTotal(i)) || [],
    B: data?.B?.map((i) => parseToAbcTotal(i)) || [],
    C: data?.C?.map((i) => parseToAbcTotal(i)) || [],
  }

  const classificacaoTodos = [
    ...(nData?.A || []),
    ...(nData?.B || []),
    ...(nData?.C || []),
  ]
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="relatorio-abc-tabs"
      >
        {Object.keys(nData).map((k) => (
          <Tab
            label={
              <StyledBadge
                badgeContent={nData[k].length}
                color="primary"
                max={999}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {`Classificação ${k}`}
              </StyledBadge>
            }
          />
        ))}
        <Tab
          label={
            <StyledBadge
              badgeContent={classificacaoTodos.length}
              color="primary"
              max={999}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {`Todos`}
            </StyledBadge>
          }
        />
      </Tabs>

      <Box sx={{}}>
        <TabPanel value={value} index={0}>
          <ABCTable
            isLoading={isLoading}
            isFetching={isFetching}
            error={extractAxiosError(error)}
            data={nData?.A || []}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ABCTable
            isLoading={isLoading}
            isFetching={isFetching}
            error={extractAxiosError(error)}
            data={nData?.B || []}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ABCTable
            isLoading={isLoading}
            isFetching={isFetching}
            error={extractAxiosError(error)}
            data={nData?.C || []}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <ABCTable
            isLoading={isLoading}
            isFetching={isFetching}
            error={extractAxiosError(error)}
            data={classificacaoTodos || []}
            exibeClassificacao={true}
          />
        </TabPanel>
      </Box>
    </>
  )
}

export default memo(Table)
