import { MenuItem, Stack, TextField } from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import PedidoTransporter from 'components/Transporters/PedidosTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import {
  CrmAtividadesFilters1,
  TipoAtividade,
} from 'hooks/queries/useCrmAtividades'
import DropDownCrmSituacao from 'components/Inputs/DropDownCrmSituacao'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'

export function FilterForm() {
  const { user, temPermissao } = useUserContext()
  const { filterValues, isOpen } = useFilter<CrmAtividadesFilters1>()
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()

  const permissaoParaLerAtividades = temPermissao('clientes.read')
  useEffect(() => {
    if (permissaoParaLerAtividades === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerAtividades, isOpen])

  return (
    <Stack spacing={2}>
      <DateRangePicker
        onChange={(value) => {
          changeFilterValue('dateRange', value)
        }}
        value={filterValues?.dateRange}
      />
      <TextField
        name="assunto"
        onChange={(e) => changeFilterValue('assunto', e.target.value)}
        value={filterValues?.assunto || ''}
        label="Assunto"
      />
      <TextField
        name="tipoAtividade"
        onChange={(e) => changeFilterValue('tipoAtividade', e.target.value)}
        value={filterValues?.tipoAtividade || ''}
        label="Tipo Atividade"
        select
      >
        <MenuItem value={TipoAtividade.TODAS}>Todas</MenuItem>
        <MenuItem value={TipoAtividade.LIGAR}>Ligação</MenuItem>
        <MenuItem value={TipoAtividade.REUNIAO}>Reunião</MenuItem>
        <MenuItem value={TipoAtividade.TAREFA}>Tarefa</MenuItem>
        <MenuItem value={TipoAtividade.PRAZO}>Prazo</MenuItem>
        <MenuItem value={TipoAtividade.EMAIL}>E-mail</MenuItem>
        <MenuItem value={TipoAtividade.ALMOCO}>Evento</MenuItem>
      </TextField>
      <DropDownCrmSituacao
        name="situacao"
        value={filterValues?.situacao || []}
        label="Situação"
        onChange={(e) => changeFilterValue('situacao', e.target.value)}
      />

      <PedidoTransporter
        name="pedidoUuid"
        value={filterValues?.pedido || null}
        label="Pedido"
        onChange={(pedido) => {
          changeFilterValue('pedido', pedido)
        }}
        slotProps={{ inputLabel: { shrink: true } }}
      />

      <TimeVendasTransporter
        name="vendedorUuid"
        disabled={temPermissao('clientes.read') === 'pessoal'}
        label="Vendedor"
        value={filterValues?.responsavel || null}
        onChange={(responsavel) => {
          changeFilterValue('responsavel', responsavel)
        }}
        slotProps={{ inputLabel: { shrink: true } }}
      />
      <ClientesTransporter
        label="Cliente"
        name="lead"
        value={filterValues?.lead || null}
        vendedorUuid={
          permissaoParaLerAtividades === 'pessoal' ? user?.uuid : undefined
        }
        onChange={(lead) => {
          changeFilterValue('lead', lead)
        }}
        slotProps={{ inputLabel: { shrink: true } }}
      />
    </Stack>
  )
}
