import { Box, Tooltip } from '@mui/material'

interface CustomMarkerProps {
  color: string
  title: string
  size: number
  onClick: () => void
}
export function CustomMarker(props: CustomMarkerProps) {
  const { color, onClick, title, size } = props
  const scale = size / 16

  return (
    <Tooltip title={title} arrow>
      <Box
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          borderRadius: '50% 50% 50% 0',
          border: `${10 * scale}px solid #fff`,
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: `${15 * scale}px`,
            height: `${15 * scale}px`,
            borderRadius: '50%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: `${4 * scale}px solid ${color}`,
            backgroundColor: 'transparent',
          }}
        ></Box>
      </Box>
    </Tooltip>
  )
}
