import { Stack, StackProps } from '@mui/material'

export default function DialogActions(props: StackProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      marginLeft="auto"
      {...props}
    />
  )
}
