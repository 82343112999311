import { LabelValue } from '@data-c/ui'
import { Grid2 as Grid, Paper, Typography } from '@mui/material'
import { useMemo } from 'react'
import InfoCliente from '../TabNegociacao/components/HeaderPedido/components/InfoCliente'
import ItensPedido from '../TabNegociacao/components/ItensPedido'
import useUsuario, { UsuarioModel } from 'hooks/queries/useUsuario'
import { formatCurrency } from '@data-c/hooks'

export interface SnapshotProps {
  snapshot: object | string
}

export default function TabSnapshot(props: SnapshotProps) {
  const { snapshot } = props

  const { useQuery: useObterUsuario } = useUsuario()
  const { data: usuarioLogado } = useObterUsuario()

  const obj = useMemo(() => {
    if (typeof snapshot === 'string') {
      return JSON.parse(snapshot)
    }
    return snapshot
  }, [snapshot])

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <LabelValue label="Data">
            <Typography>{obj.vendaAsBrazilianDate}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <LabelValue label="Código">
            <Typography>{obj.codigo}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <LabelValue label="Situação">
            <Typography>{obj.situacaoAsText}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }}>
          <LabelValue label="Operação">
            <Typography>{obj.operacaoAsText}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <LabelValue label="Vendedor">
            <Typography>
              {obj.representante?.codigo} - {obj.representante?.nome}
            </Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <InfoCliente cliente={obj.cliente || {}} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}>
          <LabelValue label="Tabela de preço">
            <Typography>
              {obj.tabelaPreco?.codigo} - {obj.tabelaPreco?.nome}
            </Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}>
          <LabelValue label="Condição de pagamento">
            <Typography>
              {obj.condicaoPagamento?.codigo} - {obj.condicaoPagamento?.nome}
            </Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}>
          <LabelValue label="Ajuste">
            <Typography>{obj.ajuste}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <LabelValue label="Observação">
            <Typography>{obj.observacao}</Typography>
          </LabelValue>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Produtos</Typography>
          <ItensPedido
            pedido={obj}
            usuarioLogado={usuarioLogado || ({} as UsuarioModel)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <LabelValue label="Total do pedido">
            <Typography>
              {obj.totalLiquido && formatCurrency(obj.totalLiquido)}
            </Typography>
          </LabelValue>
        </Grid>
      </Grid>
    </Paper>
  )
}