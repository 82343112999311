import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Card, IconButton, Stack, Typography } from '@mui/material'
import { ItemValues } from './ItemValues'
import { ItemPedidoModel, PedidoModel } from 'hooks/queries/usePedidos'
import { findData } from '@data-c/hooks'

export interface FormatedItemInterface {
  uuid: string
  pedidoUuid: string | undefined
  codigoProduto: string | undefined
  nomeProduto: string | undefined
  nomeCor: string | undefined
  precoTotalLiquido: string
  precoUnitarioLiquido: string
  precoDeTabela: string
  precoTotalIcms: string
  precoTotalIpi: string
  precoTotalComImposto: string
  percentualDesconto: string
  fatorPrecificacaoNome: string | undefined
  pesoAsText: string
  quantidade: number
  pesoTotal: number
}

interface CardItemProps {
  pedido: PedidoModel
  exibirPeso: boolean | undefined
  onClickItem: (
    event: 'edit' | 'delete' | 'perder_item',
    data: ItemPedidoModel,
  ) => void
  podeEditar: boolean
  item: FormatedItemInterface
}

export default function CardItem(props: CardItemProps) {
  const {
    onClickItem,
    item: itemPedido,
    exibirPeso,
    pedido,
    podeEditar,
  } = props
  function handleClickItem(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: ItemPedidoModel,
    action: 'delete' | 'edit',
  ) {
    event.stopPropagation()
    onClickItem(action, item)
  }

  return (
    <Card
      key={itemPedido.pedidoUuid}
      sx={{
        width: '100%',
        height: 'fit-content',
        borderRadius: 1,
        p: 1,
      }}
      elevation={1}
    >
      <Stack sx={{ justifyContent: 'space-between' }} direction={'row'}>
        <Stack gap={2}>
          <Typography>
            {itemPedido?.codigoProduto || ''} - {itemPedido.nomeProduto}
          </Typography>
          <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
            <ItemValues label="Preço:" conteudo={itemPedido.precoDeTabela} />
            <ItemValues
              label="Venda:"
              conteudo={itemPedido.precoUnitarioLiquido}
            />
            <ItemValues label="Quantidade: " conteudo={itemPedido.quantidade} />

            {exibirPeso && (
              <ItemValues
                label="Peso: "
                conteudo={`${itemPedido?.pesoTotal || ''}KG`}
              />
            )}

            <ItemValues
              label="Total: "
              conteudo={itemPedido.precoTotalLiquido}
            />
          </Stack>
        </Stack>
        {podeEditar && (
          <Stack
            direction={'row'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="primary"
              onClick={(e) => {
                const _data = findData(pedido.itens || [], itemPedido.uuid)
                if (_data) handleClickItem(e, _data, 'edit')
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              onClick={(e) =>
                handleClickItem(
                  e,
                  itemPedido as unknown as ItemPedidoModel,
                  'delete',
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
