import { ButtonProps } from '@mui/material'
import { Button } from '@data-c/ui'

export default function DialogActionCancelar({
  children,
  ...rest
}: ButtonProps) {
  return (
    <Button variant="outlined" color="error" {...rest}>
      Cancelar
    </Button>
  )
}
