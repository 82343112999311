import {
  SortProps,
  formatCurrency,
  useDialog,
  usePagination,
} from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import { Button, Dialog } from '@data-c/ui'
import {
  PedidoFilters1,
  PedidoModel,
  SituacaoPedidoEnum,
  useInfiniteQueryObterPedidos,
} from 'hooks/queries/usePedidos'
import _ from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, LinearProgress, Stack } from '@mui/material'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import CardsPedidos, { PedidosFormatados } from 'components/CardsPedidos'
import InfiniteScrollLoading from 'components/InfiniteScrollLoading'

interface ListagemPedidosPorClienteProps {
  clienteId: string
}

export default function ListagemPedidosPorCliente(
  props: ListagemPedidosPorClienteProps,
) {
  const { clienteId } = props
  const observer = useRef<IntersectionObserver>()

  const [sort] = useState<SortProps>({
    column: 'venda',
    direction: 'desc',
  })
  const { pagination } = usePagination()
  const { searchId } = useFilter<PedidoFilters1>()
  const nFilters: PedidoFilters1 = {
    clienteUuid: clienteId,
  }

  const { openConfirm } = useFormApi()
  const {
    data: dataDialog,
    openDialog: openDialog,
    isOpen: isOpenDialog,
    closeDialog: closeDialog,
  } = useDialog()

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useInfiniteQueryObterPedidos(
    {
      queryParams: nFilters,
      pagination,
      requestId: searchId,
    },
    sort,
  )

  const pedidos =
    data?.pages?.flatMap((page) => {
      return page.data
    }) || []

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [hasNextPage, fetchNextPage, isFetching, isLoading],
  )

  const navigate = useNavigate()

  const pedidosFormatados = useMemo(() => {
    if (!pedidos) return []
    return pedidos.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo || ''} - ${
        pedido.cliente?.nome
      } (${formatToCPFOrCNPJ(pedido.cliente?.cnpjcpf || '')})`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
      totalComImposto: formatCurrency(pedido?.totalComImposto || ''),
      pesoTotal: `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 3,
      }).format(pedido?.pesoTotal || 0)}Kg`,
      observacao: _.truncate(pedido.observacao, { length: 30 }),
      pedidoRelacionadoCodigo: pedido.pedidoRelacionado?.codigo || '',
      condicaoPagamento: pedido.condicaoPagamento?.nome || '',
      formaPagamento: pedido.formaPagamento?.nome || '',
      situacao: pedido.situacao,
    }))
  }, [data, isFetchingNextPage, hasNextPage])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: PedidoModel) => {
      console.log({ data })
      switch (event) {
        case 'edit':
          navigate(`/pedidos/pedido/${data.uuid}`)
          break
        case 'delete':
          const situacoesQuePodemSerExcluidas = [
            SituacaoPedidoEnum.WEB,
            SituacaoPedidoEnum.MOBILE,
            SituacaoPedidoEnum.COTACAO_SOLICITACAO,
          ]

          if (
            data?.situacao &&
            situacoesQuePodemSerExcluidas.includes(data?.situacao)
          ) {
            return openConfirm(data)
          }

          openDialog(data.situacaoAsText)
          break
      }
    },
    [navigate],
  )

  return (
    <Box>
      {(isLoading || isFetching) && <LinearProgress />}
      <Stack spacing={2}>
        <Stack spacing={1} p={1}>
          {pedidosFormatados.map((pedido) => {
            return (
              <div ref={lastElementRef} key={pedido.uuid}>
                <CardsPedidos
                  pedidoFormatado={pedido as unknown as PedidosFormatados}
                  pedidos={pedidos}
                  onClickItem={handleClickItem}
                ></CardsPedidos>
              </div>
            )
          })}
        </Stack>
        <InfiniteScrollLoading isFetchingNextPage={isFetchingNextPage} />
      </Stack>
      <Dialog
        title={`A negociação não está em aberto`}
        maxWidth="xs"
        type="info"
        open={isOpenDialog}
        onClose={closeDialog}
        actions={
          <Button variant="contained" onClick={closeDialog}>
            Voltar
          </Button>
        }
      >
        A situação da negociação é: <strong>{`${dataDialog}`}</strong>.
        <br />
        <br />
        Apenas as negociações <strong>Em aberto</strong> podem ser excluídas!
      </Dialog>
    </Box>
  )
}
