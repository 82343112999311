import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import useProdutos from 'hooks/queries/useProdutos'
import Delete from 'components/Delete'
import Table from './components/Table'
import Form from './components/Form'
import { TipoVariacaoEnum } from 'components/Inputs/DropDownTipoVariacao'
import Content from 'components/Layouts/Content'

import MioFilter from 'components/MioCandidate/Filter'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Filter from './components/Filter'
import Assistant from 'components/MioCandidate/Assistant'

export default function Produtos() {
  const { useDelete } = useProdutos()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article
          open={true}
          articleId="28764082341396"
        ></Assistant.Article>
      </Assistant.Root>
      <FormProvider
        initialFormValues={{
          ativo: true,
          unidade: 'un',
          tipoVariacao: TipoVariacaoEnum.NENHUMA,
        }}
      >
        <MioFilter.Provider filterValues={{}}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Produtos',
                  link: '/produtos/lista',
                },
              ]}
            />

            <Filter />

            <Form />
          </ContentTitle.Root>

          <Content>
            <Table />
            <Delete onDelete={mutateAsync} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
