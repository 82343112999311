import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
  CircularProgress,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material'
import { useTableRootContext } from './DataTableRootProvider'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
}

export default function DataTableButton(props: ButtonProps) {
  const { isLoading, startIcon, children, title, ...rest } = props

  const { breakpoint } = useTableRootContext()

  return (
    <Tooltip title={children}>
      {breakpoint ? (
        <IconButton
          {...rest}
          color="primary"
          disabled={isLoading ? true : rest.disabled}
        >
          {startIcon}
          {isLoading && (
            <CircularProgress
              color="secondary"
              size={14}
              sx={{
                position: 'absolute',
                top: '25%',
                bottom: '25%',
                left: '25%',
                right: '25%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </IconButton>
      ) : (
        <MuiButton
          {...rest}
          startIcon={startIcon}
          disabled={isLoading ? true : rest.disabled}
          variant="outlined"
          sx={{
            position: 'relative',
            height: '26px',
            minWidth: '70px',
            padding: '7px 8px 8px',
            border: '1px solid rgba(223, 226, 231, 0.8)',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flexShrink: 1,
            transition: 'width 0.2s ease',
          }}
        >
          <Box
            component="span"
            sx={{
              transition: 'opacity 0.2s ease, width 0.2s ease',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {isLoading && (
              <CircularProgress
                color="secondary"
                size={14}
                sx={{
                  position: 'absolute',
                  top: '22%',
                  left: '44%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}

            {children}
          </Box>
        </MuiButton>
      )}
    </Tooltip>
  )
}
