import { LinearProgress, Stack } from '@mui/material'
import {
  GoogleMap,
  // Marker,
  OverlayView,
  useLoadScript,
} from '@react-google-maps/api'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'

import { theme } from '@data-c/ui'
import {
  Data,
  obterRelatorioPositivacaoClientes,
  PositivacaoClientesFilters1,
} from 'hooks/queries/usePositivacaoClientes'
// import { Bounds } from 'hooks/queries/useRelatorioMapaDeClientes'
import { useCallback, useEffect, useRef, useState } from 'react'
import { CustomMarker } from './components/CustomMarker'
import DrawerListagemPedidos from './components/DrawerListagemPedidos'

export default function Maps() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  })
  const [initialCenter] = useState({
    lat: -21.1093379,
    lng: -42.9463624,
  })
  // const lastBoundsRef = useRef<Bounds | null>(null)

  const [zoom, setZoom] = useState(8)
  const [clientes, setClientes] = useState<Array<Data>>([])
  const [loadingClientes, setLoadingClientes] = useState<boolean>(false)
  const mapRef = useRef<google.maps.Map | null>(null)

  const { appliedValues } = useFilter<PositivacaoClientesFilters1>()

  const { representante, categoriaValue, ...rest } = appliedValues || {}
  const nFilters: PositivacaoClientesFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    categoriaUuid: categoriaValue?.uuid,
  }

  const [isOpenDrawer, setOpenDrawer] = useState(false)

  const [clienteSelecionado, setClienteSelecionado] = useState<Data>()

  useEffect(() => {
    async function obterRelatorios() {
      setLoadingClientes(true)
      const req = await obterRelatorioPositivacaoClientes(nFilters)
      setClientes(
        req.data.filter(
          (item) =>
            item.latitude !== 0 &&
            item.latitude != null &&
            item.latitude !== undefined,
        ),
      )
      setLoadingClientes(false)
    }
    obterRelatorios()
  }, [appliedValues])

  const handleZoomChanged = useCallback(() => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom() || 10)
    }
  }, [])

  function handleOnLoad(map: google.maps.Map) {
    mapRef.current = map

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       setInitialCenter({
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       })
    //     },
    //     (err) => {
    //       // setError(err.message)
    //     },
    //   )
    // }
  }

  // ------- para quando o endPoint com bounds estiver funcionando ------
  // const fetchClientesByBounds = useCallback(
  //   async (bounds: Bounds) => {
  //     //setLoadingClientes(true)

  //     try {
  //       const clientes = await obterRelatorioPositivacaoClientes({
  //         ...nFilters,
  //         ...bounds,
  //       } as PositivacaoClientesFilters1WithBounds)

  //       setClientes(
  //         clientes.data.filter(
  //           (item) =>
  //             item.latitude !== 0 &&
  //             item.latitude != null &&
  //             item.latitude !== undefined,
  //         ),
  //       )
  //     } catch (e) {
  //     } finally {
  //     }
  //   },
  //   [appliedValues],
  // )

  //Evento chamado quando os limites do mapa mudam (zoom ou movimento)
  // const handleBoundsChanged = useCallback(() => {
  //   const bounds = mapRef?.current?.getBounds()
  //   if (bounds) {
  //     const north = bounds.getNorthEast().lat()
  //     const south = bounds.getSouthWest().lat()
  //     const east = bounds.getNorthEast().lng()
  //     const west = bounds.getSouthWest().lng()

  //     const currentBounds: Bounds = { north, south, east, west }

  //     // Verifica se os bounds mudaram antes de buscar novamente
  //     if (
  //       !lastBoundsRef.current ||
  //       JSON.stringify(lastBoundsRef.current) !== JSON.stringify(currentBounds)
  //     ) {
  //       lastBoundsRef.current = currentBounds
  //       fetchClientesByBounds(currentBounds)
  //     }
  //   }
  // }, [appliedValues])

  function calculateScale(zoomLevel: number) {
    const baseScale = 12 // Tamanho inicial do ícone
    const zoomFactor = 1 // Ajuste para controle fino
    // return baseScale / (zoomLevel * zoomFactor)
    return baseScale + zoomLevel * zoomFactor
  }

  function handleClickMarker(clienteSelecionado: Data) {
    setClienteSelecionado(clienteSelecionado)
    setOpenDrawer(true)
  }

  if (!isLoaded) return <div>Carregando mapa...</div>

  return (
    <Stack sx={{ mt: 1, height: '100%', width: '100%' }}>
      {loadingClientes && <LinearProgress />}

      <GoogleMap
        options={{
          streetViewControl: false,
        }}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        center={initialCenter}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        onLoad={handleOnLoad}

        // onIdle={handleBoundsChanged}
      >
        {clientes.map((cliente) => (
          <OverlayView
            key={cliente.codigo}
            position={{
              lat: cliente.latitude || 0,
              lng: cliente.longitude || 0,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <CustomMarker
              title={`${cliente.codigo} - ${cliente.nome}`}
              color={
                cliente.estaAtivo
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }
              size={calculateScale(zoom)}
              onClick={() => handleClickMarker(cliente)}
            ></CustomMarker>
          </OverlayView>
        ))}
        {/* <Marker
          position={{ lat: initialCenter.lat, lng: initialCenter.lng }}
          animation={window.google.maps.Animation.DROP}
          // title={}
        /> */}
      </GoogleMap>
      {/* <button
        // onClick={goToUserLocation}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1000,
          padding: '10px 15px',
          backgroundColor: '#007BFF',
          color: '#FFF',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontWeight: 'bold',
          boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
        }}
      >
        Meu Local
      </button> */}

      <DrawerListagemPedidos
        isOpenDrawer={isOpenDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        cliente={clienteSelecionado}
      ></DrawerListagemPedidos>
    </Stack>
  )
}
