import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useCrmAtividades, {
  CrmAtividadesFilters,
  CrmAtividadesFilters1,
  CrmAtividadesModel,
  situacaoAtividadeMap,
  tipoAtividadeMap,
} from 'hooks/queries/useCrmAtividades'

import { useFormApi } from '@data-c/providers'

import { useMemo, useCallback, useEffect, useState } from 'react'
import { findData, usePagination } from '@data-c/hooks'
import { Badge } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import useNotification from 'hooks/useNotifications'
import { SortProps } from 'interfaces/SortProps'
import Surface from 'components/Surface'
import StatusAtividade from 'components/Displays/StatusAtividade'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'

export default function Table() {
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery, readById } = useCrmAtividades()
  const [sort, setSort] = useState<SortProps>({
    column: 'dataHoraInicioAsBrazilianDate',
    direction: 'desc',
  })
  const [searchParams] = useSearchParams()
  const notifications = useNotification()
  const { appliedValues, searchId } = useFilter<CrmAtividadesFilters1>()
  const { lead, responsavel, pedido, dateRange, ...rest } = appliedValues || {}

  const nFilters: CrmAtividadesFilters1 = {
    ...rest,
    responsavelUuid: responsavel?.uuid,
    leadUuid: lead?.uuid,
    pedidoUuid: pedido?.uuid,
    dataInicio: dateRange?.firstDate,
    dataFim: dateRange?.secondDate,
  }

  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    sort,
    queryParams: nFilters,
    requestId: searchId,
  })
  const { openForm, openConfirm } = useFormApi()

  useEffect(() => {
    async function obterAtividadePorId(uuid: string) {
      try {
        const response = await readById<CrmAtividadesModel>(uuid)
        openForm(response)
      } catch (err) {
        notifications.notifyException(err)
      }
    }

    const id = searchParams.get('id')
    if (id) {
      const _data = findData<CrmAtividadesModel>(data?.data || [], id)
      if (_data) {
        openForm(_data)
        return
      }
      obterAtividadePorId(id)
    }
  }, [searchParams, data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: CrmAtividadesModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const atividades =
    data?.data?.map((d) => {
      return {
        ...d,
        tipoAtividade: tipoAtividadeMap[d?.tipoAtividade || 'Todas'],
        duracao: d.duracao,
        dataHoraInicioAsBrazilianDate: d.dataHoraInicioAsBrazilianDate,
        dataHoraFimAsBrazilianDate: d.dataHoraFimAsBrazilianDate,
        status: d.status,
        statusAsText: situacaoAtividadeMap[d.status],
      }
    }) || []

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'tipoAtividade',
        label: 'Atividade',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'assunto',
        label: 'Assunto',
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customBodyRender: (value) => (
            <StatusAtividade disableBorder status={value} />
          ),
        },
      },
      {
        name: 'atribuidoParaAsText',
        label: 'Atribuído para',
      },
      {
        name: 'leadAsText',
        label: 'Cliente / Lead',
      },
      {
        name: 'pedidoAsText',
        label: 'Negociação',
      },
      {
        name: 'dataHoraInicioAsBrazilianDate',
        label: 'Início',
      },
      {
        name: 'dataHoraFimAsBrazilianDate',
        label: 'Fim',
      },
      {
        name: 'duracao',
        label: 'Duração',
        options: {
          sort: false,
        },
      },
      // {
      //   name: 'concluido',
      //   label: 'Concluído',
      //   options: {
      //     sort: false,
      //     setCellProps: () => ({
      //       style: {
      //         width: '60px',
      //         textAlign: 'center',
      //       },
      //     }),
      //     customBodyRender: (value) => {
      //       return <Flag isFlagged={value} type="success" />
      //     },
      //   },
      // },
      {
        name: 'comentarioStatus',
        label: 'Comentários',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value: {
            totalComentarios: number
            totalNaoLidas: number
          }) => {
            if (value.totalNaoLidas > 0) {
              return (
                <Badge color="error" badgeContent={value.totalNaoLidas}></Badge>
              )
            }

            if (value.totalComentarios > 0) {
              return <Flag isFlagged={true} type="primary" />
            }
            return ''
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<CrmAtividadesModel>(data?.data || [], value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={atividades || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        sort: true,
        sortOrder: {
          name: sort.column,
          direction: sort.direction,
        },
      }}
      changeSort={(column, direction) => {
        setSort({ column, direction: direction as 'asc' | 'desc' })
      }}
    />
  )
}
