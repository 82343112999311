import { ButtonContainer } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import TabPanel from 'components/TabPanel'
import useTabs from 'hooks/useTabs'
import TabChart from './components/TabChart'
import TabDetalhado from './components/TabDetalhado'
import Filter from './components/Filter'
import { filters } from 'hooks/queries/useRelatorioOportunidadesPerdidas'
export default function NegociacoesPerdidas() {
  const { value: tabValue, handleChange } = useTabs()

  return (
    <Container>
      <MioFilter.Provider
        filterValues={{ dateRange: filters.dateRange?.value }}
        storagePath="@fdv:relatorio-perdas"
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Oportunidades Perdidas',
                link: '/negociacoes-perdidas',
              },
            ]}
          ></Breadcrumbs>
          <Filter></Filter>
          <ButtonContainer sx={{ m: 2 }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Por motivo" value={0} />
            </Tabs>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Detalhado" value={2} />
            </Tabs>
          </ButtonContainer>
        </ContentTitle.Root>
        <Content>
          <TabPanel value={tabValue} index={0}>
            <TabChart />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <TabDetalhado />
          </TabPanel>
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
