import { Typography, useTheme } from '@mui/material'

interface TitleProps {
  titleDivider?: boolean
  children: string
}

export default function Title({ children, titleDivider = false }: TitleProps) {
  const theme = useTheme()

  return (
    <Typography
      variant="body1"
      fontSize="14px"
      whiteSpace="nowrap"
      sx={{
        borderRight: titleDivider
          ? `solid 1px ${theme.palette.grey[300]}`
          : 'none',
        paddingRight: 1.5,
      }}
    >
      {children}
    </Typography>
  )
}
