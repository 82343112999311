import { ReactNode } from 'react'
import { Paper, Stack } from '@mui/material'

interface ToolbarProps {
  children?: ReactNode
}

export default function Toolbar({ children }: ToolbarProps) {
  return (
    <Stack
      component={Paper}
      elevation={0}
      gap={1.5}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      paddingX={2}
      height="42px"
      borderRadius="8px 8px 0 0"
    >
      {children}
    </Stack>
  )
}
