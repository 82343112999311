import { useMemo, useState } from 'react'

import { Button, DataTable, Flag } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ProdutoModel } from 'hooks/queries/useProdutos'
import { useDesvincularProduto } from 'hooks/queries/useMixProdutos'
import Surface from 'components/Surface'

export interface TabelaProdutosVinculadosProps {
  data: Array<ProdutoModel>
  mixProdutoUuid: string
  isLoading?: boolean
  isFetching?: boolean
  error?: string
}

function temProdutosSelecionados(
  indexProdutosSelecionados: Array<string | undefined>,
): boolean {
  return indexProdutosSelecionados.length > 0
}

export default function TableProdutosVinculados(
  props: TabelaProdutosVinculadosProps,
) {
  const { data, mixProdutoUuid, isLoading, isFetching, error } = props
  const {
    mutateAsync: desvincularProdutos,
    isLoading: isLoadingDesvincularProdutos,
  } = useDesvincularProduto()

  const [indexProdutosSelecionados, setIndexProdutosSelecionados] = useState<
    number[]
  >([])

  const { changePageSize, changePage } = usePagination()

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  async function handleClickDesvincularProdutos() {
    const produtosSelecionadosIds =
      indexProdutosSelecionados.map((index) => data[index].uuid) || []

    if (mixProdutoUuid && temProdutosSelecionados(produtosSelecionadosIds)) {
      try {
        await desvincularProdutos({
          uuid: mixProdutoUuid,
          produtosSelecionadosIds,
        })
      } finally {
        setIndexProdutosSelecionados([])
      }
    }
  }
  return (
    <DataTable
      error={error}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      data={data || []}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        selectableRows: 'multiple',
        selectableRowsOnClick: true,
        rowsSelected: indexProdutosSelecionados,
        onRowSelectionChange: (_, __, rowsSelecteds: any) =>
          setIndexProdutosSelecionados(rowsSelecteds),
        customToolbarSelect() {
          return (
            <Button
              color="error"
              isLoading={isLoadingDesvincularProdutos}
              onClick={handleClickDesvincularProdutos}
              sx={{ marginRight: '26px' }}
            >
              Desvincular Produtos
            </Button>
          )
        },
      }}
    />
  )
}
