import { Autocomplete, CircularProgress, TextField } from '@mui/material'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import DropDownFunilVendas from 'components/Inputs/DropDownFunilVendas'
import MioFilter from 'components/MioCandidate/Filter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { TaxaConversaoNegociacaoModelFilters1 } from 'hooks/queries/useRelatorioTaxaConversaoNegociacao'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'
import FilterForm from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi<{
    plainQuery?: string
  }>()
  const { filterValues, isOpen, appliedValues } =
    useFilter<TaxaConversaoNegociacaoModelFilters1>()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <>
      <MioFilter.Root>
        <MioFilter.Container>
          <MioFilter.SearchTextField
            onChange={(query) => {
              changeFilterValue('plainQuery', query)
              applyFilterValues()
            }}
            fullWidth
            autoFocus
            placeholder="Gerar Relatório"
          />
          <MioFilter.Chips>
            <MioFilter.Chip
              disabledDelete
              name="dateRange"
              label={
                appliedValues?.dateRange?.tipo === 'day'
                  ? `${DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')}`
                  : 'Período: ' +
                    `${DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                      appliedValues?.dateRange?.secondDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')}`
              }
            >
              <DateRangePicker
                renderFieldSet={false}
                onChange={(value) => {
                  changeFilterValue('dateRange', value)
                }}
                value={filterValues?.dateRange}
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              disabledDelete
              name="funilVenda"
              label={`Funil de venda - ${filterValues?.funilVenda?.nome}`}
            >
              <DropDownFunilVendas
                label="Funil de Vendas"
                name="funilUuid"
                value={
                  filterValues?.funilVenda?.uuid ||
                  appliedValues?.funilVenda?.uuid
                }
                onChange={(funil) => {
                  changeFilterValue('funilVenda', funil)
                }}
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              name="representante"
              label={appliedValues?.representante?.nome}
            >
              <TimeVendasTransporter
                disabled={temPermissao('clientes.read') === 'pessoal'}
                name="representanteUuid"
                label="Representante"
                value={filterValues?.representante || null}
                onChange={(representante) => {
                  changeFilterValue('representante', representante)
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              name={`etiquetaKeyValue`}
              label={`${
                appliedValues?.etiquetaKeyValue?.length === 1
                  ? 'Etiqueta:'
                  : 'Etiquetas:'
              } ${appliedValues?.etiquetaKeyValue?.map((etiqueta: any) => {
                return ' ' + etiqueta.label
              })}`}
            >
              <Autocomplete
                multiple
                options={formattedEtiquetas || []}
                getOptionLabel={(option) => option?.label}
                value={filterValues?.etiquetaKeyValue || []}
                disableCloseOnSelect
                onChange={(_: any, value: any) =>
                  changeFilterValue('etiquetaKeyValue', value)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Etiquetas" />
                )}
                loading={isLoadingEtiquetas}
                loadingText={<CircularProgress />}
              />
            </MioFilter.Chip>
          </MioFilter.Chips>
          <MioFilter.AdvancedButton />
        </MioFilter.Container>
        <MioFilter.AdvancedContainer>
          <FilterForm />
          <MioFilter.AdvancedActionsContainer>
            <MioFilter.ResetButton />
            <MioFilter.ApplyButton />
          </MioFilter.AdvancedActionsContainer>
        </MioFilter.AdvancedContainer>
      </MioFilter.Root>
    </>
  )
}
