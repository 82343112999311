import { useNavigate, useParams } from 'react-router-dom'

import { Content, PageTitle } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'

import { Container, Tab, Tabs } from '@mui/material'

import useProdutos, { ProdutoModel } from 'hooks/queries/useProdutos'

import Loading from 'components/Displays/Loading'
import TabPanel from 'components/TabPanel'

import useTabs from 'hooks/useTabs'
import TabCadastroProduto from './components/TabCadastroProduto'
import TabPhoto from './components/TabPhotos'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function Produto() {
  const { id: produtoUuid } = useParams()
  const { useQueryByUuid } = useProdutos()
  const { data: produto, isLoading } = useQueryByUuid<ProdutoModel>(
    produtoUuid as string,
  )
  const navigate = useNavigate()

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            { label: 'Produtos', link: `/produtos` },
            { label: `${produto?.nome}`, link: `/produto/${produto?.uuid}` },
          ]}
        ></Breadcrumbs>
        <Tabs value={value} onChange={handleChange} aria-label="tabs produtos">
          <Tab label="Cadastro" value={0} />
          <Tab label="Fotos" value={1} />
          {/* <Tab
            label="Variações"
            value={1}
            disabled={
              (produto?.tipoVariacao || TipoVariacaoEnum.NENHUMA) ===
              TipoVariacaoEnum.NENHUMA
            }
          /> */}
        </Tabs>
      </ContentTitle.Root>
      {/* <PageTitle title={`Cadastro de Produto `} onBack={() => navigate(-1)}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs produtos">
          <Tab label="Cadastro" value={0} />
          <Tab label="Fotos" value={1} />
          <Tab
            label="Variações"
            value={1}
            disabled={
              (produto?.tipoVariacao || TipoVariacaoEnum.NENHUMA) ===
              TipoVariacaoEnum.NENHUMA
            }
          />
        </Tabs>
      </PageTitle> */}
      <Content>
        <FormProvider>
          <TabPanel value={value} index={0}>
            <TabCadastroProduto produto={produto || ({} as ProdutoModel)} />
          </TabPanel>
        </FormProvider>
        <TabPanel value={value} index={1}>
          <TabPhoto produtoUuid={produto?.uuid || ''} />
        </TabPanel>
      </Content>
    </Container>
  )
}
