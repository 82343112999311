import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import CadastrosVisibilidade from './components/CadastrosVisibilidade'
import CRMVisibilidade from './components/CRMVisibilidade'

export default function TabVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const {
    negociacoes,
    // configuracoes,
    taxa_conversao_negocio,
    relatorios,
    meta_vendas,
    positivacao_clientes,
    ficha_clientes,
    negociacoes_perdidas,
    abc_produtos,
    abc_clientes,
    performance_produtos,
    previsao_entrega,
    funil_vendas,
    mapa_clientes,
  } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Negociações"
          description="Define se o usuário tem permissão para acessar o menu Negociações"
          component={
            <Switch
              name="negociacoes.visibilidade"
              checked={Boolean(negociacoes?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Funil de Vendas"
          description="Define se o usuário tem permissão para acessar o Funil de Vendas"
          component={
            <Switch
              name="funil_vendas.visibilidade"
              checked={Boolean(funil_vendas?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CadastrosVisibilidade />
        <CRMVisibilidade />

        <CardConfig
          title="Relatórios"
          description="Define se o usuário tem permissão para acessar o menu Relatórios"
          collapse={Boolean(relatorios?.visibilidade)}
          component={
            <Switch
              name="relatorios.visibilidade"
              checked={Boolean(relatorios?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        >
          <CardConfig
            title="Mapa de Clientes"
            description="Define se o usuário tem permissão para acessar o menu Mapa de Clientes"
            disabledPadding
            component={
              <Switch
                name="mapa_clientes.visibilidade"
                checked={Boolean(mapa_clientes?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Taxa Conversão Negócio"
            description="Define se o usuário tem permissão para acessar o menu Taxa Conversão Negócio"
            disabledPadding
            component={
              <Switch
                name="taxa_conversao_negocio.visibilidade"
                checked={Boolean(taxa_conversao_negocio?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Curva ABC de Produtos"
            description="Define se o usuário tem permissão para acessar o menu Curva ABC de Produtos"
            disabledPadding
            component={
              <Switch
                name="abc_produtos.visibilidade"
                checked={Boolean(abc_produtos?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Curva ABC de Clientes"
            description="Define se o usuário tem permissão para acessar o menu Curva ABC de Clientes"
            disabledPadding
            component={
              <Switch
                name="abc_clientes.visibilidade"
                checked={Boolean(abc_clientes?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Previsão de Entrega"
            description="Define se o usuário tem permissão para acessar o menu Previsão de Entrega"
            disabledPadding
            component={
              <Switch
                name="previsao_entrega.visibilidade"
                checked={Boolean(previsao_entrega?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Performance de Produtos"
            description="Define se o usuário tem permissão para acessar o menu Performance de Produtos"
            disabledPadding
            component={
              <Switch
                name="performance_produtos.visibilidade"
                checked={Boolean(performance_produtos?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Meta de Vendas"
            description="Define se o usuário tem permissão para acessar o menu Meta de Vendas"
            disabledPadding
            component={
              <Switch
                name="meta_vendas.visibilidade"
                checked={Boolean(meta_vendas?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />
          <CardConfig
            title="Ficha do Cliente"
            description="Define se o usuário tem permissão para acessar o menu Ficha do Cliente"
            disabledPadding
            component={
              <Switch
                name="ficha_clientes.visibilidade"
                checked={Boolean(ficha_clientes?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Ativação de Clientes"
            description="Define se o usuário tem permissão para acessar o menu Ativação de Clientes"
            disabledPadding
            component={
              <Switch
                name="positivacao_clientes.visibilidade"
                checked={Boolean(positivacao_clientes?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />

          <CardConfig
            title="Oportunidades Perdidas"
            description="Define se o usuário tem permissão para acessar o menu Oportunidades Perdidas"
            disabledPadding
            component={
              <Switch
                name="negociacoes_perdidas.visibilidade"
                checked={Boolean(negociacoes_perdidas?.visibilidade)}
                onChange={changeRolePermission}
                disabled={Boolean(role?.fixa)}
              />
            }
          />
        </CardConfig>

        {/* <CardConfig
          title="Configurações"
          description="Define se o usuário tem permissão para acessar o menu Configurações"
          component={
            <Switch
              name="configuracoes.visibilidade"
              checked={Boolean(configuracoes?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        /> */}
      </Stack>
    </Box>
  )
}
