import { Container } from '@mui/material'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'

export default function PrevisaoEntrega() {
  return (
    <Container>
      <MioFilter.Provider filterValues={{}}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Previsão de Entrega',
                link: '/relatorio-previsao-entrega',
              },
            ]}
          />
          <Filter />
        </ContentTitle.Root>
        <Content>
          <Table />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
