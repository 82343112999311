import { Grid2 as Grid, TextField } from '@mui/material'
import { Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useCondicaoPagamento, {
  CondicaoPagamentoModel,
} from 'hooks/queries/useCondicaoPagamento'
import useValidations from 'hooks/useValidations'

import * as yup from 'yup'
// import DescontoMaxTextField from 'components/DescontoMaxTextField'
// import { onlyNumbers } from '@data-c/hooks'

function onlyNumbers(value: string, addOn?: string) {
  if (!value) return

  var commaCount = (value.match(/,/g) || []).length
  if (commaCount > 1) {
    return value.replace(/,(?=[^,]*$)/, '')
  }

  const regex = new RegExp(`[^\\d|\\,${addOn || ''}]+`, 'g')
  return value.replace(regex, '')
}

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
  condpgtot: yup
    .string()
    .test(
      'test1',
      'O prazo de pagamento está fora do padrão. Ex: 15/30/45',
      function (value) {
        if (value) return /^\d{1,3}(?:\/\d{1,3})*$/g.test(value)
        return true
      },
    )
    .test(
      'test2',
      'O prazo de pagamento de cada parcela precisa estar em ordem crescente',
      function (value) {
        if (!value) return true
        const splitedValue = value?.split('/')
        function estaEmOrdemCrescente(array: Array<string>) {
          for (let i = 0; i < array.length - 1; i++) {
            if (array[i] >= array[i + 1]) {
              return false
            }
          }
          return true
        }
        return estaEmOrdemCrescente(splitedValue)
      },
    ),
  // fator: yup.number().max(100, 'O Fator não pode ser superior à 100,00'),
  // descontoMax: yup
  //   .number()
  //   .max(100, 'O Desconto Máximo não pode ser superior à 100,00'),
})

export default function Form() {
  const { formValues: data } = useFormData<CondicaoPagamentoModel>()
  const { onChangeFormValue, toggleSubmit, closeForm, changeValue } =
    useFormApi<CondicaoPagamentoModel>()

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useCondicaoPagamento()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        if (data) await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmitForm}
      dialogProps={{
        maxWidth: 'sm',
        title: 'Condição de Pagamento',
        disableRestoreFocus: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 8 }}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            inputProps={{ maxLength: 30 }}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
          <TextField
            name="condpgtot"
            label="Prazo"
            placeholder="Ex: 15/30/45"
            value={data?.condpgtot || ''}
            onChange={(e) => {
              const condpgtot = onlyNumbers(e.target.value, '/')
              changeValue('condpgtot', condpgtot)
            }}
            {...validationProps('condpgtot')}
          />
        </Grid>
        {/* <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <CurrencyTextField
            name="fator_desbloqueio"
            label="Fator Desbloqueio"
            value={data?.fatorDesbloqueio || ''}
            onChange={(_: any, value: string) =>
              changeValue('fator_desbloqueio', value)
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <CurrencyTextField
            name="fator"
            label="Fator (%)"
            value={data?.fator || ''}
            onChange={(_: any, value: string) => {
              changeValue('fator', value)
            }}
            {...validationProps('fator')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <DescontoMaxTextField
            name="descontoMax"
            label="Desconto Máximo (%)"
            value={data?.descontoMax || ''}
            onChange={(_, value) => {
              changeValue('descontoMax', value)
            }}
            {...validationProps('descontoMax')}
          />
        </Grid> */}
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <Checkbox
            label="Ativo"
            name="ativo"
            checked={Boolean(data?.ativo)}
            onChange={onChangeFormValue}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}