import { Button } from '@data-c/ui'
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import VersaoItem from './VersaoItem'
import useNotification from 'hooks/useNotifications'

export interface Article {
  id: number
  url: string
  html_url: string
  author_id: number
  comments_disabled: boolean
  draft: boolean
  promoted: boolean
  position: number
  vote_sum: number
  vote_count: number
  section_id: number
  created_at: string // ISO 8601 format
  updated_at: string // ISO 8601 format
  name: string
  title: string
  source_locale: string
  locale: string
  outdated: boolean
  outdated_locales: string[]
  edited_at: string // ISO 8601 format
  user_segment_id: number | null
  permission_group_id: number
  content_tag_ids: number[]
  label_names: string[]
  body: string
}

export interface VersaoProps {
  sectionId: string
}

export default function Versao(props: VersaoProps) {
  const { sectionId } = props
  const [articles, setArticles] = useState<Array<Article> | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<false | undefined | string>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const notifications = useNotification()
  useEffect(() => {
    async function loadSection() {
      setLoading(true)
      try {
        const cachedData = localStorage.getItem(sectionId)
        if (cachedData) {
          const parsedData = JSON.parse(cachedData)
          const now = new Date().getTime()
          if (now - parsedData.timestamp < 10 * 60 * 1000) {
            // 10 minutes in milliseconds
            setArticles(parsedData.article)
            setLoading(false)
            return
          }
        }
        const response = await axios.get<{ articles: Array<Article> }>(
          `https://datacsistemashelp.zendesk.com/api/v2/help_center/pt-br/sections/${sectionId}/articles`,
          {
            // headers: {
            //   Authorization:
            //     'Basic ZGF0YWNAZGF0YWMuY29tLmJyL3Rva2VuOlJYOUhlUjI4Slh1dEpKTXEyTjRYOXhidkZEMjhuRFlyRkZuemtVMFE=',
            // },
            params: {
              sort_by: 'created_at',
              sort_order: 'desc',
            },
          },
        )
        setArticles(response.data.articles)
      } catch (err) {
        setError(notifications.extractAxiosError(err))
      } finally {
        setLoading(false)
      }
    }

    if (sectionId && isOpen) {
      loadSection()
    }
  }, [sectionId, isOpen])

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault()
          setOpen(true)
        }}
        sx={{
          fontSize: '0.7rem',
          minWidth: 0,
          pt: 0,
          pb: 0,
          pl: 0.5,
          pr: 0.5,
          color: (theme) => theme.palette.grey[500],
        }}
        variant="text"
      >
        {import.meta.env.VITE_VERSION}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          Notas de Versão{' '}
          {loading && <CircularProgress sx={{ ml: 1 }} size={14} />}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error">Não foi possível carregar o conteúdo</Alert>
          )}
          {articles?.map((a, index) => (
            <VersaoItem isOpen={index === 0} key={a.id} article={a} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}
