import { useNavigate, useParams } from 'react-router-dom'

import { Container, Tab, Tabs } from '@mui/material'
import { PageTitle } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'

import useMixProdutos, { MixProdutosModel } from 'hooks/queries/useMixProdutos'
import useTabs from 'hooks/useTabs'

import TabPanel from 'components/TabPanel'
import Loading from 'components/Displays/Loading'

import TabProdutosVinculados from './components/TabProdutosVinculados'
import TabDesontos from './components/TabDescontos/components'
import TabCadastroMixProduto from './components/TabCadastroMixProduto'
import Content from 'components/Layouts/Content'
import TabBonificacao from './components/TabBonificacao'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function MixProduto() {
  const { id: mixProdutoUuid } = useParams()

  const { useQueryByUuid } = useMixProdutos()
  const {
    data: mixProduto,
    isLoading,
    isFetching,
    error,
  } = useQueryByUuid<MixProdutosModel>(mixProdutoUuid as string)

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              { label: 'Mix de Produtos', link: '/mix-produtos' },
              {
                label: `${mixProduto?.nome}`,
                link: `/mix-produtos/mix-produto/${mixProduto?.uuid}`,
              },
            ]}
          ></Breadcrumbs>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs mix de produtos"
          >
            <Tab label="Cadastro" value={0} />
            <Tab label="Produtos" value={1} />
            <Tab label="Descontos" value={2} />
            <Tab label="Bonificações" value={3} />
          </Tabs>
        </ContentTitle.Root>
        <Content>
          <TabPanel value={value} index={0}>
            <TabCadastroMixProduto
              mixProduto={mixProduto || ({} as MixProdutosModel)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabProdutosVinculados
              error={error?.message}
              isLoading={isLoading}
              isFetching={isFetching}
              mixProduto={mixProduto || ({} as MixProdutosModel)}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabDesontos mixProdutoUuid={mixProdutoUuid || ''} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TabBonificacao mixProdutoUuid={mixProdutoUuid || ''} />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
