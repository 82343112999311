import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export interface DialogActionCloseProps {
  onClose: (result?: any) => Promise<void>
}

export default function DialogActionClose({ onClose }: DialogActionCloseProps) {
  return (
    <IconButton
      onClick={() => onClose()}
      sx={{ position: 'absolute', right: '11px' }}
    >
      <CloseIcon />
    </IconButton>
  )
}
