import { FormProvider } from '@data-c/providers'

import { Container } from '@mui/material'
import Delete from 'components/Delete'
import useFamiliaProdutos from 'hooks/queries/useFamiliaProdutos'

import Form from './components/Form'
import Table from './components/Table'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Assistant from 'components/MioCandidate/Assistant'
import Content from 'components/Layouts/Content'

export default function FamiliaProdutos() {
  const { useDelete } = useFamiliaProdutos()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article
          open={true}
          articleId="27025236952340"
        ></Assistant.Article>
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Família de Produtos',
                link: 'familia-produtos',
              },
            ]}
          />
          <Form />
        </ContentTitle.Root>
        <Content sx={{ margin: 1 }}>
          <Table />
          <Delete onDelete={mutateAsync} />
        </Content>
      </FormProvider>
    </Container>
  )
}
