import { Grid2 as Grid, TextField } from '@mui/material'
import { DialogProps } from '@toolpad/core/useDialogs'
import { Checkbox } from '@data-c/ui'
import useClasseProdutos, {
  ClasseProdutosModel,
} from 'hooks/queries/useClasseProdutos'
import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import useForm from 'hooks/useForm'
import Dialog from 'components/MioCandidate/Dialog'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

export default function Form({ open, onClose, payload }: DialogProps<any>) {
  const { data, handleChange: onChangeFormValue } = useForm(payload)

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useClasseProdutos()
  const { mutateAsync, isLoading } = useSubmit()

  function handleSubmitForm(formValues: ClasseProdutosModel) {
    setValidationErrors(null)
    schema
      .validate(formValues, { abortEarly: false })
      .then(async () => {
        const newFormValues = {
          ...formValues,
          ativo: data?.ativo || false,
        }
        await mutateAsync(newFormValues)
        onClose()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {})
  }

  return (
    <Dialog.Root open={open} onClose={onClose}>
      <Dialog.Header>
        <Dialog.Title>Alterar Serviço</Dialog.Title>
        <Dialog.ActionClose onClose={onClose} />
      </Dialog.Header>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2, xl: 2 }}>
            <TextField
              name="codigo"
              label="Código"
              value={data?.codigo || ''}
              onChange={onChangeFormValue}
              disabled
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 9, md: 10, lg: 10, xl: 10 }}>
            <TextField
              autoFocus
              name="nome"
              label="Nome"
              required
              value={data?.nome || ''}
              onChange={onChangeFormValue}
              {...validationProps('nome')}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
            <Checkbox
              label="Ativo"
              name="ativo"
              onChange={onChangeFormValue}
              checked={Boolean(data?.ativo)}
            />
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Footer>
        <Dialog.Actions>
          <Dialog.ActionCancelar onClick={() => onClose()} />
          <Dialog.ActionSalvar
            isLoading={isLoading}
            onClick={() => handleSubmitForm(data)}
          />
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog.Root>
  )
}
