import { findData, formatCurrency } from '@data-c/hooks'
import {
  DataTable,
  Flag,
  CurrencyCellStyle,
  DataTableOptions as Options,
} from '@data-c/ui'
import { Badge } from '@mui/material'
import Surface from 'components/Surface'
import { PedidoModel, SituacaoPedidoEnum } from 'hooks/queries/usePedidos'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
export interface TableProps {
  data: Array<PedidoModel>
}

export default function Table({ data }: TableProps) {
  const { data: configuracoes } = useQuery()
  const navigate = useNavigate()
  function riscarNegociacaoPerdida(indexNegociacao: number) {
    const situacao = data[indexNegociacao].situacao
    return situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA
      ? 'line-through'
      : 'none'
  }

  const handleClickItem = useCallback(
    (event: 'edit', data: PedidoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/pedidos/pedido/${data.uuid}`)
          break
      }
    },
    [navigate],
  )

  const totais = useMemo(() => {
    return data.reduce(
      (acumulador, valorAtual) => {
        const { total, totalNota, pesoTotal } = acumulador
        return {
          total: total + valorAtual.totalLiquido,
          totalNota: totalNota + valorAtual.totalComImposto,
          pesoTotal: pesoTotal + (valorAtual.pesoTotal || 0),
        }
      },
      { total: 0, totalNota: 0, pesoTotal: 0 },
    )
  }, [data])

  const pedidos = useMemo(() => {
    return data.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo || ''} - ${pedido.cliente?.nome}`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
      totalComImposto: formatCurrency(pedido?.totalComImposto || ''),
      pesoTotal: `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 3,
      }).format(pedido?.pesoTotal || 0)}Kg`,
      observacao: _.truncate(pedido.observacao, { length: 30 }),
    }))
  }, [data])

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'venda',
        label: 'Data',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'situacaoAsText',
        label: 'Situação',
        options: {
          sort: false,
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'representante',
        label: 'Vendedor',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'cliente',
        label: 'Cliente',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'observacao',
        label: 'Observação',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'pesoTotal',
        label: `Peso (${new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 3,
        }).format(totais?.pesoTotal || 0)}Kg)`,
        options: {
          display: Boolean(configuracoes?.exibe_peso),
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textAlign: 'right',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'totalLiquido',
        label: `Total (R$${formatCurrency(totais?.total || 0)})`,
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textAlign: 'right',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'totalComImposto',
        label: `Total Nota (R$${formatCurrency(totais?.totalNota || 0)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          sort: false,
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                ...CurrencyCellStyle().style,
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'comentarioStatus',
        label: 'Comentários',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value: {
            totalComentarios: number
            totalNaoLidas: number
          }) => {
            if (value.totalNaoLidas > 0) {
              return (
                <Badge color="error" badgeContent={value.totalNaoLidas}></Badge>
              )
            }

            if (value.totalComentarios > 0) {
              return <Flag isFlagged={true} type="primary" />
            }
            return ''
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => {
            return {
              style: {
                width: '100px',
                textAlign: 'center',
              },
            }
          },
          customBodyRender: (value) => {
            const _data = findData<PedidoModel>(data || [], value)
            return (
              <Options
                value={_data}
                onClick={handleClickItem}
                displayDeleteButton={false}
              />
            )
          },
        },
      },
    ],
    [data, handleClickItem, configuracoes],
  )

  return <DataTable columns={columns} data={pedidos || []} />
}
