import { useEffect } from 'react'
import { Grid2 as Grid, TextField, Typography } from '@mui/material'

import { Checkbox, CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useTabelaPreco, { TabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import useValidations from 'hooks/useValidations'

// import DropDownOperacaoPadrao from 'components/Inputs/DropDownOperacaoPadrao'
// import DropDownFatorPromocional from 'components/Inputs/DropDownFatorPromocional'

import * as yup from 'yup'
import FormProps from 'interfaces/FormProps'

import { useQuery } from 'hooks/queries/useConfiguracoes'
import Divider from 'components/Divider'
// import DropDownFatorPrecificacao from 'components/Inputs/DropDownFatorPrecificacao'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
})

export default function Form(props: FormProps<TabelaPrecoModel>) {
  const { data: tabelaPreco, view = 'dialog' } = props
  const { data: configuracoes } = useQuery()
  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    closeForm,
    toggleSubmit,
  } = useFormApi<TabelaPrecoModel>()
  const { formValues: data } = useFormData<TabelaPrecoModel>()

  useEffect(() => {
    if (tabelaPreco) {
      changeValues(tabelaPreco)
    }
  }, [])

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useTabelaPreco()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Cadastro Tabela de Preço',
        disableRestoreFocus: true,
      }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2, xl: 2 }}>
          <TextField
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
            onChange={onChangeFormValue}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 40 }}
            {...validationProps('nome')}
          />
        </Grid>

        {configuracoes?.usa_programa_fidelidade != 'DESATIVADO' && (
          <>
            <Grid size={{ xs: 12 }}>
              <Divider label="Programa de Fidelidade" />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <CurrencyTextField
                autoFocus
                name="valorPonto"
                label="Valor do Ponto"
                value={data?.valorPonto || ''}
                onChange={onChangeFormValue}
                {...validationProps('valorPonto')}
              />
              <Typography variant="body2">
                Configure quantos pontos o cliente ganha por cada real gasto.
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
              <Checkbox
                label="Resgate de Pontos"
                name="trocaPontos"
                size="small"
                onChange={onChangeFormValue}
                checked={Boolean(data?.trocaPontos)}
              />
            </Grid>
            {Boolean(data?.trocaPontos) && (
              <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
                <CurrencyTextField
                  label="Fator do valor de resgate"
                  value={data?.percentualResgate || ''}
                  onChange={(_, value) => {
                    changeValue('percentualResgate', value)
                  }}
                />
              </Grid>
            )}
          </>
        )}

        {/* <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <DropDownFatorPrecificacao
            name="fatorPromocionalUuid"
            label="Fator Promocional"
            value={data?.fatorPromocionalUuid || -1}
            onChange={(e) => {
              const fatorPromocionalUuid =
                parseInt(e.target.value) === -1 ? null : e.target.value
              changeValue('fatorPromocionalUuid', fatorPromocionalUuid)
            }}
          />
        </Grid> */}

        <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}
