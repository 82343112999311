import Form from 'pages/FormaPagamentos/components/Form'
import { FormaPagamentoModel } from 'hooks/queries/useFormaPagamento'

export interface TabCadastroProps {
  formaPagamento: FormaPagamentoModel
}

export default function TabCadastro(props: TabCadastroProps) {
  return <Form view="plain" data={props.formaPagamento} />
}
