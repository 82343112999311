import ProdutoTransporter from 'components/Transporters/ProdutosTransporter'
import { Grid2 as Grid, TextField } from '@mui/material'
import { Button, ButtonContainer } from '@data-c/ui'
import { onlyNumbers, useForm, useValidations } from '@data-c/hooks'

import * as yup from 'yup'
import useMixProdutos, {
  ProdutoBonificacaoModel,
} from 'hooks/queries/useMixProdutos'

const schema = yup.object().shape({
  quantidade: yup.number().required('Informe uma quantidade mínima'),
  produto: yup.object().required('Informe um produto'),
})

export interface FormProps {
  regraUuid: string
}

export default function Form(props: FormProps) {
  const { regraUuid } = props
  const { data, changeValue, setData } = useForm<ProdutoBonificacaoModel>({
    regraUuid,
  })
  const { setValidationErrors, validationProps } = useValidations()
  const { useAdicionarProdutoBonificacao } = useMixProdutos()
  const { mutateAsync, isLoading } = useAdicionarProdutoBonificacao()

  async function handleClick() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        await mutateAsync({ ...data, regraUuid })
        setData({ regraUuid })
        close()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12, sm: 10, md: 10, lg: 10, xl: 10 }}>
        <ProdutoTransporter
          autoFocus
          name="produto"
          value={data?.produto || null}
          onChange={(produto) => {
            setData((prev) => ({
              ...prev,
              produto: produto,
              produtoUuid: produto?.uuid || null,
            }))
          }}
          {...validationProps('produto')}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
        <TextField
          label="Quantidade"
          name="quantidade"
          value={data.quantidade || ''}
          onChange={(e) => {
            const v = e.target.value
            let v1 = onlyNumbers(v)
            changeValue('quantidade', v1)
          }}
          {...validationProps('quantidade')}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <ButtonContainer>
          <Button
            isLoading={isLoading}
            onClick={handleClick}
            variant="contained"
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Grid>
    </Grid>
  )
}