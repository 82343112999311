import { Box, Typography } from '@mui/material'
import {
  obterCorDaSituacao,
  SituacaoPedidoEnum,
} from 'hooks/queries/usePedidos'
import CircleIcon from '@mui/icons-material/Circle'

interface FlagSituacaoProps {
  situacao: SituacaoPedidoEnum
  situacaoAsText: string
}
export default function FlagSituacao(props: FlagSituacaoProps) {
  const { situacao, situacaoAsText } = props
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      gap={1}
    >
      <CircleIcon
        sx={{
          color: `${obterCorDaSituacao(situacao)}`,
          fontSize: 'small',
        }}
      />
      <Typography>{situacaoAsText}</Typography>
    </Box>
  )
}
