import { LabelValue } from '@data-c/ui'
import {
  Box,
  Collapse,
  Grid2 as Grid,
  Typography,
  useTheme,
} from '@mui/material'

import AutoCompleteCidade from 'components/AutoCompleteCidade'
import CEPTextField from 'components/CEPTextField'
import DropDownUF from 'components/Inputs/DropDownUF'
import InscricaoEstadualTextField from 'components/InscricaoEstadualTextField'
import MaskedTextField from 'components/MaskedTextField'

import DocumentoTextField from 'components/DocumentoTextField'
import TextField from 'components/Inputs/TextField'
import useClientes, { ClientesModel } from 'hooks/queries/useClientes'
import { Data } from 'hooks/queries/usePositivacaoClientes'
import React, { useEffect, useState } from 'react'

interface InfoClienteProps {
  clienteProcurado: Data
}

export default function InfoCliente(props: InfoClienteProps) {
  const { clienteProcurado } = props

  const theme = useTheme()
  const [collapse, setCollapse] = useState(false)
  const handleChangeCollapse = () => {
    setCollapse(!collapse)
  }
  const { readById } = useClientes()
  const [cliente, setCliente] = useState<ClientesModel>()

  useEffect(() => {
    async function obterCliente() {
      const response = await readById<ClientesModel>(
        clienteProcurado?.uuid || '',
      )
      if (response) {
        setCliente(response)
      }
    }
    if (collapse) {
      obterCliente()
    }
  }, [collapse])

  return (
    <React.Fragment>
      <LabelValue label="Cliente">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            textWrap: 'nowrap',
          }}
        >
          {`${clienteProcurado.codigo || ''} - ${clienteProcurado?.nome || ''}`}
          <Typography
            fontSize={13}
            onClick={handleChangeCollapse}
            color={theme.palette.primary.dark}
            sx={{ cursor: 'pointer', display: 'inline' }}
          >
            {collapse ? 'ver menos' : 'ver mais'}
          </Typography>
        </Box>
      </LabelValue>
      <Collapse in={collapse}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <TextField
              disabled
              name="codigo"
              label="Código"
              value={cliente?.codigo || ''}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <DocumentoTextField
              name="cnpjcpf"
              value={cliente?.cnpjcpf || ''}
              InputProps={{
                disabled: true,
              }}
              inputProps={{
                label: 'CPF/CNPJ',
              }}
            />
          </Grid>
          <Grid size={12}>
            <InscricaoEstadualTextField
              name="ie"
              label="Inscrição Estadual"
              value={cliente?.ie || ''}
              disabled={true}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="nome"
              label="Razão Social"
              required
              value={cliente?.nome || ''}
              inputProps={{ maxLength: 40 }}
              disabled={true}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="usual"
              label="Nome Fantasia"
              value={cliente?.usual || ''}
              disabled
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <CEPTextField
              name="cep"
              disabled={true}
              inputProps={{
                label: 'CEP',
              }}
              InputProps={{
                disabled: true,
              }}
              value={cliente?.cep || ''}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <DropDownUF
              disabled={true}
              name="uf"
              label="Estado"
              value={cliente?.cidadeModel?.uf || ''}
            />
          </Grid>
          <Grid size={12}>
            <AutoCompleteCidade
              name="cidade"
              label="Cidade"
              disabled={true}
              value={cliente?.cidadeModel}
              uf={cliente?.uf || null}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="bairro"
              label="Bairro"
              value={cliente?.bairro || ''}
              disabled={true}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="endereco"
              label="Endereço"
              value={cliente?.endereco || ''}
              inputProps={{ maxLength: 40 }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
            <TextField
              name="numero"
              label="Número"
              value={cliente?.numero || ''}
              inputProps={{ maxLength: 30 }}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 10, md: 10, lg: 10, xl: 10 }}>
            <TextField
              name="complemento"
              label="Complemento"
              value={cliente?.complemento || ''}
              disabled={true}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="contato"
              label="Contato"
              value={cliente?.contato || ''}
              inputProps={{ maxLength: 30 }}
              disabled={true}
            />
          </Grid>
          <Grid size={12}>
            <MaskedTextField
              name="telefoneAsText"
              mask="(99) 9999-9999"
              inputProps={{
                label: 'Telefone',
                InputProps: { disabled: true },
              }}
              value={cliente?.telefoneAsText || ''}
            />
          </Grid>
          <Grid size={12}>
            <MaskedTextField
              mask="(99) 9 9999-9999"
              name="celularAsText"
              inputProps={{
                label: 'Celular',
                InputProps: { disabled: true },
              }}
              value={cliente?.celularAsText || ''}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              name="email"
              label="E-mail"
              value={cliente?.email || ''}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            <TextField
              name="observacao"
              label="Observação"
              multiline
              rows={4}
              value={cliente?.observacao || ''}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </React.Fragment>
  )
}
