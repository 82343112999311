import {
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { useQuery } from 'hooks/queries/useConfiguracoes'
import { PedidoModel } from 'hooks/queries/usePedidos'

import { HeaderPedido } from './components/HeaderPedido'
import ItensPedido from './components/ItensPedido'

import { ButtonContainer } from '@data-c/ui'
import { Close } from '@mui/icons-material'
import Divider from 'components/Divider'
import MobileOrDesktopRenderer from 'components/MobileOrDesktopRenderer'
import Surface from 'components/Surface'
import useUsuario, { UsuarioModel } from 'hooks/queries/useUsuario'
import { useEffect, useState } from 'react'
import Joyride, {
  CallBackProps,
  STATUS,
  TooltipRenderProps,
} from 'react-joyride'
import { TabsMostrarPedidosOuDetalhes } from './components/TabsMostrarPedidosOuDetalhes'

interface TabNegociacaoProps {
  pedido?: PedidoModel
  isLoading: boolean
  isFetching: boolean
}

const steps = [
  {
    title: 'Tabela de Preço',
    target: '.passo1',
    content:
      'Selecione uma tabela de preço para continuar com o pedido. Essa etapa é obrigatória!',
  },
]

function CustomTooltip(props: TooltipRenderProps) {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  } = props
  return (
    <Surface {...tooltipProps}>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {step.title && (
            <Typography variant="subtitle2">{step.title}</Typography>
          )}
          <ButtonContainer>
            <IconButton {...closeProps}>
              <Close />
            </IconButton>
          </ButtonContainer>
        </Stack>
        <Divider />

        <Box sx={{ minHeight: '60px' }}>{step.content}</Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            sx={{
              justifyContent: 'flex-start',
              textAlign: 'left',
            }}
            variant="text"
            {...skipProps}
          >
            {skipProps.title}
          </Button>

          <Stack spacing={1} direction="row" alignItems="center">
            {index > 0 && <Button {...backProps}>{backProps.title}</Button>}
            {continuous && (
              <Button variant="contained" {...primaryProps}>
                {primaryProps.title}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Surface>
  )
}

export default function TabNegociacao(props: TabNegociacaoProps) {
  const { pedido, isLoading, isFetching } = props
  const { data: configuracoes } = useQuery()
  const { useQuery: useObterUsuario } = useUsuario()
  const { data: usuarioLogado } = useObterUsuario()
  const [showRide, setShowRide] = useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    if (!pedido?.tabelaPreco) {
      setShowRide(true)
    } else {
      setShowRide(false)
    }
  }, [pedido])

  function handleJoyrideCallback(data: CallBackProps) {
    const { status } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(
        status as 'skipped' | 'finished',
      )
    ) {
      setShowRide(false)
    }
  }

  if (!pedido) {
    return <div>Pedido não encontrado</div>
  }

  return (
    <Box>
      <Joyride
        steps={steps}
        run={showRide}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        scrollDuration={1000}
        locale={{
          back: 'Voltar',
          close: 'Fechar',
          last: 'Fim',
          next: 'Próximo',
          nextLabelWithProgress: 'Próximo ({step} de {steps})',
          open: 'Abrir',
          skip: 'Pular',
        }}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: theme.palette.primary.main,
          },
        }}
        tooltipComponent={CustomTooltip}
      />

      <Grid container>
        <MobileOrDesktopRenderer
          mobileChildren={
            <TabsMostrarPedidosOuDetalhes
              pedido={pedido}
              configuracoes={configuracoes}
              usuarioLogado={usuarioLogado}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          }
          desktopChildren={
            <>
              <Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
                <HeaderPedido pedido={pedido} configuracoes={configuracoes} />
              </Grid>
              <Grid size={{ xs: 12, sm: 9, md: 9, lg: 9, xl: 9 }}>
                <ItensPedido
                  pedido={pedido}
                  usuarioLogado={usuarioLogado || ({} as UsuarioModel)}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  configuracoes={configuracoes}
                />
              </Grid>
            </>
          }
        />
      </Grid>
    </Box>
  )
}
