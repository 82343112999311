import { Container } from '@mui/material'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'
import { filters } from 'hooks/queries/usePositivacaoClientes'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
export default function AtivacaoClientes() {
  return (
    <Container>
      <MioFilter.Provider
        filterValues={{
          anoMes: filters.anoMes?.value,
          situacaoPedido: filters.situacaoPedido?.value,
          categoriaKey: filters.categoriaKey?.value,
          periodoInicial: filters.periodoInicial?.value,
          periodoInicialPositivacao: filters.periodoInicialPositivacao?.value,
        }}
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              { label: 'Ativação de Clientes', link: '/ativacao-clientes' },
            ]}
          ></Breadcrumbs>
          <Filter />
        </ContentTitle.Root>
        <Content>
          <Table />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
