import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { ABCClientesFilters1 } from 'hooks/queries/useRelatorioABCClientes'
import { useMemo } from 'react'

export default function FilterForm() {
  const { changeFilterValue } = useFilterApi()

  const { filterValues } = useFilter<ABCClientesFilters1>()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  return (
    <Stack spacing={1.5}>
      <DateRangePicker
        onChange={(value) => {
          changeFilterValue('dateRange', value)
        }}
        value={filterValues?.dateRange}
      />
      <TextField
        select
        label="Situação do Pedido"
        value={filterValues?.situacaoPedido || ''}
        onChange={(e) => changeFilterValue('situacaoPedido', e.target.value)}
      >
        <MenuItem value="emissao">Faturados</MenuItem>
        <MenuItem value="venda">Em processamento</MenuItem>
      </TextField>

      <TextField
        type="number"
        label="A"
        value={filterValues?.percentualA || ''}
        onChange={(e) => changeFilterValue('percentualA', e.target.value)}
      />
      <TextField
        type="number"
        label="B"
        value={filterValues?.percentualB || ''}
        onChange={(e) => changeFilterValue('percentualB', e.target.value)}
      />
      <TextField
        type="number"
        label="C"
        value={filterValues?.percentualC || ''}
        onChange={(e) => changeFilterValue('percentualC', e.target.value)}
      />
      <TimeVendasTransporter
        name="representanteUuid"
        label="Representante"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        InputLabelProps={{ shrink: true }}
      />
      <Autocomplete
        aria-label="Etiquetas"
        multiple
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
  )
}
