import { FormProvider } from '@data-c/providers'

import { Container } from '@mui/material'

import useVendedores from 'hooks/queries/useVendedores'

import Delete from 'components/Delete'

import Table from './components/Table'
import Filter from './components/Filter'
import Form from './components/Form'

import Content from 'components/Layouts/Content'

import MioFilter from 'components/MioCandidate/Filter'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Assistant from 'components/MioCandidate/Assistant'

export default function Vendedores() {
  const { useDelete } = useVendedores()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article
          open={true}
          articleId="28518211079316"
        ></Assistant.Article>
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <MioFilter.Provider filterValues={{}}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Vendedores',
                  link: '/vendedores/lista',
                },
              ]}
            />
            <Filter />
            <Form view="dialog" />
          </ContentTitle.Root>

          <Content>
            <Table />
            <Delete onDelete={handleDelete} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
