import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'

import useMixProdutos from 'hooks/queries/useMixProdutos'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'

import Delete from 'components/Delete'
import Content from 'components/Layouts/Content'
import Form from './components/Form'
import Table from './components/Table'
import Assistant from 'components/MioCandidate/Assistant'
import AssistantArticle from 'components/MioCandidate/Assistant/AssistantArticle'

export default function MixProdutos() {
  const { useDelete } = useMixProdutos()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <AssistantArticle
          articleId="26976721038100"
          open={true}
        ></AssistantArticle>
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Mix de Produtos',
                link: '/mix-produtos/lista',
              },
            ]}
          />
          <ButtonContainer>
            <Form />
          </ButtonContainer>
        </ContentTitle.Root>
        <Content>
          <Table />
          <Delete onDelete={handleDelete} />
        </Content>
      </FormProvider>
    </Container>
  )
}
