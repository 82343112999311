import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { useEffect, useMemo } from 'react'
import { FilterForm } from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { filterValues, isOpen, appliedValues } = useFilter()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  useEffect(() => {
    console.log({ appliedValues })
    console.log(appliedValues?.representante)
  }, [appliedValues])

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Previsões de entrega"
        />

        <MioFilter.Chips>
          <MioFilter.Chip
            name="codigoPedido"
            label={appliedValues?.codigoPedido}
          >
            <TextField
              label="Código do Pedido"
              value={filterValues?.codigoPedido || ''}
              onChange={(e) =>
                changeFilterValue('codigoPedido', e.target.value)
              }
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="representante"
            label={appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="representanteUuid"
              label="representante"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip name="cliente" label={appliedValues?.cliente?.nome}>
            <ClientesTransporter
              name="clienteUuid"
              label={filterValues?.cliente}
              value={filterValues?.cliente || null}
              vendedorUuid={filterValues?.representante?.uuid || ''}
              onChange={(cliente) => {
                changeFilterValue('cliente', cliente)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name={`etiquetaKeyValue`}
            label={`${
              appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta:'
                : 'Etiquetas:'
            } ${appliedValues?.etiquetaKeyValue?.map((etiqueta: any) => {
              return ' ' + etiqueta.label
            })}`}
          >
            <Autocomplete
              multiple
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
