import { formatCurrency } from '@data-c/hooks'

import { ItemPedidoModel, PedidoModel } from 'hooks/queries/usePedidos'
import { Stack } from '@mui/material'

import { UsuarioModel } from 'hooks/queries/useUsuario'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import { useMemo } from 'react'
import CardItem, { FormatedItemInterface } from './components/CardItem'

interface MobileViewProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  configuracoes?: ConfiguracoesModel
  onClickItem: (
    event: 'edit' | 'delete' | 'perder_item',
    data: ItemPedidoModel,
  ) => void
  podeEditar: boolean
}

export default function MobileView(props: MobileViewProps) {
  const { pedido, configuracoes, onClickItem, podeEditar } = props

  let pesoTotalDoPedido = 0
  const itens = useMemo(
    () =>
      pedido?.itens
        ? pedido.itens.map((item) => {
            pesoTotalDoPedido += item.pesoTotal || 0
            return {
              ...item,
              codigoProduto: item.produto?.codigo,
              nomeProduto: item.produto?.nome,
              nomeCor: item.cor?.nome,
              precoTotalLiquido: formatCurrency(item.precoTotalLiquido),
              precoUnitarioLiquido: formatCurrency(item.precoUnitarioLiquido),
              precoDeTabela: formatCurrency(item.precoDeTabela),
              precoTotalIcms: formatCurrency(item.precoTotalIcms),
              precoTotalIpi: formatCurrency(item.precoTotalIpi),
              precoTotalComImposto: formatCurrency(item.precoTotalLiquido),
              percentualDesconto: formatCurrency(item.percentualDesconto),
              fatorPrecificacaoNome: item.fatorPrecificacao?.descricao,
              pesoAsText: `${item?.pesoTotal || ''} Kg`,
            }
          })
        : [],
    [pedido],
  )

  return (
    <Stack gap={1}>
      {itens.map((item) => {
        return (
          <CardItem
            pedido={pedido}
            onClickItem={onClickItem}
            exibirPeso={Boolean(configuracoes?.exibe_peso)}
            podeEditar={podeEditar}
            item={item as FormatedItemInterface}
          />
        )
      })}
    </Stack>
  )
}
