import { Checkbox, theme } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { ItemTabelaPrecoFilter1 } from 'hooks/queries/useTabelaPreco'
import FilterForm from './FilterForm'

export default function Filter() {
  const { onChangeFilterValue, changeFilterValue, applyFilterValues } =
    useFilterApi()
  const { data: configuracoes } = useQuery()
  const { filterValues, appliedValues } = useFilter<ItemTabelaPrecoFilter1>()

  return (
    // <FilterContainer
    //   title="Pesquisar por produtos da tabela"
    //   triggerButtonProps={{ variant: 'outlined' }}
    //   disableds={['categoriaKey']}
    //   renderLabel={(filterLabel: string, filterValue: any) => {
    //     if (filterLabel === 'qtdMinima') {
    //       return 'Apenas grade fechada'
    //     }
    //     if (filterLabel === 'codigoNomeCor') {
    //       return `Cor - ${filterValue}`
    //     }
    //     if (filterLabel === 'codigoNomeGrade') {
    //       return `Grade - ${filterValue}`
    //     }
    //     if (filterLabel === 'Categoria') {
    //       return `${filterLabel} - ${filterValue?.nome || ''}`
    //     }
    //     if (filterLabel === 'Tipo de Categoria') {
    //       return `${filterLabel} - ${
    //         (filterValue as string).toUpperCase() || ''
    //       }`
    //     }
    //     return `${filterLabel} - ${filterValue}`
    //   }}
    // >
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar por produtos da tabela"
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="codigoProduto"
            label={appliedValues?.codigoProduto}
          >
            <TextField
              autoFocus
              name="codigoProduto"
              value={filterValues?.codigoProduto}
              label="Codigo do Produto"
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>
          <MioFilter.Chip name="nomeProduto" label={appliedValues?.nomeProduto}>
            <TextField
              name="nomeProduto"
              value={filterValues?.nomeProduto}
              label="Nome do Produto"
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="codigoNomeCor"
            label={appliedValues?.codigoNomeCor}
          >
            {configuracoes?.modulo_pedido === 'COR' ||
              (configuracoes?.modulo_pedido === 'GRADE' && (
                <TextField
                  name="codigoNomeCor"
                  value={filterValues?.codigoNomeCor}
                  label={'Variação 1'}
                  onChange={onChangeFilterValue}
                  placeholder="Código ou nome da variação 1"
                />
              ))}
          </MioFilter.Chip>
          <MioFilter.Chip
            name="codigoNomeGrade"
            label={appliedValues?.codigoNomeGrade}
          >
            {configuracoes?.modulo_pedido === 'GRADE' && (
              <TextField
                name="codigoNomeGrade"
                value={filterValues?.codigoNomeGrade}
                label={'Variação 2'}
                onChange={onChangeFilterValue}
                placeholder="Código ou nome da variação 2"
              />
            )}
          </MioFilter.Chip>
          {/* <MioFilter.Chip
            name="apenasQuantidadeMinima"
            label={'a - ' + appliedValues?.apenasQuantidadeMinima}
          >
            {Boolean(configuracoes?.usa_grade_fechada) && (
              <Checkbox
                name="apenasQuantidadeMinima"
                label="Apenas grade fechada"
                checked={filterValues?.apenasQuantidadeMinima}
                onChange={(e) =>
                  changeFilterValue('apenasQuantidadeMinima', e.target.checked)
                }
              />
            )}
          </MioFilter.Chip> */}
          <MioFilter.Chip
            name="categoriaKey"
            label={`Tipo de categora - ${appliedValues?.categoriaKey}`}
          >
            <TextField
              select
              name="categoriaKey"
              label={filterValues?.categoriaKey}
              value={filterValues?.categoriaKey || 'nenhuma'}
              onChange={(e) => {
                const key = e.target.value
                changeFilterValue('categoriaKey', key)
              }}
            >
              <MenuItem value="nenhuma">Todas</MenuItem>
              <MenuItem value="classe">Classe</MenuItem>
              <MenuItem value="familia">Família</MenuItem>
              <MenuItem value="grupo">Grupo</MenuItem>
              <MenuItem value="linha">Linha</MenuItem>
            </TextField>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="categoriaValue"
            label={`${appliedValues?.categoriaValue?.nome}`}
          >
            <Stack
              sx={{
                gap: 2,
                border: `solid 1px ${theme.palette.primary.main}`,
                padding: 2,
                borderRadius: '4px',
              }}
            >
              {filterValues?.categoriaKey === 'classe' && (
                <ClasseProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: ClasseProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'familia' && (
                <FamiliaProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: FamiliaProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'grupo' && (
                <GrupoProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: GrupoProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'linha' && (
                <LinhaProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: LinhaProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}
            </Stack>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="apenasQuantidadeMinima"
            label={`${
              appliedValues?.apenasQuantidadeMinima
                ? 'Apenas grade fechada'
                : null
            }`}
          >
            {Boolean(configuracoes?.usa_grade_fechada) && (
              <Checkbox
                name="apenasQuantidadeMinima"
                label="Apenas grade fechada"
                checked={filterValues?.apenasQuantidadeMinima}
                onChange={onChangeFilterValue}
              />
            )}
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
    // </FilterContainer>
  )
}
