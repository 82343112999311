import { Box, CircularProgress } from '@mui/material'

interface NextPageLoadingProps {
  isFetchingNextPage: boolean
}

export default function InfiniteScrollLoading(props: NextPageLoadingProps) {
  const { isFetchingNextPage } = props
  if (!isFetchingNextPage) {
    return
  }
  return (
    <Box
      position={'relative'}
      bottom={1}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: 'white',
        height: 'fit-content',
      }}
    >
      <CircularProgress></CircularProgress>
    </Box>
  )
}
