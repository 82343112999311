import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

export function useMobileComponents() {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | number>('sm')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint))

  return { isMobile, breakpoint, setBreakpoint }
}
