import { Checkbox } from '@data-c/ui'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { Stack } from '@mui/material'
import CategoriaProdutoTextField from './CategoriaProdutoTextField'

export default function FilterForm() {
  const { filterValues } = useFilter()
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()
  return (
    <Stack spacing={1.5}>
      <CategoriaProdutoTextField
        value={{
          categoriaKey: filterValues.categoriaKey || '',
          categoriaValue: filterValues.categoriaValue || '',
        }}
        onChange={(value) => {
          changeFilterValue('categoriaKey', value.categoriaKey)
          changeFilterValue('categoriaValue', value.categoriaValue)
        }}
      />
      <Checkbox
        name="apenasQuantidadeMinima"
        label="Apenas grade fechada"
        checked={filterValues?.apenasQuantidadeMinima}
        onChange={onChangeFilterValue}
      />
    </Stack>
  )
}
