import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import DropDownFunilVendas from 'components/Inputs/DropDownFunilVendas'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { TaxaConversaoNegociacaoModelFilters1 } from 'hooks/queries/useRelatorioTaxaConversaoNegociacao'
import { useEffect, useMemo } from 'react'

export default function FilterForm() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } =
    useFilter<TaxaConversaoNegociacaoModelFilters1>()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <Stack spacing={2}>
      <DateRangePicker
        onChange={(value) => {
          changeFilterValue('dateRange', value)
        }}
        value={filterValues?.dateRange}
      />

      <DropDownFunilVendas
        label="Funil de Vendas"
        name="funilUuid"
        value={filterValues?.funilVenda?.uuid || ''}
        onChange={(funil) => {
          changeFilterValue('funilVenda', funil)
        }}
      />

      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="representanteUuid"
        label="representante"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        InputLabelProps={{ shrink: true }}
      />

      <Autocomplete
        multiple
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
  )
}
