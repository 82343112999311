import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { useTableRootContext } from './DataTableRootProvider'

interface SelectionCounterProps {
  countTitle?: string
  gender?: 'masculino' | 'feminino'
}

export default function SelectionCounter(props: SelectionCounterProps) {
  const { countTitle, gender } = props

  const { rowsSelectedId } = useTableRootContext()
  const countQuantidade = rowsSelectedId?.length

  const flexSufixCountTitle = useMemo(() => {
    if (!countTitle) return ''

    const selecionadoText =
      gender === 'feminino' ? 'selecionada' : 'selecionado'
    const selecionadosText =
      gender === 'feminino' ? 'selecionadas' : 'selecionados'
    const nenhumText = gender === 'feminino' ? 'Nenhuma' : 'Nenhum'

    return countQuantidade === 1
      ? `${countTitle} ${selecionadoText}`
      : countQuantidade && countQuantidade > 1
      ? `${countTitle
          .split(' ')
          .map((item) => `${item}s`)
          .join('  ')} ${selecionadosText}`
      : `${nenhumText} ${countTitle} ${selecionadoText}`
  }, [countQuantidade])

  return (
    <Stack>
      <Typography
        variant="body2"
        fontSize="13px"
        fontWeight="500"
        whiteSpace="nowrap"
      >
        {countQuantidade && countQuantidade > 0 ? countQuantidade : ''}{' '}
        {flexSufixCountTitle}
      </Typography>
    </Stack>
  )
}
