import { Dialog } from '@data-c/ui'
import useMixProdutos, {
  MixProdutoBonificacaoModel,
} from 'hooks/queries/useMixProdutos'
import Table from './components/Table'
import Form from './components/Form'
import { Grid2 as Grid } from '@mui/material'
import { FormProvider } from '@data-c/providers'
import Delete from 'components/Delete'

interface UseDialogProps<T> {
  isOpen: boolean
  data?: T
  closeDialog: (e?: object, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

interface ProdutoProps extends UseDialogProps<MixProdutoBonificacaoModel> {
  mixProdutoUuid: string
  regraUuid: string
}

export default function Produtos(props: ProdutoProps) {
  const { isOpen: isOpenDialog, closeDialog, regraUuid, mixProdutoUuid } = props
  const { useRemoverProdutoBonificacao } = useMixProdutos()
  const { mutateAsync } = useRemoverProdutoBonificacao()
  return (
    <Dialog
      title="Bonificações"
      open={isOpenDialog}
      maxWidth="md"
      actions="none"
      onClose={closeDialog}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Form regraUuid={regraUuid} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <FormProvider>
            <Table
              mixProdutoUuid={mixProdutoUuid}
              bonificacaoUuid={regraUuid}
            />
            <Delete onDelete={mutateAsync} />
          </FormProvider>
        </Grid>
      </Grid>
    </Dialog>
  )
}