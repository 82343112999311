import { FormProvider } from '@data-c/providers'

import { Container } from '@mui/material'
import useCrmAtividades from 'hooks/queries/useCrmAtividades'

import Delete from 'components/Delete'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
import { filters } from 'hooks/queries/useCrmAtividades'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'

export default function CrmAtividades() {
  const { mutateAsync: handleDelete } = useCrmAtividades().useDelete()
  return (
    <Container>
      <FormProvider>
        <MioFilter.Provider
          filterValues={{
            tipoAtividade: filters.tipoAtividade?.value,
            dateRange: filters.dateRange?.value,
            situacao: filters.situacao?.value,
          }}
        >
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Atividades',
                  link: '/crm/atividades',
                },
              ]}
            ></Breadcrumbs>
            <Filter />
            <Form />
            {/* <HelpButton link="https://ember-health-873.notion.site/Manuten-o-de-Atividades-ac6fc0d250784c4e9b385643c213f6df?pvs=4" /> */}
          </ContentTitle.Root>
          <Content>
            <Table />
            <Delete onDelete={handleDelete} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
