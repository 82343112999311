import { Stack, Typography } from '@mui/material'
import errorImage from 'assets/images/error.svg'

export default function DataTableError({
  errorMessage,
}: {
  errorMessage: string
}) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={2}
      padding={2}
      maxWidth="400px"
      minWidth="200px"
    >
      <Stack
        width="250px"
        height="250px"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        sx={{
          background:
            'linear-gradient(to top, #ffffff, #fdfcfd, #fafafa, #f8f7f8, #f5f5f6);',
        }}
      >
        <img
          src={errorImage}
          alt="Imagem de erro"
          width="148px"
          height="148px"
        />
      </Stack>
      <Stack gap={1} textAlign="center">
        <Typography variant="h4" component="h2" fontWeight="600">
          Ops! Algo deu errado
        </Typography>

        <Typography variant="body2">{errorMessage}</Typography>
      </Stack>
    </Stack>
  )
}
