import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'

import { onlyNumbers, plurilize } from '@data-c/hooks'
import { useUserContext } from 'components/Contexts/UserContext'
import DatePicker from 'components/DatePicker'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { PositivacaoClientesFilters1 } from 'hooks/queries/usePositivacaoClientes'
import { useEffect, useMemo } from 'react'
import FilterForm from './FilterForm'

import { DateTime } from 'luxon'

export default function Filter() {
  const theme = useTheme()
  const { temPermissao, user } = useUserContext()
  const { filterValues, isOpen, appliedValues } =
    useFilter<PositivacaoClientesFilters1>()
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])
  const pInicial = (filterValues?.periodoInicial || 0) * 1
  const aInicial = (filterValues?.periodoInicialPositivacao || 0) * 1
  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Gerar Relatório"
          disabled
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="situacaoPedido"
            label={`Situacao -  ${
              appliedValues?.situacaoPedido === 'emissao'
                ? 'Faturados'
                : 'Em processamento'
            }`}
            disabledDelete
          >
            <TextField
              select
              name="situacaoPedido"
              label="Situação"
              value={filterValues?.situacaoPedido || ''}
              onChange={(e) => {
                const situacaoPedido = e.target.value
                changeFilterValue('situacaoPedido', situacaoPedido)
              }}
            >
              <MenuItem value="emissao">Faturados</MenuItem>
              <MenuItem value="venda">Em processamento</MenuItem>
            </TextField>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="anoMes"
            label={`Data - ${DateTime.fromFormat(
              appliedValues?.anoMes || '',
              'yyyy-mm-dd',
            ).toFormat('mm/yyyy')}`}
            disabledDelete
          >
            <DatePicker
              openTo="month"
              views={['year', 'month']}
              format="MM/yyyy"
              label={'Data Base'}
              onChange={(date: string | null) => {
                changeFilterValue('anoMes', date)
              }}
              value={filterValues?.anoMes || ''}
              // fromFormat="yyyy-MM"
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="periodoInicial"
            label={`Periodo de vendas - ${appliedValues?.periodoInicial} ${
              appliedValues?.periodoInicial === 1 ? ' mes' : ' meses'
            }`}
            disabledDelete
          >
            <TextField
              type="number"
              name="periodoInicial"
              label={`Considerar as vendas ${plurilize(
                'do',
                pInicial,
              )} ${plurilize('último', pInicial)} ${plurilize(
                'mes',
                pInicial,
                true,
                'es',
              )}`}
              value={filterValues?.periodoInicial || ''}
              onChange={(e) => {
                const periodoInicial = onlyNumbers(e.target.value, false)
                changeFilterValue('periodoInicial', periodoInicial)
              }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name={'periodoInicialPositivacao'}
            label={`Periodo Ativação - ${
              appliedValues?.periodoInicialPositivacao
            } ${
              appliedValues?.periodoInicialPositivacao === 1 ? ' mes' : ' meses'
            }`}
            disabledDelete
          >
            <TextField
              type="number"
              name="periodoInicialPositivacao"
              label={`Considerar clientes ativados com compra ${plurilize(
                'no',
                aInicial,
              )} ${plurilize('último', aInicial)} ${plurilize(
                'mes',
                aInicial,
                true,
                'es',
              )}`}
              value={filterValues?.periodoInicialPositivacao || ''}
              onChange={(e) => {
                const periodoInicialPositivacao = onlyNumbers(
                  e.target.value,
                  false,
                )
                changeFilterValue(
                  'periodoInicialPositivacao',
                  periodoInicialPositivacao,
                )
              }}
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            name="representante"
            label={'Vendedor - ' + appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="representante"
              label="Representante"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="categoriaKey"
            label={'Tipo de Categoria - ' + appliedValues?.categoriaKey}
          >
            <TextField
              select
              name="categoriaKey"
              label={filterValues?.categoriaKey}
              value={filterValues?.categoriaKey || 'nenhuma'}
              onChange={(e) => {
                const key = e.target.value
                changeFilterValue('categoriaKey', key)
              }}
            >
              <MenuItem value="nenhuma">Todas</MenuItem>
              <MenuItem value="classe">Classe</MenuItem>
              <MenuItem value="familia">Família</MenuItem>
              <MenuItem value="grupo">Grupo</MenuItem>
              <MenuItem value="linha">Linha</MenuItem>
            </TextField>
          </MioFilter.Chip>

          <MioFilter.Chip
            name="categoriaValue"
            label={'Categoria - ' + appliedValues?.categoriaValue?.nome}
          >
            <Stack
              sx={{
                gap: 2,
                border: `solid 1px ${theme.palette.primary.main}`,
                padding: 2,
                borderRadius: '4px',
              }}
            >
              {filterValues?.categoriaKey === 'classe' && (
                <ClasseProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: ClasseProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'familia' && (
                <FamiliaProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: FamiliaProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'grupo' && (
                <GrupoProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: GrupoProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}

              {filterValues?.categoriaKey === 'linha' && (
                <LinhaProdutoTransporter
                  value={filterValues?.categoriaValue || null}
                  onChange={(value: LinhaProdutosModel | null) => {
                    changeFilterValue('categoriaValue', value)
                  }}
                />
              )}
            </Stack>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="etiquetaKeyValue"
            label={`${
              appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta:'
                : 'Etiquetas:'
            } ${
              Array.isArray(appliedValues?.etiquetaKeyValue)
                ? appliedValues.etiquetaKeyValue
                    .map((etiqueta: any) => ' ' + etiqueta.label)
                    .join('')
                : ''
            }`}
          >
            <Autocomplete
              multiple
              aria-label="Etiquetas"
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>
        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
