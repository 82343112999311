import { MenuItem, Stack, TextField } from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { DuplicataFilter1 } from 'hooks/queries/useClientes'

export default function FilterForm() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilter<DuplicataFilter1>()

  return (
    <Stack spacing={2}>
      <fieldset
        style={{
          border: '1px solid #cfcfcf',
          borderRadius: '4px',
        }}
      >
        <legend
          style={{
            color: '#808080',
            fontSize: '0.7rem',
            paddingLeft: '4px',
            paddingRight: '16px',
          }}
        >
          Período
        </legend>

        <Stack
          sx={{
            mt: 1,
            gap: 2,
            borderRadius: '4px',
          }}
        >
          <TextField
            label="Tipo da Data"
            name="tipoData"
            value={filterValues?.tipoData || ''}
            select
            onChange={(e) => {
              const key = e.target.value
              changeFilterValue('tipoData', key)
            }}
          >
            <MenuItem value="vencimento">Vencimento</MenuItem>
            <MenuItem value="pagamento">Pagamento</MenuItem>
            <MenuItem value="emissao">Emissão</MenuItem>
          </TextField>

          <DateRangePicker
            renderFieldSet={false}
            onChange={(value) => {
              changeFilterValue('dateRange', value)
            }}
            value={filterValues?.dateRange}
          />
        </Stack>
      </fieldset>
    </Stack>
  )
}
