import { FileDownload as FileDownloadIcon } from '@mui/icons-material'
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
} from '@mui/x-data-grid'

import { useTableRootContext } from './DataTableRootProvider'
import Button from './DataTableButton'

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridFilteredSortedRowIdsSelector(apiRef)

export default function ExportCsvButton() {
  const { apiRef } = useTableRootContext()

  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options)

  return (
    <Button
      startIcon={<FileDownloadIcon />}
      onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
    >
      Exportar CSV
    </Button>
  )
}
