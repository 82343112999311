import { MenuItem, TextField } from '@mui/material'
import MioFilter from 'components/MioCandidate/Filter'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { FilterForm } from './FilterForm'
import { AtivoEnum } from 'hooks/queries/useFormaPagamento'


export default function Filter() {
  const {applyFilterValues, changeFilterValue } = useFilterApi()
  const { appliedValues, filterValues } = useFilter()

  return (
    <>
      <MioFilter.Root>
      <MioFilter.Container>
      <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Formas de Pagamento"
        />

        <MioFilter.Chips>
            <MioFilter.Chip
              name='codigo'
              label ={appliedValues?.codigo}
            >
                <TextField
                  name="codigo"
                  value={filterValues?.codigo}
                  label="Codigo"
                  onChange={(e) =>{
                    changeFilterValue('codigo', e.target.value)
                  }}
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              name="nome"
              label = {appliedValues?.nome}
            >
              <TextField
                name="nome"
                value={filterValues?.nome}
                label="Nome"
                onChange={(e) =>{
                  changeFilterValue('nome', e.target.value)
                }}
            />
            </MioFilter.Chip>
            <MioFilter.Chip
              name='ativo'
              label={filterValues?.ativo}
            >
              <TextField
                name="ativo"
                onChange={(e) =>{
                  changeFilterValue('ativo', e.target.value)
                }}
                value={filterValues?.ativo}
                label='ativo'
                select

              >
                <MenuItem value={AtivoEnum.TODOS}>Todos</MenuItem>
                <MenuItem value={AtivoEnum.ATIVOS}>Ativos</MenuItem>
                <MenuItem value={AtivoEnum.INATIVOS}>Inativos</MenuItem>
              </TextField>
            </MioFilter.Chip>
          </MioFilter.Chips>
          <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm/>
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
      </MioFilter.Root>
    </>

  )
}
