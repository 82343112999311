import { FilterOptions } from '@data-c/providers'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import CategoriaInterface from 'interfaces/CategoriaInterface'
import ErrorInterface from 'interfaces/ErrorInterface'
import { SortProps } from 'interfaces/SortProps'
import { DateTime } from 'luxon'
import api from 'services/api'
import { ClientesModel } from './useClientes'
import { EtiquetaModel } from './useEtiqueta'
import { TimeVendasModel } from './useTimeVendas'

export interface PositivacaoClientesFilters {
  anoMes: FilterOptions<string>
  categoriaKey?: FilterOptions<
    'nenhuma' | 'classe' | 'grupo' | 'linha' | 'familia'
  >
  situacaoPedido?: FilterOptions<'venda' | 'emissao'>
  categoriaValue?: FilterOptions<CategoriaInterface>
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  categoriaUuid?: FilterOptions<string | null>
  periodoInicial?: FilterOptions<number | null>
  periodoInicialPositivacao?: FilterOptions<number | null>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaModel>>
}

export interface PositivacaoClientesFilters1 {
  dataInicio: string
  dataFim: string
  inicioPeriodo: string
  categoriaKey?: 'nenhuma' | 'classe' | 'grupo' | 'linha' | 'familia'
  situacaoPedido?: 'venda' | 'emissao'
  categoriaValue?: CategoriaInterface
  representante?: TimeVendasModel
  representanteUuid?: string
  categoriaUuid?: string
  periodoInicial?: number
  periodoInicialPositivacao?: number
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaModel> | []
  anoMes: string
}

export interface PositivacaoClientesFilters1WithBounds
  extends PositivacaoClientesFilters1 {
  north: number
  south: number
  east: number
  west: number
}

export interface Data {
  pessoa?: ClientesModel
  uuid?: string
  codigo: string
  nome: string
  totalPeriodo: number
  totalDesdeInicioPeriodo: number
  estaAtivo: boolean
  longitude?: number
  latitude?: number
}

interface DataSintetico {
  codigoVendedor: string
  nomeVendedor: string
  clientesAtivos: number
  clientesInativos: number
  percentualPositivacao: number
}

export interface RelatorioPositivacaoClientesModel {
  data: Array<Data>
  inicioPeriodo: string
  dataInicio: string
  dataFim: string
  clientesAtivos: number
  clientesInativos: number
  percentualPositivacao: number
}

export interface RelatorioPositivacaoClientesSinteticoModel {
  data: Array<DataSintetico>
  inicioPeriodo: string
  dataInicio: string
  dataFim: string
  clientesAtivos: number
  clientesInativos: number
  percentualPositivacao: number
}

export const filters: PositivacaoClientesFilters = {
  anoMes: {
    label: 'Período',
    value: DateTime.now().toFormat('yyyy-MM-dd'),
  },
  situacaoPedido: {
    label: 'Situação',
    value: 'emissao',
  },
  representante: {
    label: 'Vendedor',
    value: null,
  },
  categoriaKey: {
    label: 'Tipo de Categoria',
    value: 'nenhuma',
  },
  categoriaValue: {
    label: 'Categoria',
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  periodoInicial: {
    value: 1,
    label: 'Ativacao',
  },
  periodoInicialPositivacao: {
    value: 1,
    label: 'Positivacao',
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export async function obterRelatorioPositivacaoClientes(
  params: PositivacaoClientesFilters1,
  sort?: SortProps,
): Promise<RelatorioPositivacaoClientesModel> {
  if (!params?.situacaoPedido) return {} as RelatorioPositivacaoClientesModel

  const etiquetas = params?.etiquetaKeyValue
    ? params?.etiquetaKeyValue.map((e) => e.value)
    : null

  const newParams = {
    ...params,
    etiqueta: etiquetas,
  }

  const response = await api.get<RelatorioPositivacaoClientesModel>(
    `relatorio/positivacao-clientes`,
    {
      params: newParams,
      headers: {
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )
  return response.data
}

export function useQueryObterRelatorioPositivacaoClientes(
  params: PositivacaoClientesFilters1,
  searchId?: string,
  sort?: SortProps,
) {
  return useQuery<
    RelatorioPositivacaoClientesModel,
    AxiosError<ErrorInterface>
  >(['RELATORIO_POSITIVACAO_CLIENTES', params, searchId, sort], () => {
    return obterRelatorioPositivacaoClientes(params, sort)
  })
}

export async function obterRelatorioPositivacaoClientesSintetico(
  params: PositivacaoClientesFilters1,
  sort?: SortProps,
): Promise<RelatorioPositivacaoClientesSinteticoModel> {
  if (!params?.situacaoPedido)
    return {} as RelatorioPositivacaoClientesSinteticoModel

  const etiquetas = params?.etiquetaKeyValue
    ? params?.etiquetaKeyValue.map((e) => e.value)
    : null

  const newParams = {
    ...params,
    etiqueta: etiquetas,
  }

  const response = await api.get<RelatorioPositivacaoClientesSinteticoModel>(
    `relatorio/positivacao-clientes-sintetico`,
    {
      params: newParams,
      headers: {
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )
  return response.data
}

export function useQueryObterRelatorioPositivacaoClientesSintetico(
  params: PositivacaoClientesFilters1,
  searchId?: string,
  sort?: SortProps,
) {
  return useQuery<
    RelatorioPositivacaoClientesSinteticoModel,
    AxiosError<ErrorInterface>
  >(
    ['RELATORIO_POSITIVACAO_CLIENTES_SINTETICO', params, searchId, sort],
    () => {
      return obterRelatorioPositivacaoClientesSintetico(params, sort)
    },
  )
}

export default function useRelatorioPositivacaoClientes() {
  return {
    useQueryObterRelatorioPositivacaoClientes,
    useQueryObterRelatorioPositivacaoClientesSintetico,
  }
}
