import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import MixProdutosTransporter from 'components/Transporters/MixProdutosTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { useEffect, useMemo } from 'react'

export default function FilterForm() {
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilter()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <Stack spacing={1.5}>
      <fieldset
        style={{
          border: '1px solid #cfcfcf',
          borderRadius: '4px',
        }}
      >
        <legend
          style={{
            color: '#808080',
            fontSize: '0.7rem',
            paddingLeft: '4px',
            paddingRight: '16px',
          }}
        >
          Período
        </legend>

        <Stack
          sx={{
            gap: 2,
            borderRadius: '4px',
          }}
        >
          <DateRangePicker
            renderFieldSet={false}
            onChange={(value) => {
              changeFilterValue('dateRange', value)
            }}
            value={filterValues?.dateRange}
          />
        </Stack>
      </fieldset>
      <TextField
        select
        label="Situação do Pedido"
        value={filterValues?.situacao || ''}
        onChange={onChangeFilterValue}
        name="situacao"
      >
        <MenuItem value="emissao">Faturados</MenuItem>
        <MenuItem value="venda">Em processamento</MenuItem>
      </TextField>
      <TextField
        select
        label="Calcular por"
        value={filterValues?.tipoDeCalculo || ''}
        name="tipoDeCalculo"
        onChange={onChangeFilterValue}
      >
        <MenuItem value="totalliquido">Valor Total de Venda</MenuItem>
        <MenuItem value="pesoliquido">Peso Total de Venda</MenuItem>
      </TextField>
      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="representanteUuid"
        label="Representante"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        InputLabelProps={{ shrink: true }}
      />
      <MixProdutosTransporter
        name="mix1Uuid"
        label="Mix de Produtos 1"
        value={filterValues?.mix1 || null}
        onChange={(mix1) => {
          changeFilterValue('mix1', mix1)
        }}
        InputLabelProps={{ shrink: true }}
      />
      <MixProdutosTransporter
        name="mix2Uuid"
        label="Mix de Produtos 2"
        value={filterValues?.mix2 || null}
        onChange={(mix2) => {
          changeFilterValue('mix2', mix2)
        }}
        InputLabelProps={{ shrink: true }}
      />
      <Autocomplete
        multiple
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
    // </FilterContainer>
  )
}
