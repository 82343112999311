import { useMemo, useCallback } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { TransportableDataTableProps, usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useFamiliaProdutos, {
  FamiliaProdutosModel,
} from 'hooks/queries/useFamiliaProdutos'

export default function Table(
  props: TransportableDataTableProps<FamiliaProdutosModel>,
) {
  const { query, onTransport, enableTransporter } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useFamiliaProdutos()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination,
  })
  const { openForm, openConfirm } = useFormApi()

  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: FamiliaProdutosModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: FamiliaProdutosModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm, onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, enableTransporter],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
