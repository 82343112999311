import { FilterOptions } from '@data-c/providers'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { ClientesModel } from './useClientes'
import { TimeVendasModel } from './useTimeVendas'
import { DateTime } from 'luxon'
import { useQuery } from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'
import api from 'services/api'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import { PedidoModel } from './usePedidos'
import { HttpResponseInterface, SortProps } from '@data-c/hooks'
import { EtiquetaModel } from './useEtiqueta'

export interface OportunidadesPerdidasFilter {
  dataInicial: FilterOptions<string>
  dataFinal: FilterOptions<string>
  cliente: FilterOptions<ClientesModel>
  clienteUuid: FilterOptions<string>
  representanteUuid: FilterOptions<string>
  representante: FilterOptions<TimeVendasModel>
  dateRange?: FilterOptions<DateRange>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaModel>>
}

export interface OportunidadesPerdidasFilter1 {
  plainQuery?: string | null
  dataInicial?: string | null
  dataFinal?: string | null
  dateRange?: DateRange
  cliente?: ClientesModel
  clienteUuid?: string
  representante?: TimeVendasModel
  representanteUuid?: string
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaModel> | []
}

export const filters: OportunidadesPerdidasFilter = {
  dataInicial: {
    label: 'Data Inicial',
  },
  dataFinal: {
    label: 'Data Final',
  },
  cliente: {
    label: 'Cliente',
  },
  clienteUuid: {
    label: 'Cliente',
  },
  representante: {
    label: 'Vendedor',
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export interface OportunidadePerdidaPorMotivoDataResponse {
  total: number
  descricao: string
  totalPerda: number
  motivoPerdaUuid: string
}

export interface OportunidadePerdidaPorPedidoDataResponse {}

/*
------- MOTIVO
*/
export async function relatorioOportunidadesPerdidasPorMotivo(
  params: HttpRequestInterface<OportunidadesPerdidasFilter1>,
) {
  const { queryParams } = params
  const etiquetas = queryParams?.etiquetaKeyValue
    ? queryParams?.etiquetaKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    etiqueta: etiquetas,
  }
  const response = await api.get<
    Array<OportunidadePerdidaPorMotivoDataResponse>
  >(`/relatorio/oportunidade-perdida-motivo`, {
    params: nQueryParams,
  })
  return response.data
}

export function useQueryRelatorioOportunidadesPerdidasPorMotivo(
  params: HttpRequestInterface<OportunidadesPerdidasFilter1>,
) {
  return useQuery<
    Array<OportunidadePerdidaPorMotivoDataResponse>,
    AxiosError<ErrorInterface>
  >(['RELATORIO_OPORTUNIDADES_PERDIDAS_MOTIVO', params], () => {
    return relatorioOportunidadesPerdidasPorMotivo(params)
  })
}

/*
------- PEDIDO
*/
export async function relatorioOportunidadesPerdidasPorPedido(
  params: HttpRequestInterface<OportunidadesPerdidasFilter1>,
  sort?: SortProps,
) {
  const { queryParams, pagination: _pagination } = params
  const etiquetas = queryParams?.etiquetaKeyValue
    ? queryParams?.etiquetaKeyValue.map((e) => e.value)
    : null
  const nQueryParams = {
    ...queryParams,
    etiqueta: etiquetas,
  }
  const response = await api.get<HttpResponseInterface<PedidoModel>>(
    `/relatorio/oportunidade-perdida-pedido`,
    {
      params: nQueryParams,
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )
  return response.data
}

export function useQueryRelatorioOportunidadesPerdidasPorPedido(
  params: HttpRequestInterface<OportunidadesPerdidasFilter1>,
  sort?: SortProps,
) {
  return useQuery<
    HttpResponseInterface<PedidoModel>,
    AxiosError<ErrorInterface>
  >(['RELATORIO_OPORTUNIDADES_PERDIDAS_PEDIDO', params, sort], () => {
    return relatorioOportunidadesPerdidasPorPedido(params, sort)
  })
}

export function useRelatorioOportunidadesPerdidas() {
  return {
    useQueryRelatorioOportunidadesPerdidasPorMotivo,
    useQueryRelatorioOportunidadesPerdidasPorPedido,
  }
}
