import { Stack, StackProps, useTheme } from '@mui/material'

export default function DialogHeader(props: StackProps) {
  const theme = useTheme()

  return (
    <Stack
      component="header"
      height="44px"
      position="absolute"
      direction="row"
      top={0}
      left={0}
      right={0}
      display="flex"
      alignItems="center"
      padding="16px"
      borderBottom={`solid 1px ${theme.palette.grey[300]}`}
      {...props}
    />
  )
}
