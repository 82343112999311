import { FormProvider } from '@data-c/providers'

import { Container } from '@mui/material'
import Delete from 'components/Delete'
import useGrupoProdutos from 'hooks/queries/useGrupoProdutos'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Form from './components/Form'
import Table from './components/Table'
import Assistant from 'components/MioCandidate/Assistant'
import Content from 'components/Layouts/Content'

export default function GrupoProdutos() {
  const { useDelete } = useGrupoProdutos()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="27025236952340" />
      </Assistant.Root>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Grupo de Produtos',
                link: 'grupo-produtos',
              },
            ]}
          />
          <Form />
        </ContentTitle.Root>
        <Content sx={{ margin: 1 }}>
          <Table />
          <Delete onDelete={mutateAsync} />
        </Content>
      </FormProvider>
    </Container>
  )
}
