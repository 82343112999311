import { Container } from '@mui/material'

import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import { useFilterValues } from 'hooks/queries/useRelatorioTaxaConversaoNegociacao'
import Chart from './componentes/Chart'
import Filter from './componentes/Filter'

export default function TaxaConversaoNegocio() {
  const filter = useFilterValues()
  return (
    <Container>
      <MioFilter.Provider
        filterValues={{
          dateRange: filter.dateRange?.value,
          funilVenda: filter.funilVenda.value,
        }}
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Taxa Conversão Negócio',
                link: '/taxa-conversao-negocio',
              },
            ]}
          ></Breadcrumbs>
          <Filter />
        </ContentTitle.Root>
        <Content>
          <Chart />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
