import { FormProvider } from '@data-c/providers'
import useClientes from 'hooks/queries/useClientes'

import { Button, Container } from '@mui/material'
// import novoIcon from 'assets/images/novo.svg'
import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
import Delete from 'components/Delete'
import { useUserContext } from 'components/Contexts/UserContext'
import Content from 'components/Layouts/Content'
import { useNavigate } from 'react-router-dom'
import MioFilter from 'components/MioCandidate/Filter'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function Clientes() {
  const { temPermissao } = useUserContext()
  const navigate = useNavigate()
  const podeCriar = temPermissao('clientes.create') != 'sem_acesso'
  const { useDelete } = useClientes()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <FormProvider
        initialFormValues={{
          ativo: true,
          ie: 'ISENTO',
        }}
      >
        <MioFilter.Provider filterValues={{}}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Clientes',
                  link: '/clientes/lista',
                },
              ]}
            />

            <Filter />
            {podeCriar && (
              <>
                <Button onClick={() => navigate('/importar')}>Importar</Button>
                <Form />
              </>
            )}
          </ContentTitle.Root>
          <Content>
            <Table />
            <Delete onDelete={mutateAsync} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
