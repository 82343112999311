import { useMemo } from 'react'
import {
  TextField,
  Pagination as MuiPagination,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import {
  PaginationProps as MioPaginationProps,
  pluralizar,
} from '@data-c/hooks'

interface PaginationProps {
  pagination?: MioPaginationProps
  rowsPerPageOptions?: number[]
  onChangePage?: (currentPage: number) => void
  onChangePageSize?: (numberOfRows: number) => void
}

export default function Pagination(props: PaginationProps) {
  const {
    onChangePageSize,
    onChangePage,
    pagination,
    rowsPerPageOptions = [15, 50, 100, 200],
  } = props
  const { page, pageSize = 15, totalRecords } = pagination || {}

  const pages = useMemo(() => {
    if (pagination?.lastPage) return pagination.lastPage

    if ((totalRecords || 0) < pageSize) return 1
    return Math.max(Math.ceil((totalRecords || 0) / pageSize))
  }, [totalRecords, pageSize])

  const totalRecordsLabel = useMemo(() => {
    if ((totalRecords || 0) < pageSize)
      return `Exibindo ${pluralizar(
        totalRecords || 0,
        'registro',
        'registros',
        true,
      )}`

    let registrosExibidos = pageSize
    if (page === pages) {
      const ultimaPaginaRegistros = (totalRecords || 0) % pageSize
      registrosExibidos =
        ultimaPaginaRegistros === 0 ? pageSize : ultimaPaginaRegistros
    }

    return `Exibindo ${pluralizar(
      registrosExibidos || 0,
      'registro',
      'registros',
      true,
    )} de ${totalRecords}`
  }, [pagination, pages])

  // const showPagination = Boolean((totalRecords || 0) > pageSize)
  const showPagination = true

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      gap={2}
      marginLeft="auto"
    >
      <Typography
        variant="body2"
        fontSize="13px"
        fontWeight="500"
        whiteSpace="nowrap"
      >
        {totalRecordsLabel}
      </Typography>

      {showPagination && (
        <Stack direction="row" alignItems="center" gap={2} marginLeft="auto">
          <TextField
            sx={{
              width: '78px',
              fontSize: '8pt',
            }}
            size="small"
            value={pageSize || 0}
            select
            onChange={(e) => {
              if (onChangePageSize) {
                onChangePageSize(parseInt(e.target.value))
              }
            }}
          >
            {rowsPerPageOptions.map((option: number) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <MuiPagination
            variant="outlined"
            shape="rounded"
            count={pages || 0}
            showFirstButton
            showLastButton
            onChange={(_, _page) => {
              if (onChangePage) onChangePage(_page)
            }}
            page={page || 0}
            color="primary"
          />
        </Stack>
      )}
    </Stack>
  )
}
