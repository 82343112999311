import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import Delete from 'components/Delete'
import useClasseProdutos from 'hooks/queries/useClasseProdutos'
import Form from './components/Form'
import Table from './components/Table'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Assistant from 'components/MioCandidate/Assistant'
import AssistantArticle from 'components/MioCandidate/Assistant/AssistantArticle'
import Content from 'components/Layouts/Content'
import Dialog from 'components/MioCandidate/Dialog'

export default function ClasseProdutos() {
  const { useDelete } = useClasseProdutos()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <Dialog.Provider>
        <Assistant.Root>
          <AssistantArticle articleId="27025236952340" open={true} />
        </Assistant.Root>
        <FormProvider initialFormValues={{ ativo: true }}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  link: 'grupo-produtos',
                  label: 'Grupo de Produtos',
                },
              ]}
            />
            <Dialog.AdicionarButton
              dialogComponent={Form}
              payload={{ ativo: true }}
            />
          </ContentTitle.Root>
          <Content>
            <Table />
            <Delete onDelete={handleDelete} />
          </Content>
        </FormProvider>
      </Dialog.Provider>
    </Container>
  )
}
