import { plurilize } from '@data-c/hooks'
import { Circle } from '@mui/icons-material'
import { LinearProgress, Stack, Typography, useTheme } from '@mui/material'
import EmptyData from 'components/Displays/EmptyData'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import Surface from 'components/Surface'
import useRelatorioTaxaConversaoNegociacao, {
  TaxaConversaoNegociacaoModelFilters1,
  TaxaConversaoNegociacaoModelModel,
} from 'hooks/queries/useRelatorioTaxaConversaoNegociacao'
import useCalculos from 'hooks/useCalculos'
import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

interface CustomizedTooltip extends TooltipProps<any, any> {}

function TooltipWraper(props: CustomizedTooltip) {
  // const { active, payload, label } = props
  if (props.active && props.payload && props.payload.length) {
    const { payload: values } = props.payload[0]
    const T = values.customTooltip
    return <T {...props} />
  }
  return null
}

function CustomizedTooltip(props: CustomizedTooltip) {
  const { active, payload, label } = props
  const theme = useTheme()
  if (active && payload && payload.length) {
    const { payload: values } = payload[0]
    return (
      <Surface elevation={1}>
        <div className="custom-tooltip">
          <Stack spacing={1}>
            <Typography fontWeight="bold" variant="subtitle1">
              {label}
            </Typography>
            <Stack
              sx={{ mt: 2 }}
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Circle
                sx={{ fontSize: '10pt', color: theme.palette.primary.main }}
              />
              <Typography variant="subtitle2">
                Chegou à etapa: {values.totalAcumuladoNaEtapa}{' '}
                {plurilize('negócio', values.totalAcumuladoNaEtapa, false)}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Circle sx={{ fontSize: '10pt', color: '#646768FF' }} />
              <Typography variant="subtitle2">
                Conversão para a próxima etapa:{' '}
                {values.taxaConversao.toFixed(0)}%{' '}
                {`(${values.totalNegociacoesProximaEtapa} negócios) `}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </Surface>
    )
  }

  return null
}

function CustomizedTooltip1(props: CustomizedTooltip) {
  const { active, payload, label } = props
  const theme = useTheme()
  if (active && payload && payload.length) {
    const { payload: values } = payload[0]
    return (
      <Surface elevation={1}>
        <div className="custom-tooltip">
          <Stack spacing={1}>
            <Typography fontWeight="bold" variant="subtitle1">
              {label}
            </Typography>
            <Stack
              sx={{ mt: 2 }}
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Circle
                sx={{ fontSize: '10pt', color: theme.palette.primary.main }}
              />
              <Typography variant="subtitle2">
                Chegou à etapa: {values.totalAcumuladoNaEtapa}{' '}
                {plurilize('negócio', values.totalNegociacoes, false)}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Circle sx={{ fontSize: '10pt', color: '#646768FF' }} />
              <Typography variant="subtitle2">
                Ganho na etapa:
                {` ${values.taxaConversao.toFixed(0)}%`}
                {` (${values.totalNegociacoesProximaEtapa} negócios) `}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </Surface>
    )
  }
  return null
}

function CustomizedTooltip2(props: CustomizedTooltip) {
  const { active, payload, label } = props
  const theme = useTheme()
  if (active && payload && payload.length) {
    const { payload: values } = payload[0]
    return (
      <Surface elevation={1}>
        <div className="custom-tooltip">
          <Stack spacing={1}>
            <Typography fontWeight="bold" variant="subtitle1">
              {label}
            </Typography>
            <Stack
              sx={{ mt: 2 }}
              spacing={1}
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Circle
                sx={{ fontSize: '10pt', color: theme.palette.success.main }}
              />
              <Typography variant="subtitle2">
                Ganho: {values.negociacoesGanhas}{' '}
                {values.taxaConversao.toFixed(0)}%
                {plurilize('negócio', values.negociacoesGanhas, false)}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </Surface>
    )
  }
  return null
}

export default function Chart() {
  const { appliedValues, searchId } =
    useFilter<TaxaConversaoNegociacaoModelFilters1>()
  const theme = useTheme()
  const { calcularPorcentagem } = useCalculos()
  const { representante, funilVenda, dateRange, ...rest } = appliedValues || {}
  const nFilters: TaxaConversaoNegociacaoModelFilters1 = {
    ...rest,
    funilUuid: funilVenda?.uuid,
    representanteUuid: representante?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { useQueryObterTaxaConversaoNegociacaoModel } =
    useRelatorioTaxaConversaoNegociacao()
  const { data, isLoading, error } = useQueryObterTaxaConversaoNegociacaoModel(
    nFilters,
    searchId,
  )

  if (isLoading) return <LinearProgress />

  if (Array.isArray(data) && data.length === 0)
    return (
      <EmptyData
        error={
          Boolean(!isLoading && !error)
            ? 'Não conseguimos gerar o relatório. Por favor, ajuste os filtros de busca e tente novamente.'
            : undefined
        }
      />
    )

  const tempData: Array<TaxaConversaoNegociacaoModelModel> =
    JSON.parse(JSON.stringify(data)) || []

  const totalGanhas = tempData[tempData.length - 1].totalGanhoNaEtapa
  const taxaGanho = calcularPorcentagem(
    totalGanhas,
    data![0].totalAcumuladoNaEtapa,
  )

  const quantidadeDeNegociosNecessarios =
    taxaGanho > 0 ? 1 / (taxaGanho / 100) : 0

  const nData =
    tempData?.map((negociacao, index) => ({
      ...negociacao,
      totalNegociacoesProximaEtapa:
        index < tempData.length - 1
          ? tempData[index + 1].totalAcumuladoNaEtapa
          : 0,

      customTooltip:
        index < tempData.length - 2
          ? CustomizedTooltip
          : index === tempData.length - 1
          ? CustomizedTooltip2
          : CustomizedTooltip1,
    })) || []

  const CustomBar: React.FC<any> = (props) => {
    const { fill, x, y, width, height, payload, index } = props
    const { taxaConversao } = payload
    const barWidth = width - width / 2
    const middleOfBarWidth = barWidth - barWidth / 2
    const xPath = x + barWidth + middleOfBarWidth
    const yPath = 300
    return (
      <svg>
        <g>
          <text
            x={x + middleOfBarWidth}
            y={y - 10}
            fill={theme.palette.text.primary}
            font-size="14"
            fontWeight="bold"
            text-anchor="middle"
            dominant-baseline="middle"
          >
            {payload.totalAcumuladoNaEtapa}
          </text>
          <rect
            x={x}
            y={y}
            width={barWidth}
            height={height}
            fill={index + 1 < nData.length ? fill : theme.palette.success.main}
            radius="5"
          />
          {index + 1 < nData.length && (
            <React.Fragment>
              <path
                d={`M${xPath},${yPath}
                L${xPath + 32} ${yPath}
                L${xPath + 44} 
                ${yPath + 12}
                L${xPath + 32} ${yPath + 24}
                L${xPath} ${yPath + 24}
                Z`}
                stroke="none"
                fill="#646768FF"
              ></path>
              <text
                x={xPath + 18}
                y={yPath + 13}
                fill="#fff"
                font-size="12"
                fontWeight="bold"
                text-anchor="middle"
                dominant-baseline="middle"
              >
                {taxaConversao.toFixed(0)}%
              </text>
            </React.Fragment>
          )}
        </g>
      </svg>
    )
  }

  return (
    <Surface sx={{ pb: 6, pl: 2, pr: 2, pt: 2, height: '500px' }}>
      <Stack direction="column">
        <Typography variant="subtitle1" fontWeight="bold">
          Taxa de Ganho: {taxaGanho.toFixed(0)}%
        </Typography>
        <Typography variant="body2">
          {quantidadeDeNegociosNecessarios > 0
            ? `Para ganhar um negócio,
          ${plurilize('negócio', quantidadeDeNegociosNecessarios, true)} devem
          ser adicionados em média.`
            : 'Comece adicionar negocioações ao funil para ter análise'}
        </Typography>
      </Stack>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={nData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="etapaNome" tickLine={false} />
          <YAxis
            label={{
              value: `Total de Negócios`,
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: 0,
            }}
          />
          <Tooltip content={<TooltipWraper />} />
          {/* <Legend /> */}
          <Bar
            dataKey="totalAcumuladoNaEtapa"
            fill={theme.palette.primary.main}
            shape={<CustomBar />}
          />
        </BarChart>
      </ResponsiveContainer>
    </Surface>
  )
}
