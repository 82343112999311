import { MouseEvent, useState } from 'react'

import { usePagination } from '@data-c/hooks'
import { FormProvider } from '@data-c/providers'
import { Button, ButtonContainer } from '@data-c/ui'

import Delete from 'components/Delete'
import DragAndDrop from 'pages/CondicaoPagamento/components/DragAndDrop'

import { Container, Popover, Typography } from '@mui/material'
import useCondicaoPagamento, {
  CondicaoPagamentoModel,
} from 'hooks/queries/useCondicaoPagamento'

import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
import Assistant from 'components/MioCandidate/Assistant'

export default function CondicaoPagamento() {
  const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(null)

  const { pagination } = usePagination()
  const { useDelete, useUpdateOrder, useQuery } = useCondicaoPagamento()
  const { mutateAsync: updateOrder, isLoading } = useUpdateOrder()
  const { mutateAsync: handleDelete } = useDelete()
  const { data } = useQuery({ pagination })

  function handleShowPopover(event: MouseEvent<HTMLButtonElement>) {
    setOpenPopover(event.currentTarget)
  }

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="27684312994452" />
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <MioFilter.Provider filterValues={{}}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Condição de Pagamento',
                  link: '/condicao-pagamento',
                },
              ]}
            />
            <Filter />

            <ButtonContainer>
              {data?.data && (
                <Button size="small" onClick={handleShowPopover}>
                  Ordenar Exibição
                </Button>
              )}
              <Form />
            </ButtonContainer>
          </ContentTitle.Root>
          <Content>
            <Table />
          </Content>

          <Popover
            open={!!openPopover}
            anchorEl={openPopover}
            onClose={() => setOpenPopover(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DragAndDrop
              data={data?.data || []}
              isLoading={isLoading}
              onChange={(_newItemsOrder) => {
                updateOrder(_newItemsOrder)
              }}
              renderItem={(data: CondicaoPagamentoModel) => (
                <Typography>{data?.nome}</Typography>
              )}
            />
          </Popover>
          <Delete onDelete={handleDelete} />
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
