import { useCallback, useEffect, useMemo, useState } from 'react'

import { Stack } from '@mui/material'

import { formatCurrency, useDialog } from '@data-c/hooks'

import { useUserContext } from 'components/Contexts/UserContext'
import ConfirmDialog from 'components/Displays/ConfirmDialog'
import MobileOrDesktopRenderer from 'components/MobileOrDesktopRenderer'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import {
  ItemPedidoModel,
  ItensPedidoSelecionados,
  PedidoModel,
  SituacaoPedidoEnum,
  useDeleteItemPedido,
  useUpdateBloqueioItemPedido,
} from 'hooks/queries/usePedidos'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import AdicionarItem from '../AdicionarItem'
import Notifications from '../Notifications'
import MobileView from './components/MobileView'
import DesktopView from './components/DesktopView'

export interface ItensPedidoProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  isLoading?: boolean
  isFetching?: boolean
  configuracoes?: ConfiguracoesModel
}

export default function ItensPedido(props: ItensPedidoProps) {
  const { pedido, usuarioLogado, configuracoes } = props
  const [value, setValue] = useState<ItemPedidoModel | null>(null)
  const { temPermissao } = useUserContext()
  const [itensPedidoSelecionados, setItensPedidoSelecionados] = useState<
    ItensPedidoSelecionados[]
  >([])

  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'
  const { mutateAsync } = useUpdateBloqueioItemPedido()
  const {
    data: dataToDelete,
    isOpen: isOpenDeleteDialog,
    openDialog: openConfirmDelete,
    closeDialog: closeConfirmDeleteDialog,
  } = useDialog<ItemPedidoModel>()
  const { mutateAsync: deleteItem, isLoading: isDeleting } =
    useDeleteItemPedido()
  const calulcaImposto =
    pedido.ajuste === 100 && configuracoes?.usa_calculo_imposto

  const podeEditar = useMemo(() => {
    const situacao = pedido.situacao

    return (
      (situacao === SituacaoPedidoEnum.WEB ||
        situacao === SituacaoPedidoEnum.MOBILE ||
        situacao === SituacaoPedidoEnum.COTACAO_SOLICITACAO ||
        situacao === SituacaoPedidoEnum.COTACAO_ANALISE ||
        situacao === SituacaoPedidoEnum.COTACAO_APROVADA) &&
      podeEditarPedido
    )
  }, [pedido, podeEditarPedido])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'perder_item', data: ItemPedidoModel) => {
      switch (event) {
        case 'edit':
          setValue(data)
          break
        case 'delete':
          openConfirmDelete(data)
          break
        case 'perder_item':
          // openDialog(data)
          break
      }
    },
    [],
  )

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      await updateBloqueioItemPedido()
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [itensPedidoSelecionados])

  async function updateBloqueioItemPedido() {
    const possuiItensPedido = itensPedidoSelecionados.length > 0

    if (possuiItensPedido) {
      await mutateAsync({
        itensPedidoSelecionados,
      })

      setItensPedidoSelecionados([])
    }
  }

  async function handleDelete() {
    if (dataToDelete) {
      await deleteItem(dataToDelete)
      closeConfirmDeleteDialog()
    }
  }

  const notifications = useMemo(() => {
    const toReturn: Array<string> = []

    const temDescontoAgregado =
      Array.isArray(pedido?.tabelaPreco?.descontoAgregado) &&
      pedido?.tabelaPreco.descontoAgregado.length > 0

    if (temDescontoAgregado) {
      pedido?.tabelaPreco?.descontoAgregado?.forEach((d) => {
        const value =
          d.pesoValor === 1
            ? formatCurrency(d.pesoValorMinimo)
            : `${d.pesoValorMinimo} Kg`

        const msg = `${d.pdesconto}% de desconto acima de ${value}`
        toReturn.push(msg)
      })
    }

    return toReturn
  }, [pedido])

  return (
    <Stack spacing={1}>
      {podeEditar && (
        <Stack direction="row" spacing={1}>
          {notifications.length > 0 && (
            <Notifications notifications={notifications} />
          )}

          <AdicionarItem
            calculaImposto={calulcaImposto}
            tabelaPrecoUuid={pedido?.tabelaPrecoUuid || ''}
            pedido={pedido || ({} as PedidoModel)}
            value={value}
            onReset={() => {
              setValue(null)
            }}
          />
        </Stack>
      )}
      <MobileOrDesktopRenderer
        mobileChildren={
          <MobileView
            pedido={pedido}
            usuarioLogado={usuarioLogado}
            podeEditar={podeEditar}
            onClickItem={handleClickItem}
            configuracoes={configuracoes}
          />
        }
        desktopChildren={
          <DesktopView
            pedido={pedido}
            usuarioLogado={usuarioLogado}
            podeEditar={podeEditar}
            onClickItem={handleClickItem}
            configuracoes={configuracoes}
          />
        }
      />

      {/* <PerderItemForm
        isOpen={isOpen}
        closeDialog={closeDialog}
        item={itemPerdidoSelecionado}
      /> */}

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        isLoading={Boolean(isDeleting)}
        close={closeConfirmDeleteDialog}
        onConfirm={handleDelete}
        // message="Você quer remover essa foto?"
        confirmLabel="Sim"
      />
    </Stack>
  )
}
