import { ReactNode, useEffect } from 'react'
import { Breakpoint, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useTableRootContext } from './DataTableRootProvider'

interface ActionsProps {
  breakpoints?: Breakpoint | number
  children: ReactNode
}

export default function Actions({ children, breakpoints }: ActionsProps) {
  const { setBreakpoint } = useTableRootContext()

  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down(breakpoints || 'md'))

  useEffect(() => {
    setBreakpoint(breakpoint)
  }, [breakpoint])

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      position="relative"
      overflow="hidden"
      gap={1}
      marginLeft="auto"
    >
      {children}
    </Stack>
  )
}
