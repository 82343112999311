import { forwardRef, ReactElement, ReactNode } from 'react'

import { Dialog, Slide, Stack, DialogProps } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

export interface RootProps extends Omit<DialogProps, 'onClose'> {
  height?: number | string
  children: ReactNode
  onClose: (result: any) => Promise<void>
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogRoot({
  children,
  height,
  fullWidth = true,
  ...rest
}: RootProps) {
  return (
    <Dialog
      disableRestoreFocus
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: '#6464648F',
        },
        ...rest?.sx,
      }}
      PaperProps={{
        style: {
          height,
          overflow: 'hidden',
          position: 'absolute',
          top: '51px',
          margin: 0,
        },
      }}
      {...rest}
    >
      <Stack height="100%" position="relative" overflow="auto">
        {children}
      </Stack>
    </Dialog>
  )
}
