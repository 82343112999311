import { useCallback, useEffect, useMemo } from 'react'
import {
  Grid2 as Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Stack,
} from '@mui/material'

import {
  PedidoModel,
  SituacaoPedidoEnum,
  ajustesMap,
  obterCorDaSituacao,
  useSyncEtiquetas,
  useUpdatePedido,
  useUpdateDesconto,
} from 'hooks/queries/usePedidos'
import useForm from 'hooks/useForm'

import AutoSaverTextField from 'components/AutoSaverTextField'
import TabelaPrecoTransporter from 'components/Transporters/TabelaPrecoTransporter'
import CondicaoPagamentoTransporter from 'components/Transporters/CondicaoPagamentoTransporter'
import FormaPagamentoTransporter from 'components/Transporters/FormaPagamentoTransporter'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import FeedbackRequestStatus from 'components/FeedbackRequestStatus'
import DatePicker from 'components/DatePicker'
import { useUserContext } from 'components/Contexts/UserContext'

import Divider from 'components/Divider'
import { formatCurrency, onlyNumbers } from '@data-c/hooks'
import AutoCompleteEtiquetas from 'components/AutocompleteEtiquetas'
import Surface from 'components/Surface'
import { Circle } from '@mui/icons-material'
import Options from '../Options'
import IndicadorLimiteCredito from '../Form/components/IndicadorLimiteCredito'
import { IndicadoresLimiteCreditoModel } from 'hooks/queries/useClientes'
import DropDownDescontos from 'components/Inputs/DropDownDescontos'
import { DescontoModel } from 'hooks/queries/useDesconto'
import { useMobileComponents } from 'hooks/useMobileComponents'

export interface HeaderPedidoProps {
  pedido: PedidoModel
  configuracoes?: ConfiguracoesModel
}

export function HeaderPedido(props: HeaderPedidoProps) {
  const { pedido, configuracoes } = props
  const { user, temPermissao } = useUserContext()
  const { data, handleChange, setData, changeValue } = useForm<PedidoModel>(
    {} as PedidoModel,
  )
  const { mutateAsync: syncEtiquetas } = useSyncEtiquetas()
  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'
  const { isMobile } = useMobileComponents()
  const {
    mutateAsync: updatePedido,
    isLoading,
    error,
    isSuccess,
  } = useUpdatePedido()

  const {
    mutateAsync: updateDesconto,
    isLoading: isLoadingUpdateDesconto,
    isSuccess: isSuccessUpdateDesconto,
    error: errorUpdateDesconto,
  } = useUpdateDesconto()

  const situacaoColor = obterCorDaSituacao(data.situacao as SituacaoPedidoEnum)

  useEffect(() => {
    setData(pedido)
  }, [pedido, setData])

  const salvarPedido = useCallback(
    async (_data: PedidoModel) => {
      await updatePedido(_data)
    },
    [updatePedido],
  )

  const salvarDesconto = useCallback(
    async (desconto?: DescontoModel | null) => {
      await updateDesconto({
        pedidoUuid: pedido?.uuid || '',
        desconto: desconto || null,
      })
    },
    [updatePedido, pedido?.uuid],
  )

  const podeEditar = useMemo(() => {
    const naoPodeEditar =
      data.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA ||
      data.situacao === SituacaoPedidoEnum.FECHADO ||
      data.situacao === SituacaoPedidoEnum.LIBERADO ||
      data.situacao === SituacaoPedidoEnum.ANALISE ||
      data.situacao === SituacaoPedidoEnum.CANCELADO

    if (naoPodeEditar) return false

    if (!podeEditarPedido) return false

    return true
  }, [data, podeEditarPedido])

  const podeExibirDescontoProgressivo = useMemo(() => {
    return (
      data.situacao === SituacaoPedidoEnum.MOBILE ||
      data.situacao === SituacaoPedidoEnum.WEB
    )
  }, [data])

  const podeEditarValidadeDaCotacao = user?.isadmin || false
  const usaAjuste = configuracoes?.usa_ajuste || false

  const descontoAgregadoDesbloqueado = useMemo(() => {
    const descontosAgregados = pedido.tabelaPreco?.descontoAgregado || []
    return (
      [...descontosAgregados].reverse().find((desconto) => {
        const valor =
          (desconto?.tipoPesoValor || 1) === 1
            ? pedido.totalLiquido
            : pedido.pesoTotal || 0
        return valor >= desconto.pesoValorMinimo
      }) || null
    )
  }, [pedido])

  const totalDescontoAgregado = useMemo(() => {
    const pDesconto = descontoAgregadoDesbloqueado?.pdesconto || 0

    let auxValorDesconto = (pedido.totalLiquido * pDesconto) / 100
    return pedido.totalLiquido - auxValorDesconto
  }, [descontoAgregadoDesbloqueado, pedido])

  // *** Calculo limite crédito
  const indicadoresLimiteCredito =
    pedido.cliente?.indicadoresLimiteCredito ||
    ({
      limiteDeCredito: 0,
      valorDuplicatasEmAberto: 0,
      valorPedidosFechadosEAnalise: 0,
    } as IndicadoresLimiteCreditoModel)

  const totalPedidoAtual =
    parseInt(onlyNumbers(pedido?.condicaoPagamento?.condpgtot || '0') || '0') >
    0
      ? pedido.totalLiquido
      : 0

  const limiteRestante =
    indicadoresLimiteCredito.limiteDeCredito -
    (indicadoresLimiteCredito.valorDuplicatasEmAberto +
      indicadoresLimiteCredito.valorPedidosFechadosEAnalise +
      totalPedidoAtual)
  // *** Calculo limite crédito8
  const fatorAltura = isMobile ? 168 : 100

  return (
    <Surface
      elevation={0}
      sx={{
        mr: 1,
        pr: 1,
        pl: 1,
        borderLeft: `5px solid ${situacaoColor}`,
        borderRight: (theme) => `1px solid ${theme.palette.border}`,
        position: 'relative',
      }}
    >
      <Stack
        spacing={1.5}
        sx={{
          height: `calc(100vh - ${fatorAltura}px)`,
          overflowY: 'auto',
          pb: 4,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Circle
              sx={{
                fontSize: '10pt',
                color: situacaoColor,
                border: (theme) => `1px solid ${theme.palette.border}`,
                borderRadius: '90px',
              }}
            />
            <Typography variant="body1">{pedido.situacaoAsText}</Typography>
          </Stack>

          <Options
            pedido={pedido}
            descontoAgregadoDesbloqueado={descontoAgregadoDesbloqueado}
          />
        </Stack>

        <Divider />

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="body1">{`#${pedido.codigo}`}</Typography>
          <Typography variant="body1">{pedido.vendaAsBrazilianDate}</Typography>
        </Stack>

        <Divider />
        <Stack>
          <Typography variant="body1">
            {`${
              pedido?.cliente?.codigo ? `#${pedido?.cliente?.codigo}` : ''
            }  ${pedido?.cliente?.nome || pedido?.cliente?.usual}`}
          </Typography>
          {pedido?.cliente?.cidadeModel && (
            <Typography variant="body2">
              {`${pedido?.cliente?.cidadeModel?.nome || ''} - ${
                pedido?.cliente?.cidadeModel?.uf || ''
              }`}{' '}
            </Typography>
          )}
        </Stack>
        <Divider />

        <TabelaPrecoTransporter
          className="passo1"
          value={data?.tabelaPreco || null}
          clienteUuid={pedido.pessoa}
          vendedorUuid={pedido.vendedor}
          onChange={(value) => {
            setData({
              ...data,
              tabelaPreco: value,
              tabelaPrecoUuid: value?.uuid,
            })
            salvarPedido({
              ...data,
              tabelaPreco: value,
              tabelaPrecoUuid: value?.uuid,
            })
          }}
          isLoading={isLoading}
          error={Boolean(error?.message)}
          helperText={
            Boolean(error?.message) &&
            'Não foi possível salvar a tabela de preço mas você pode tentar novamente.'
          }
          disabled={!podeEditar}
        />

        <CondicaoPagamentoTransporter
          className="passo2"
          value={data?.condicaoPagamento || null}
          clienteUuid={pedido.pessoa}
          vendedorUuid={pedido.vendedor}
          onChange={(value) => {
            setData({
              ...data,
              condicaoPagamento: value,
              condicaoPagamentoUuid: value?.uuid,
            })
            salvarPedido({
              ...data,
              condicaoPagamento: value,
              condicaoPagamentoUuid: value?.uuid,
            })
          }}
          disabled={!podeEditar}
          isLoading={isLoading}
          error={Boolean(error?.message)}
        />

        {Boolean((configuracoes?.usa_formapgto || 0) > 0) && (
          <FormaPagamentoTransporter
            value={data?.formaPagamento || null}
            clienteUuid={pedido.pessoa}
            vendedorUuid={pedido.vendedor}
            onChange={(value) => {
              setData({
                ...data,
                formaPagamento: value,
                formaPagamentoUuid: value?.uuid,
              })
              salvarPedido({
                ...data,
                formaPagamento: value,
                formaPagamentoUuid: value?.uuid,
              })
            }}
            isLoading={isLoading}
            error={Boolean(error?.message)}
            disabled={!podeEditar}
          />
        )}

        {Boolean(configuracoes?.usa_descontos) && (
          <Stack>
            <DropDownDescontos
              label="Desconto"
              onChange={(value) => {
                setData({
                  ...data,
                  desconto: value,
                  descontoUuid: value?.uuid,
                  percentualDesconto: value?.pdesconto,
                  descontos: value?.pdescontos,
                })
                salvarDesconto(value)
              }}
              value={data?.desconto || null}
            />
          </Stack>
        )}

        {Boolean(usaAjuste) && (
          <TextField
            select
            value={data?.ajuste || 0}
            label="Ajuste"
            onChange={(e) => {
              const ajuste = e.target.value
              setData({
                ...data,
                ajuste,
              })
              salvarPedido({
                ...data,
                ajuste,
              })
            }}
          >
            {Array.from(
              { length: (configuracoes?.ajuste || 0) + 1 },
              (_, i) => i,
            ).map((ajuste) => (
              <MenuItem value={ajustesMap[ajuste]}>{ajuste}</MenuItem>
            ))}
          </TextField>
        )}

        {Boolean(configuracoes?.edita_observacao) && (
          <AutoSaverTextField
            name="observacao"
            label="Observação"
            value={data?.observacao || ''}
            extraParams={data}
            multiline
            rows={4}
            error={Boolean(error?.message)}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              extraParams?: PedidoModel,
            ) => {
              handleChange(e)
              salvarPedido({
                ...(extraParams as PedidoModel),
                observacao: e.target.value,
              })
            }}
            disabled={!podeEditar}
          />
        )}

        <Divider />

        <AutoCompleteEtiquetas
          value={data?.etiquetas || []}
          onChange={(_: any, value: any) => {
            syncEtiquetas({
              pedidoUuid: data?.uuid || '',
              etiquetas: value || [],
            })
            changeValue('etiquetas', value || [])
            //  changeFilterValue('etiqueta', value)
          }}
        />

        <Divider />
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          width: '97%',
          pr: 1,
          bottom: '0px',
          background: '#FFF',
        }}
        spacing={0.5}
      >
        <FeedbackRequestStatus
          value={data}
          isLoading={isLoading || isLoadingUpdateDesconto}
          isSuccess={isSuccess || isSuccessUpdateDesconto}
          error={
            Boolean(error?.message) || Boolean(errorUpdateDesconto?.message)
          }
          onRetry={(value: PedidoModel) =>
            salvarPedido({
              ...data,
              observacao: value?.observacao,
              validadeCotacao: value?.validadeCotacao || null,
            })
          }
        />

        {Boolean(configuracoes?.usa_limite_credito) && (
          <Stack
            sx={{
              p: 0.5,
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: '4px',
            }}
          >
            <Typography variant="body1" fontSize="11pt">
              {`Limite de Crédito: R$${formatCurrency(limiteRestante)}`}
            </Typography>
            <IndicadorLimiteCredito
              data={{
                ...indicadoresLimiteCredito,
                valorPedidoAtual: totalPedidoAtual,
              }}
            />
          </Stack>
        )}

        {Boolean(pedido.representante?.pedidoMinimo) && (
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="body1" fontSize="11pt">
              Total Mínimo:
            </Typography>
            <Typography variant="body1" fontSize="11pt" fontWeight="bolder">
              {`R$${formatCurrency(pedido.representante?.pedidoMinimo || 0)}`}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="body1" fontSize="11pt">
            Total:
          </Typography>
          <Typography variant="body1" fontSize="11pt" fontWeight="bolder">
            {`R$${formatCurrency(pedido.totalComImposto)}`}
          </Typography>
        </Stack>
        {descontoAgregadoDesbloqueado && podeExibirDescontoProgressivo && (
          <>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body1" fontSize="11pt">
                Total c/ desconto:
              </Typography>
              <Typography variant="body1" fontSize="11pt" fontWeight="bolder">
                {`R$${formatCurrency(totalDescontoAgregado)}`}
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              fontSize="9pt"
              sx={{ textAlign: 'center' }}
            >
              O desconto é aplicado ao fechar o pedido
            </Typography>
          </>
        )}
      </Stack>

      <Grid container spacing={2}>
        {pedido.iscotacao && (
          <Grid size={12}>
            <DatePicker
              label="Validade da Cotação"
              value={data?.validadeCotacao || null}
              error={Boolean(error?.message)}
              onChange={(date: string | null) => {
                changeValue('validadeCotacao', date)
                salvarPedido({
                  ...data,
                  validadeCotacao: date,
                })
              }}
              disabled={!podeEditarValidadeDaCotacao || !podeEditar}
            />
          </Grid>
        )}
      </Grid>
      <Box
      // sx={{
      //   position: 'absolute',
      //   right: '10px',
      //   top: '10px',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   gap: 1,
      // }}
      >
        {/* {isLoadingEtiquetasVinculadas && <LinearProgress />} */}
      </Box>
    </Surface>
  )
}
