import IconCadastro from './IconCadastro'
import IconChevronDown from './IconChevronDown'
import IconCircle from './IconCircle'
import IconClientes from './IconClientes'
import IconDashboard from './IconDashboard'
import IconDown from './IconDown'
import IconErease from './IconErease'
import IconExpandable from './IconExpandable'
import IconFilter from './IconFilter'
import IconKanban from './IconKanban'
import IconNegociacao from './IconNegociacao'
import IconSearch from './IconSearch'
import IconBarCode from './IconBarCode'
import IconTextCursor from './IconTextCursor'
import IconOpenPanel from './IconOpenPanel'
import IconClosePanel from './IconClosePanel'
import IconMenu from './IconMenu'
import IconConfigs from './IconConfigs'
import IconUsers from './IconUsers'
import IconPasswords from './IconPasswords'
import IconLogout from './IconLogout'
import IconHistory from './IconHistory'
import IconHelp from './IconHelp'
import IconDocs from './IconDocs'
import IconVideo from './IconVideo'
import IconBlock from './IconBlock'
import IconMaps from './IconMaps'
import IconMapPin from './IconMapPin'

const Icon = {
  Circle: IconCircle,
  Dashboard: IconDashboard,
  Expandable: IconExpandable,
  Down: IconDown,
  Negociacao: IconNegociacao,
  Cadastro: IconCadastro,
  Clientes: IconClientes,
  Filter: IconFilter,
  Search: IconSearch,
  ChevronDown: IconChevronDown,
  Kanban: IconKanban,
  Erease: IconErease,
  BarCode: IconBarCode,
  TextCursor: IconTextCursor,
  OpenPanel: IconOpenPanel,
  ClosePanel: IconClosePanel,
  Menu: IconMenu,
  Configs: IconConfigs,
  Users: IconUsers,
  Password: IconPasswords,
  Logout: IconLogout,
  History: IconHistory,
  Help: IconHelp,
  Video: IconVideo,
  Docs: IconDocs,
  Block: IconBlock,
  Maps: IconMaps,
  MapPin: IconMapPin,
}

export default Icon
