import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'

import useFormaPagamento from 'hooks/queries/useFormaPagamento'
import { filters } from 'hooks/queries/useFormaPagamento'
import Delete from 'components/Delete'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import Assistant from 'components/MioCandidate/Assistant'
import Form from './components/Form'
import { ButtonContainer } from '@data-c/ui'
export default function FormaPagamentos() {
  const { useDelete } = useFormaPagamento()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="27677489623188" />
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <MioFilter.Provider filterValues={{ ativo: filters.ativo?.value }}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Forma de Pagamento',
                  link: 'forma-pagamento/lista',
                },
              ]}
            />
            <Filter />

            <ButtonContainer>
              <Form />
            </ButtonContainer>
          </ContentTitle.Root>
          <Content>
            <Table />
            <Delete onDelete={handleDelete} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
