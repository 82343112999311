import DialogRoot from './DialogRoot'
import DialogProvider from './DialogProvider'
import DialogAdicionarButton from './DialogAdicionarButton'
import DialogHeader from './DialogHeader'
import DialogTitle from './DialogTitle'
import DialogActionClose from './DialogActionClose'
import DialogContent from './DialogContent'
import DialogFooter from './DialogFooter'
import DialogActions from './DialogActions'
import DialogActionCancelar from './DialogActionCancelar'
import DialogActionSalvar from './DialogActionSalvar'

const Dialog = {
    Root: DialogRoot,
    Provider: DialogProvider,
    AdicionarButton: DialogAdicionarButton,
    Header: DialogHeader,
    Title: DialogTitle,
    ActionClose: DialogActionClose,
    Content: DialogContent,
    Footer: DialogFooter,
    Actions: DialogActions,
    ActionCancelar: DialogActionCancelar,
    ActionSalvar: DialogActionSalvar,
}

export default Dialog