import { Edit as EditIcon } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'

interface CellActionEditProps {
  disabled?: boolean
  title?: string
  onClick?: (event: 'edit') => void
}

export default function CellActionEdit({
  onClick,
  ...rest
}: CellActionEditProps) {
  return (
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Edit"
      color="primary"
      onClick={() => onClick && onClick('edit')}
      {...rest}
    />
  )
}
