import { findData } from '@data-c/hooks'
import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'

import { Box, Card, IconButton, Stack, Typography } from '@mui/material'
import Divider from 'components/Divider'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import {
  obterCorDaSituacao,
  PedidoModel,
  SituacaoPedidoEnum,
} from 'hooks/queries/usePedidos'
import { ItemValues } from './components/ItemValues'
import FlagSituacao from './components/FlagSituacao'

export interface PedidosFormatados {
  uuid: string
  representante: string
  cliente: string
  venda: string
  totalLiquido: number
  totalComImposto: string
  pesoTotal: string
  observacao: string
  pedidoRelacionadoCodigo: string | number
  situacao: SituacaoPedidoEnum
  codigo: string
  vendaAsBrazilianDate: string
  situacaoAsText: string
}

interface CardsPedidosProps {
  pedidoFormatado: PedidosFormatados
  pedidos: PedidoModel[]
  onClickItem: (event: 'edit' | 'delete', data: PedidoModel) => void
}

export default function CardsPedidos(props: CardsPedidosProps) {
  const { pedidoFormatado, pedidos, onClickItem } = props

  const { data: configuracoes } = useQuery()

  function riscarNegociacaoPerdida(situacao: SituacaoPedidoEnum) {
    return situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA
      ? 'line-through'
      : 'none'
  }

  return (
    <Card
      sx={{
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        borderColor: `${obterCorDaSituacao(pedidoFormatado.situacao)}`,
        p: 1,
        textDecoration: `${riscarNegociacaoPerdida(pedidoFormatado.situacao)}`,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bolder' }}
          >{`#${pedidoFormatado.codigo}`}</Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bolder' }}
          >{`${pedidoFormatado.vendaAsBrazilianDate}`}</Typography>
        </Stack>

        <Box>
          <IconButton
            color="primary"
            onClick={() => {
              const _data = findData<PedidoModel>(
                pedidos || [],
                pedidoFormatado.uuid,
              )
              console.log({ _data })
              if (_data) onClickItem('edit', _data)
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              const _data = findData<PedidoModel>(
                pedidos || [],
                pedidoFormatado.uuid,
              )
              if (_data) onClickItem('delete', _data)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>

      <Divider />

      <Stack spacing={0.5} sx={{ mt: 1, mb: 1, minHeight: '60px' }}>
        <ItemValues
          direction={'row'}
          label={'Cliente:'}
          conteudo={pedidoFormatado.cliente}
        ></ItemValues>
        <ItemValues
          direction={'row'}
          label={'Vendedor:'}
          conteudo={pedidoFormatado.representante}
        ></ItemValues>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 1 }}
      >
        <FlagSituacao
          situacao={pedidoFormatado.situacao}
          situacaoAsText={pedidoFormatado.situacaoAsText}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          {configuracoes?.exibe_peso == true && (
            <Typography variant="subtitle2">
              {pedidoFormatado.pesoTotal}
            </Typography>
          )}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bolder',
              // color: (theme) => theme.palette.grey[500],
            }}
          >{`R$ ${pedidoFormatado.totalComImposto}`}</Typography>
        </Stack>
      </Stack>
    </Card>
  )
}
