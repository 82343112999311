import { Container } from '@mui/material'

import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import { filters } from 'hooks/queries/useRelatorioMetaVendas'
import Filter from './components/Filter'
import Table from './components/Table'
export default function MetasVendas() {
  return (
    <Container>
      <MioFilter.Provider
        filterValues={{
          tipoData: filters.tipoData?.value,
          situacao: filters.situacao?.value,
          dateRange: filters.dateRange?.value,
        }}
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[{ label: 'Metas de Vendas', link: '/meta-vendas' }]}
          />
          <Filter />
        </ContentTitle.Root>

        <Content>
          <Table />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
