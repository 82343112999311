import api from 'services/api'
import { useQuery } from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'
import { TimeVendasModel } from './useTimeVendas'
import { DateTime } from 'luxon'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { FunilVendaModel } from './useFunilVenda'
import { useUserContext } from 'components/Contexts/UserContext'
import { TooltipProps } from 'recharts'
import { EtiquetaModel } from './useEtiqueta'

export interface TaxaConversaoNegociacaoModelModel {
  // etapa: string
  // totalNegociacoes: number
  // negociacoesGanhas: number
  // taxaConversao: number
  // totalNegociacoesProximaEtapa?: number
  etapaUuid: string
  etapaNome: string
  etapaPosicao: number
  totalNegociacoesNaEtapa: number
  totalGanhoNaEtapa: number
  totalAcumuladoNaEtapa: number
  totalGanhoAcumuladoNaEtapa: number
  taxaConversao: number
  customTooltip?: React.FC<TooltipProps<any, any>>
}

export interface TaxaConversaoNegociacaoModelFilters {
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  funilVenda: FilterOptions<FunilVendaModel | null>
  funilUuid?: FilterOptions<string | null>
  dateRange?: FilterOptions<DateRange>
  dataInicial?: FilterOptions<string>
  dataFinal?: FilterOptions<string>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaModel>>
}

export interface TaxaConversaoNegociacaoModelFilters1 {
  representante?: TimeVendasModel | null
  representanteUuid?: string
  funilUuid?: string
  funilVenda?: FunilVendaModel | null
  dateRange?: DateRange
  dataInicial?: string | null
  dataFinal?: string | null
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaModel> | []
}

export const filters: TaxaConversaoNegociacaoModelFilters = {
  dataInicial: {
    label: 'Data inicial',
  },
  dataFinal: {
    label: 'Data final',
  },
  representante: {
    label: 'Vendedor',
    value: null,
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  funilVenda: {
    label: 'Funil de Venda',
    value: null,
  },
  funilUuid: {
    value: '',
    label: 'Funil de Venda',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export async function obterTaxaConversaoNegociacaoModel(
  params: TaxaConversaoNegociacaoModelFilters1,
): Promise<Array<TaxaConversaoNegociacaoModelModel>> {
  if (!params.funilUuid) return []

  const etiquetas = params?.etiquetaKeyValue
    ? params?.etiquetaKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...params,
    etiqueta: etiquetas,
  }

  const response = await api.get<Array<TaxaConversaoNegociacaoModelModel>>(
    `relatorio/taxa-conversao-etapa-funil`,
    {
      params: nQueryParams,
    },
  )
  return response.data
}

export function useQueryObterTaxaConversaoNegociacaoModel(
  params: TaxaConversaoNegociacaoModelFilters1,
  searchId?: string,
) {
  return useQuery<
    Array<TaxaConversaoNegociacaoModelModel>,
    AxiosError<ErrorInterface>
  >(['RELATORIO_TAXA_CONVERSAO_NEGOCIACAO', params, searchId], () => {
    return obterTaxaConversaoNegociacaoModel(params)
  })
}

export function useFilterValues() {
  const { user } = useUserContext()
  if (user?.funilDeVenda) {
    filters.funilVenda.value = user.funilDeVenda || null
  }
  return filters
}

export default function useTaxaConversaoNegociacaoModel() {
  return {
    useQueryObterTaxaConversaoNegociacaoModel,
  }
}
