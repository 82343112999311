import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { ABCProdutosFilters1 } from 'hooks/queries/useRelatorioABCProdutos'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import FilterForm from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()

  const { filterValues, appliedValues } = useFilter<ABCProdutosFilters1>()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Gerar Relatório"
          disabled
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            disabledDelete
            name="dateRange"
            label={
              appliedValues?.dateRange?.tipo === 'day'
                ? 'Dia: ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
                : 'Periodo: ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                    appliedValues?.dateRange?.secondDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
            }
          >
            <DateRangePicker
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={filterValues?.dateRange}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            disabledDelete
            name="situacaoPedido"
            label={`Situacao - ${
              appliedValues?.situacaoPedido === 'emissao'
                ? 'Faturados'
                : 'Em Processamento'
            }`}
          >
            <TextField
              select
              label="Situação do Pedido"
              value={filterValues?.situacaoPedido || ''}
              onChange={(e) =>
                changeFilterValue('situacaoPedido', e.target.value)
              }
            >
              <MenuItem value="emissao">Faturados</MenuItem>
              <MenuItem value="venda">Em processamento</MenuItem>
            </TextField>
          </MioFilter.Chip>
          <MioFilter.Chip
            disabledDelete
            name="metrica"
            label={'Calcular por - ' + appliedValues?.metrica}
          >
            <TextField
              select
              label="Calcular por"
              value={filterValues?.metrica || ''}
              onChange={(e) => changeFilterValue('metrica', e.target.value)}
            >
              <MenuItem value="total">Total</MenuItem>
              <MenuItem value="quantidade">Quantidade</MenuItem>
            </TextField>
          </MioFilter.Chip>

          <MioFilter.Chip
            disabledDelete
            name="percentualA"
            label={'A - ' + appliedValues?.percentualA + '%'}
          >
            <TextField
              type="number"
              label="A"
              value={filterValues?.percentualA || ''}
              onChange={(e) => changeFilterValue('percentualA', e.target.value)}
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            disabledDelete
            name="percentualB"
            label={'B - ' + appliedValues?.percentualB + '%'}
          >
            <TextField
              type="number"
              label="B"
              value={filterValues?.percentualB || ''}
              onChange={(e) => changeFilterValue('percentualB', e.target.value)}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            disabledDelete
            name="percentualC"
            label={'C - ' + appliedValues?.percentualC + '%'}
          >
            <TextField
              type="number"
              label="C"
              value={filterValues?.percentualC || ''}
              onChange={(e) => changeFilterValue('percentualC', e.target.value)}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="representante"
            label={appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              name="vendedorUuid"
              label="Vendedor"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              slotProps={{ inputLabel: { shrink: true } }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="etiquetaKeyValue"
            label={`${
              appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta:'
                : 'Etiquetas:'
            } ${
              Array.isArray(appliedValues?.etiquetaKeyValue)
                ? appliedValues.etiquetaKeyValue
                    .map((etiqueta: any) => ' ' + etiqueta.label)
                    .join('')
                : ''
            }`}
          >
            <Autocomplete
              multiple
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>
        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
