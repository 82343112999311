import { Stack } from '@mui/material'
import { GoogleMap, OverlayView, useLoadScript } from '@react-google-maps/api'
import { ClientesModel } from 'hooks/queries/useClientes'
import {
  Bounds,
  obterMapaDeClientes,
} from 'hooks/queries/useRelatorioMapaDeClientes'
import { useCallback, useRef, useState } from 'react'
import { PushPin } from '@mui/icons-material'

export default function Maps() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  })
  const [initialCenter] = useState({ lat: -23.55052, lng: -46.633308 })
  const [_, setLoadingClientes] = useState<boolean>(false)
  const [zoom, setZoom] = useState(10)
  const [clientes, setClientes] = useState<Array<ClientesModel>>([])
  const mapRef = useRef<google.maps.Map | null>(null)
  const lastBoundsRef = useRef<Bounds | null>(null)
  const fetchClientesByBounds = useCallback(async (bounds: Bounds) => {
    setLoadingClientes(true)

    try {
      const clientes = await obterMapaDeClientes(bounds)
      setClientes(clientes)
    } catch (e) {
    } finally {
      setLoadingClientes(false)
    }
  }, [])

  const handleZoomChanged = useCallback(() => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom() || 10)
    }
  }, [])

  // Evento chamado quando os limites do mapa mudam (zoom ou movimento)
  const handleBoundsChanged = useCallback(() => {
    const bounds = mapRef?.current?.getBounds()
    if (bounds) {
      const north = bounds.getNorthEast().lat()
      const south = bounds.getSouthWest().lat()
      const east = bounds.getNorthEast().lng()
      const west = bounds.getSouthWest().lng()

      const currentBounds: Bounds = { north, south, east, west }

      // Verifica se os bounds mudaram antes de buscar novamente
      if (
        !lastBoundsRef.current ||
        JSON.stringify(lastBoundsRef.current) !== JSON.stringify(currentBounds)
      ) {
        lastBoundsRef.current = currentBounds
        fetchClientesByBounds(currentBounds)
      }
    }
  }, [])

  function handleOnLoad(map: google.maps.Map) {
    mapRef.current = map
  }

  function calculateScale(zoomLevel: number) {
    const baseScale = 12 // Tamanho inicial do ícone
    const zoomFactor = 1 // Ajuste para controle fino
    // return baseScale / (zoomLevel * zoomFactor)
    return baseScale + zoomLevel * zoomFactor
  }

  if (!isLoaded) return <div>Carregando mapa...</div>

  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      {/* {loadingClientes && <LinearProgress />} */}
      <GoogleMap
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        center={initialCenter}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        onIdle={handleBoundsChanged}
        onLoad={handleOnLoad}
      >
        {clientes.map((cliente) => (
          <OverlayView
            position={{
              lat: cliente.latitude,
              lng: cliente.longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <PushPin
              sx={{
                fontSize: `${calculateScale(zoom)}pt`,
                color: (theme) =>
                  cliente.ativo && !cliente.bloqueado
                    ? theme.palette.info.main
                    : theme.palette.error.main,
              }}
            />
          </OverlayView>
        ))}
      </GoogleMap>
    </Stack>
  )
}
