import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'

import Delete from 'components/Delete'
import Form from './components/Form'
import Content from 'components/Layouts/Content'
import useFunilVenda from 'hooks/queries/useFunilVenda'
import Table from './components/Table'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Assistant from 'components/MioCandidate/Assistant'

export default function FunisDeVendas() {
  const { useDelete } = useFunilVenda()
  const { mutateAsync: handleDelete } = useDelete()
  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article
          open={true}
          articleId="28518211079316"
        ></Assistant.Article>
      </Assistant.Root>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Funil de Vendas',
                link: '/funis_venda/lista',
              },
            ]}
          />

          <Form />
        </ContentTitle.Root>

        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
