import { useMemo, useCallback } from 'react'

import { DataTable, DataTableOptions as Options } from '@data-c/ui'
import { useFormApi } from '@data-c/providers'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useMixProdutos, {
  MixProdutoBonificacaoModel,
} from 'hooks/queries/useMixProdutos'
import Surface from 'components/Surface'
import { findData, useDialog } from '@data-c/hooks'
import Produtos from '../Produtos'

interface TableProps {
  mixProdutoUuid: string
}

export default function Table(props: TableProps) {
  const { mixProdutoUuid } = props
  const { openForm, openConfirm } = useFormApi()
  const { useQueryObterBonificacoes } = useMixProdutos()
  const {
    isOpen: isOpenDialog,
    openDialog,
    closeDialog,
    data: regra,
  } = useDialog<MixProdutoBonificacaoModel>()

  const { data, isLoading, isFetching, error } =
    useQueryObterBonificacoes(mixProdutoUuid)

  const descontos = useMemo(() => {
    return data?.data.map((d) => {
      return {
        ...d,
        representanteNome: d?.representante?.nome,
      }
    })
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'delete' | 'produtos', data: MixProdutoBonificacaoModel) => {
      switch (event) {
        case 'delete':
          openConfirm(data)
          break
        case 'produtos':
          openDialog(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'quantidadeMinima',
        label: 'Quantidade Mínima',
        options: {
          // setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'representanteNome',
        label: 'Vendedor',
        options: {
          // setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'bonificacoes',
        label: 'Qtd. Produtos',
        options: {
          customBodyRender: (value) => value.length,
          setCellProps: () => ({
            style: {
              width: '100px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            style: {
              width: '100px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData(descontos || [], value)
            // const qtdBonificacoes = _data?.bonificacoes?.length || 0
            // console.log('FD', qtdBonificacoes)
            return (
              <Options
                displayDeleteButton={true}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
                extraOptions={[
                  {
                    id: 'produtos',
                    icon: 'redeem',
                    iconButtonProps: { color: 'primary' },
                    visible: true,
                    badge: 0,
                  },
                ]}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, descontos],
  )

  return (
    <>
      <DataTable
        error={error?.message}
        columns={columns}
        data={descontos || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          pagination: false,
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />
      <Produtos
        regraUuid={regra?.uuid || ''}
        mixProdutoUuid={mixProdutoUuid || ''}
        isOpen={isOpenDialog}
        closeDialog={closeDialog}
      />
    </>
  )
}
