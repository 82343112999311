import { useMobileComponents } from 'hooks/useMobileComponents'
import { ReactNode } from 'react'

export interface MobileOrDesktopRendererProps {
  mobileChildren: ReactNode
  desktopChildren: ReactNode
}

export default function MobileOrDesktopRenderer({
  mobileChildren,
  desktopChildren,
}: MobileOrDesktopRendererProps) {
  const { isMobile } = useMobileComponents()

  return isMobile ? mobileChildren : desktopChildren
}
