import { useEffect, useMemo, useState } from 'react'

import { Box, Grid2 as Grid } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, FormContainer } from '@data-c/ui'
import { formatCurrency, usePagination } from '@data-c/hooks'
import { useFormApi, useFormData } from '@data-c/providers'

import useTabelaPreco, {
  ItemTabelaPrecoModel,
} from 'hooks/queries/useTabelaPreco'

import SearchTextField from 'components/SearchTextField'
import InteractiveCell from '../Table/components/InteractiveCell'

interface FormProps {
  tabelaPrecoUuid: string
}

function temProdutosSelecionados(
  indexProdutosSelecionados: Array<string | undefined>,
): boolean {
  return indexProdutosSelecionados.length > 0
}

export default function Form(props: FormProps) {
  const { tabelaPrecoUuid } = props

  const [query, setQuery] = useState('')
  const [indexProdutosSelecionados, setIndexProdutosSelecionados] = useState<
    number[]
  >([])

  const { isOpen } = useFormData()
  const { toggleSubmit, closeForm } = useFormApi()
  const { pagination, changePage, changePageSize } = usePagination()
  const { useQueryObterProdutosNaoVinculados, useVincularProdutos } =
    useTabelaPreco()
  const {
    data: produtosNaoVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryObterProdutosNaoVinculados({
    plainQuery: query,
    tabelaPrecoUuid,
    pagination,
  })
  const { mutateAsync: vincularProdutos } = useVincularProdutos()

  useEffect(() => {
    if (isOpen === false) {
      setIndexProdutosSelecionados([])
    }
  }, [isOpen])

  const formattedData = useMemo(
    () =>
      produtosNaoVinculados?.data?.map((item) => {
        return {
          ...item,
          produtocls: item?.produtoClasse?.nome || '',
          produtofml: item?.familiaProduto?.nome || '',
          produtolnh: item?.linhaProduto?.nome || '',
          produtogrp: item?.grupoProduto?.nome || '',
          produtoprc: formatCurrency(0),
        }
      }),
    [produtosNaoVinculados],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'produtocls',
        label: 'Classe',
      },
      {
        name: 'produtofml',
        label: 'Família',
      },
      {
        name: 'produtogrp',
        label: 'Grupo',
      },
      {
        name: 'produtolnh',
        label: 'Linha',
      },
      {
        name: 'produtoprc',
        label: 'Preço',
        options: {
          setCellProps() {
            return { style: { width: '172px', textAlign: 'right' } }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <InteractiveCell
                rowIndex={rowIndex}
                value={value}
                tabelaPrecoUuid={tabelaPrecoUuid}
                produtos={
                  (produtosNaoVinculados?.data as unknown as ItemTabelaPrecoModel[]) ||
                  ({} as ItemTabelaPrecoModel[])
                }
                tipoOperacao="naoVinculado"
                tipoValor="preco"
              />
            )
          },
        },
      },
    ],
    [produtosNaoVinculados],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  async function handleClickVincularProdutos() {
    const produtosSelecionadosIds =
      indexProdutosSelecionados.map(
        (index) => produtosNaoVinculados?.data[index]?.uuid || '',
      ) || []

    if (tabelaPrecoUuid && temProdutosSelecionados(produtosSelecionadosIds)) {
      toggleSubmit(true)
      try {
        await vincularProdutos({
          tabelaPrecoUuid,
          produtosSelecionadosIds,
        })
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  return (
    <FormContainer
      onSubmitForm={handleClickVincularProdutos}
      triggerButtonLabel="Vincular"
      confirmButtonLabel="Vincular"
      dialogProps={{ title: 'Produtos', maxWidth: 'xl' }}
    >
      <Box sx={{ mb: 1 }}>
        <SearchTextField
          placeholder="Digite o códgo ou o nome do produto para pesquisar"
          onSearch={handleSearch}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <DataTable
            error={error?.message}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            data={formattedData || []}
            changePage={changePage}
            changePageSize={changePageSize}
            pagination={produtosNaoVinculados?.pagination}
            options={{
              rowsPerPageOptions: [15, 50, 100],
              rowsPerPage: 15,
              selectableRows: 'multiple',
              //selectableRowsOnClick: true,
              rowsSelected: indexProdutosSelecionados,
              onRowSelectionChange: (_, __, rowsSelecteds: any) =>
                setIndexProdutosSelecionados(rowsSelecteds),
              selectToolbarPlacement: 'none',
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}