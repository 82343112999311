import { useMemo, useState } from 'react'

import { Button, DataTable, Flag } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { SortProps } from 'interfaces/SortProps'
import usePessoa, { EntidadeEnum } from 'hooks/queries/usePessoa'
import { useUserContext } from 'components/Contexts/UserContext'

export interface TableProps {
  pessoaUuid: string
  titulo: string
  entidade: EntidadeEnum
  situacaoPessoa?: 'CLIENTE' | 'VENDEDOR'
}

interface ItemProps {
  uuid: string
}

function temItensSelecionados(
  indexItensSelecionados: Array<string | undefined>,
): boolean {
  return indexItensSelecionados.length > 0
}

export default function Table<T extends ItemProps>(props: TableProps) {
  const { pessoaUuid, titulo, entidade, situacaoPessoa } = props

  const [sort, setSort] = useState<SortProps>({
    column: 'nome',
    direction: 'asc',
  })
  const [indexItensSelecionados, setIndexItensSelecionados] = useState<
    number[]
  >([])

  const { temPermissao } = useUserContext()
  const { changePageSize, changePage, pagination } = usePagination()
  const { useQueryObterEntidadesVinculadas, useDesvincularEntidades } =
    usePessoa()
  const {
    data: entidadesVinculadas,
    isLoading,
    isFetching,
    error,
  } = useQueryObterEntidadesVinculadas<T>(
    {
      pagination,
      pessoaUuid,
      entidade,
    },
    sort,
  )
  const {
    mutateAsync: desvincularEntidades,
    isLoading: isLoadingDesvincularEntidades,
  } = useDesvincularEntidades<T>(titulo)

  const formattedData = useMemo(
    () =>
      entidadesVinculadas?.data?.map((item) => {
        return {
          ...item,
        }
      }),
    [entidadesVinculadas],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  async function handleClickDesvincularEntidades() {
    const itensSelecionadosIds =
      indexItensSelecionados.map(
        (index) => entidadesVinculadas?.data[index]?.uuid || '',
      ) || []

    if (pessoaUuid && temItensSelecionados(itensSelecionadosIds)) {
      try {
        await desvincularEntidades({
          pessoaUuid,
          itensSelecionadosIds,
          entidade,
        })
      } finally {
        setIndexItensSelecionados([])
      }
    }
  }

  const temPermissaoParaDesvincular =
    situacaoPessoa === 'CLIENTE'
      ? temPermissao('clientes.alter') !== 'sem_acesso'
      : temPermissao('vendedores.alter') !== 'sem_acesso'

  return (
    <DataTable
      error={error?.message}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      data={formattedData || []}
      pagination={entidadesVinculadas?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        selectableRows: temPermissaoParaDesvincular ? 'multiple' : 'none',
        selectableRowsOnClick: true,
        rowsSelected: indexItensSelecionados,
        sort: true,
        sortOrder: {
          name: sort.column,
          direction: sort.direction,
        },
        onRowSelectionChange: (_, __, rowsSelected: any) =>
          setIndexItensSelecionados(rowsSelected),
        customToolbarSelect() {
          return (
            <Button
              sx={{ marginRight: '26px' }}
              color="error"
              isLoading={isLoadingDesvincularEntidades}
              onClick={handleClickDesvincularEntidades}
            >
              Desvincular {titulo}
            </Button>
          )
        },
      }}
      changeSort={(column, direction) => {
        setSort({ column, direction: direction as 'asc' | 'desc' })
      }}
    />
  )
}
