import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import BaseModel from 'interfaces/BaseModel'

export interface TimeVendasModel extends BaseModel {
  codigo: string
  nome: string
  usual?: string
  email: string
  cnpjcpf: string
  pedidoMinimo: string
}

export default function useTimeVendas() {
  return useCrud<TimeVendasModel, AxiosError>(
    'administrador/time-vendas',
    'Vendedor',
    'male',
  )
}
