import { MenuItem, TextField } from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { DuplicataFilter1 } from 'hooks/queries/useClientes'
import { DateTime } from 'luxon'
import FilterForm from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { filterValues, appliedValues } = useFilter<DuplicataFilter1>()

  return (
    <>
      <MioFilter.Root>
        <MioFilter.Container>
          <MioFilter.SearchTextField
            onChange={(query) => {
              changeFilterValue('plainQuery', query)
              applyFilterValues()
            }}
            fullWidth
            autoFocus
            placeholder="Pesquisar"
          />
          <MioFilter.Chips>
            <MioFilter.Chip
              disabledDelete
              name="tipoData"
              label={appliedValues?.tipoData}
            >
              <TextField
                label="Tipo da Data"
                name="tipoData"
                value={filterValues?.tipoData || 'vencimento'}
                select
                onChange={(e) => {
                  const key = e.target.value
                  changeFilterValue('tipoData', key)
                }}
              >
                <MenuItem value="vencimento">Vencimento</MenuItem>
                <MenuItem value="pagamento">Pagamento</MenuItem>
                <MenuItem value="emissao">Emissão</MenuItem>
              </TextField>
            </MioFilter.Chip>
            <MioFilter.Chip
              disabledDelete
              name="dateRange"
              label={
                appliedValues?.dateRange?.tipo === 'day'
                  ? 'Dia: ' +
                    `${DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')}`
                  : 'Periodo: ' +
                    `${DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                      appliedValues?.dateRange?.secondDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')}`
              }
            >
              <DateRangePicker
                onChange={(value) => {
                  changeFilterValue('dateRange', value)
                }}
                value={filterValues?.dateRange}
              />
            </MioFilter.Chip>
          </MioFilter.Chips>
          <MioFilter.AdvancedButton />
        </MioFilter.Container>
        <MioFilter.AdvancedContainer>
          <FilterForm />
          <MioFilter.AdvancedActionsContainer>
            <MioFilter.ResetButton />
            <MioFilter.ApplyButton />
          </MioFilter.AdvancedActionsContainer>
        </MioFilter.AdvancedContainer>
      </MioFilter.Root>
    </>
  )
}
