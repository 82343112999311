import { Children, cloneElement, isValidElement, ReactNode } from 'react'

interface CellActionsProps {
  children: ReactNode
  onClick: (event: 'edit' | 'delete') => void
}

export default function CellActions(props: CellActionsProps) {
  const { children, onClick: onItemClick } = props
  return (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement<any>, {
            onClick: onItemClick,
          })
        }
      })}
    </>
  )
}
