import { SortProps, isExists } from '@data-c/hooks'
import { FilterOptions } from '@data-c/providers'
import BaseModel from 'interfaces/BaseModel'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'
import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import HooksResultInterface from 'interfaces/CrudResultInterface'
import { buildActionText } from 'hooks/useCrud'
import useNotification from 'hooks/useNotifications'
import {
  CorModel,
  DescontoAgregadoModel,
  TabelaPrecoModel,
} from './useTabelaPreco'
import { CondicaoPagamentoModel } from './useCondicaoPagamento'
import { FormaPagamentoModel } from './useFormaPagamento'
import ErrorInterface from 'interfaces/ErrorInterface'
import { TimeVendasModel } from './useTimeVendas'
import { ClientesModel } from './useClientes'
import { MotivosDePerdaModel } from './useMotivosDePerda'
import { ProdutoModel } from './useProdutos'
import { AtividadeComentarios } from './useAtividadeComentarios'
import { DateTime } from 'luxon'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { SelectableItem } from 'pages/Pedido/components/TabNegociacao/components/MarcarComoPerdidaForm/components/SelecionarItens'
import { EtapaModel } from './useFunilEtapa'
import { CrmAtividadesModel } from './useCrmAtividades'
import { EtiquetaModel } from './useEtiqueta'
import { useTheme } from '@mui/material'
import { FatorPrecificacaoModel } from './useFatorPrecificacao'
import {
  DescontoModel as DescontoMixModel,
  MixProdutosModel,
} from './useMixProdutos'
import { DescontoModel } from './useDesconto'

export const situacoes = [
  {
    value: 'N',
    label: 'Em aberto',
  },
  {
    value: 'CR',
    label: 'Cotação / Reaberta',
  },
  {
    value: 'T',
    label: 'Cotação / Solicitação',
  },
  {
    value: 'R',
    label: 'Cotação / Análise',
  },
  {
    value: 'U',
    label: 'Cotação / Aprovada',
  },
  {
    value: 'E',
    label: 'Fechado',
  },
  {
    value: 'A',
    label: 'Análise',
  },
  {
    value: 'L',
    label: 'Liberado',
  },
  {
    value: 'F',
    label: 'Faturado',
  },
  {
    value: 'O',
    label: 'Orçamento',
  },
  {
    value: 'S',
    label: 'Negociação Perdida',
  },
]

export const situacoesFunil = [
  {
    value: 'N',
    label: 'Em negociação',
  },
  {
    value: 'E,A,L',
    label: 'Negociação ganha',
  },
  {
    value: 'S',
    label: 'Negociação perdida',
  },
]

export interface RequestCodigoBarras {
  pedidoUuid: string
  codigoBarras: string
  prontaEntrega: boolean
}

export interface SituacaoInterface {
  label: string
  value: string
}

export interface PedidoFilters {
  codigo: FilterOptions<string>
  dataInicial: FilterOptions<string>
  dataFinal: FilterOptions<string>
  situacao: FilterOptions<Array<string>>
  situacaoKeyValue: FilterOptions<Array<SituacaoInterface>>
  cliente: FilterOptions<ClientesModel>
  clienteUuid: FilterOptions<string>
  representanteUuid: FilterOptions<string>
  representante: FilterOptions<TimeVendasModel>
  dateRange?: FilterOptions<DateRange>
  carga?: FilterOptions<string>
  operacao?: FilterOptions<string>
  etiqueta: FilterOptions<Array<string>>
  etiquetaKeyValue: FilterOptions<Array<EtiquetaModel>>
}

export interface PedidoFilters1 {
  plainQuery?: string | null
  codigo?: string
  dataInicial?: string | null
  dataFinal?: string | null
  dateRange?: DateRange
  situacao?: Array<SituacaoInterface>
  cliente?: ClientesModel
  clienteUuid?: string
  representante?: TimeVendasModel
  representanteUuid?: string
  carga?: string
  operacao?: string
  etiqueta?: Array<EtiquetaModel>
}

export const filters: PedidoFilters = {
  codigo: {
    label: 'Código',
  },
  dataInicial: {
    label: 'Data Inicial',
  },
  dataFinal: {
    label: 'Data Final',
  },
  situacao: {
    value: [],
    label: '',
  },
  situacaoKeyValue: {
    value: [],
    label: 'Situação',
  },
  cliente: {
    label: 'Cliente',
  },
  clienteUuid: {
    label: 'Cliente',
  },
  representante: {
    label: 'Vendedor',
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  carga: {
    value: '',
    label: 'Núm. Carga',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  operacao: {
    label: 'Operação',
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export const operacoesMap: Record<string, string> = {
  V: 'Venda',
  B: 'Bonificação',
}

export const ajustesMap: Record<number, number> = {
  0: 0,
  1: 100,
  2: 50,
  3: 25,
}

export enum SituacaoPedidoEnum {
  WEB = 'N',
  MOBILE = 'M',
  FECHADO = 'E',
  ANALISE = 'A',
  CANCELADO = 'C',
  LIBERADO = 'L',
  ORCAMENTO = 'O',
  COTACAO_SOLICITACAO = 'T',
  COTACAO_ANALISE = 'R',
  COTACAO_APROVADA = 'U',
  NEGOCIACAO_PERDIDA = 'S',
}

export enum OperacaoEnum {
  VENDA = 'V',
  BONIFICACAO = 'B',
}

interface ValoresIndicadorModel {
  duplicatasEmAberto: number
  pedidosEmAberto: number
}

export interface PreviewFecharPedido {
  pedido: PedidoModel
  preview: Array<{
    item: ItemPedidoModel
    quantidadeProntaEntrega: number
    quantidadeFabricacao: number
  }>
}

export interface PedidoModel extends BaseModel {
  uuid?: string
  codigo?: number
  pessoa?: string
  vendedor?: string
  representante?: TimeVendasModel
  cliente?: ClientesModel
  tabelaPreco?: TabelaPrecoModel | null
  tabelaPrecoUuid?: string
  condicaoPagamento?: CondicaoPagamentoModel | null
  condicaoPagamentoUuid?: string
  formaPagamento?: FormaPagamentoModel | null
  formaPagamentoUuid?: string
  itens: Array<ItemPedidoModel>
  itensPerdidos: Array<ItemPerdidoModel>
  comentarios: Array<AtividadeComentarios>
  situacaoAsText?: string
  situacao?: SituacaoPedidoEnum
  vendaAsBrazilianDate?: string
  venda: string
  operacaoAsText?: string
  totalIpi: number
  totalLiquido: number
  totalBruto: number
  totalLiquidoAsBrazilianCurrency: number
  totalComImposto: number
  observacao?: string
  validadeCotacao: string | null
  iscotacao?: boolean
  motivoPerda?: MotivosDePerdaModel | null
  motivoPerdaUuid?: string
  observacaoMotivoPerda?: string
  ajuste?: number | string
  snapshot?: object
  pesoTotal?: number
  pedidosRelacionados?: Array<PedidoModel>
  funilVendaEtapaUuid?: String
  funilVendaEtapa?: EtapaModel
  atividades?: Array<CrmAtividadesModel>
  pedidoRelacionado?: PedidoModel
  valoresIndicador?: ValoresIndicadorModel
  etiquetas?: Array<EtiquetaModel>
  mixProdutos?: Array<MixProdutosModel>
  desconto?: DescontoModel | null
  descontos?: string | null
  percentualDesconto?: number | null
  descontoUuid?: string | null
}

export interface ItemPedidoModel extends BaseModel {
  // produto?: {
  //   uuid: string
  //   codigo: string
  //   nome: string
  //   fracionado: boolean
  // } | null
  produto?: ProdutoModel
  cor?: CorModel
  produtoUuid: string | null
  pedidoUuid: string | null
  quantidade: number
  precoDeTabela: number
  precoTotalLiquido: number
  precoTotalBruto: number
  precoTotalIpi: number
  precoUnitarioIpi: number
  precoTotalIcmsst: number
  precoTotalIcms: number
  precoUnitarioLiquido: number
  precoTotalComImposto: number
  percentualDesconto: number
  percentualIpi: number
  observacao?: string
  corUuid?: string | null
  itemBloqueado?: boolean
  fatorPrecificacao?: FatorPrecificacaoModel
  fatorComissaoChave?: string
  pesoTotal?: number
  descontoAplicado?: DescontoMixModel | null
  mixProdutoUuid?: string
  estoque?: number
}

interface IniciarPedidoModel {
  vendedor: string
  pessoa: string
  situacao?: SituacaoPedidoEnum
  iscotacao?: boolean
  funilVendaEtapaUuid?: string
  ajuste?: number
}

export interface ItensPedidoSelecionados {
  itemPedidoId: string
  itemBloqueado: boolean
}

export interface ItemPerdidoRequestModel {
  itemPedidoUuid: string
  motivoPerdaUuid?: string
  observacaoMotivoPerda?: string
}

export interface ItemPerdidoModel {
  uuid: string
  produto: ProdutoModel
  quantidade: number
  preco: number
  percentualDesconto: number
  pedidoUuid?: string
  clienteUuid?: string
  cliente?: ClientesModel
  motivoPerdaUuid?: string
  motivoPerda?: MotivosDePerdaModel
  observacaoMotivoPerda?: string
}

export interface LinkEtiquetaPedidoModel {
  pedidoUuid: string
  etiquetaUuid: string
  etiqueta?: EtiquetaModel
  pedido?: PedidoModel
}

export interface NegociacaoPerdidaRequest {
  negociacaoUuid: string
  motivoPerdaUuid: string
  observacaoMotivoPerda: string | null
  itensSelecionados: Array<SelectableItem>
}

export interface NegociacaoPerdidaResponse {
  pedido1: PedidoModel
  pedido2: PedidoModel | null
}

export interface PerderItemModel {
  item?: ItemPedidoModel
  motivoPerda?: MotivosDePerdaModel | null
  motivoPerdaUuid?: string | null
  observacaoMotivoPerda?: string
  informarObservacao?: boolean
}

export function obterCorDaSituacao(situacao: SituacaoPedidoEnum) {
  const theme = useTheme()
  switch (situacao as SituacaoPedidoEnum) {
    case SituacaoPedidoEnum.ORCAMENTO:
    case SituacaoPedidoEnum.COTACAO_ANALISE:
    case SituacaoPedidoEnum.COTACAO_APROVADA:
    case SituacaoPedidoEnum.COTACAO_SOLICITACAO:
      return theme.palette.situacao.orcamento
    case SituacaoPedidoEnum.MOBILE:
    case SituacaoPedidoEnum.WEB:
      return theme.palette.situacao.aberto
    case SituacaoPedidoEnum.ANALISE:
      return theme.palette.situacao.analise
    case SituacaoPedidoEnum.LIBERADO:
      return theme.palette.situacao.liberado
    case SituacaoPedidoEnum.FECHADO:
      return theme.palette.situacao.fechado
    case SituacaoPedidoEnum.CANCELADO:
    case SituacaoPedidoEnum.NEGOCIACAO_PERDIDA:
      return theme.palette.situacao.cancelado
    default:
      return 'white'
  }
}

export async function obterPedidos(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
): Promise<HttpResponseInterface<PedidoModel>> {
  const { pagination: _pagination, queryParams } = params
  const situacoes = queryParams?.situacao
    ? queryParams?.situacao.map((s) => s.value)
    : null
  const etiquetas = queryParams?.etiqueta
    ? queryParams?.etiqueta.map((e) => e.uuid)
    : null
  const nQueryParams = {
    ...queryParams,
    situacao: situacoes,
    etiqueta: etiquetas,
  }
  const response = await api.get('vendas/pedidos', {
    params: nQueryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': sort?.column,
      'DC-SortDirection': sort?.direction,
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterCotacoes(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
): Promise<HttpResponseInterface<PedidoModel>> {
  const { pagination: _pagination, queryParams } = params
  const situacoes = queryParams?.situacao
    ? queryParams?.situacao.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    situacao: situacoes,
  }
  const response = await api.get('vendas/cotacoes', {
    params: nQueryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': sort?.column,
      'DC-SortDirection': sort?.direction,
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterPedidoPorId(id: string): Promise<PedidoModel> {
  const response = await api.get(`vendas/pedidos/${id}`)
  return response.data
}

export function useQueryObterPedidoPorId(id: string) {
  return useTQuery<PedidoModel>(['PEDIDO', id], () => {
    return obterPedidoPorId(id)
  })
}

export async function obterPedidoPreview(
  id: string,
): Promise<PreviewFecharPedido> {
  const response = await api.get(`/vendas/pedidos/${id}/preview`)
  return response.data
}

export function useQueryObterPreview(id: string) {
  return useTQuery<PreviewFecharPedido>(['PEDIDO-PREVIEW', id], () => {
    return obterPedidoPreview(id)
  })
}

export function useQueryObterPedidos(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
) {
  return useTQuery<
    HttpResponseInterface<PedidoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PEDIDOS', params, sort], () => {
    return obterPedidos(params, sort)
  })
}

export function useQueryObterCotacoes(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
) {
  return useTQuery<HttpResponseInterface<PedidoModel>>(
    ['COTACOES', params, sort],
    () => {
      return obterCotacoes(params, sort)
    },
  )
}

export function useSubmitIniciarPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function iniciarPedido(data: IniciarPedidoModel): Promise<PedidoModel> {
    const response = await api.post<PedidoModel>('vendas/pedidos', data)
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, IniciarPedidoModel>(
    (data) => iniciarPedido(data),
    {
      onSuccess(data) {
        if (data) {
          notifications.notifySuccess('O Pedido foi iniciado com sucesso')
          queryClient.invalidateQueries(['PEDIDO'])
        }
      },
    },
  )
}

export function useMarcarNegociacaoComoPerdida() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  async function marcarNegociacaoComoPerdida(
    data: NegociacaoPerdidaRequest,
  ): Promise<NegociacaoPerdidaResponse> {
    const response = await api.put(
      `/vendas/pedidos/pedido/${data.negociacaoUuid}/marcar-negociacao-como-perdida`,
      data,
    )

    return response.data
  }

  return useMutation<
    NegociacaoPerdidaResponse,
    AxiosError,
    NegociacaoPerdidaRequest
  >((data) => marcarNegociacaoComoPerdida(data), {
    onSuccess(_: NegociacaoPerdidaResponse) {
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError(err) {
      notifications.notifyException(err)
    },
  })
}

export function useUpdateDesconto() {
  const queryClient = useQueryClient()
  const notifications = useNotification()
  async function updateDesconto(
    pedidoUuid: string,
    desconto: DescontoModel | null,
  ) {
    const data = desconto ? desconto : {}

    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/pedido/${pedidoUuid}/desconto`,
      data,
    )
    return response.data
  }

  return useMutation<
    PedidoModel,
    AxiosError,
    {
      pedidoUuid: string
      desconto: DescontoModel | null
    }
  >((data) => updateDesconto(data.pedidoUuid, data.desconto), {
    onSuccess(pedido: PedidoModel) {
      queryClient.invalidateQueries(['PEDIDO', pedido.uuid])
    },
    onError(err) {
      notifications.notifyException(err)
    },
  })
}

export function useUpdatePedido(
  configs: { attempts: number } = { attempts: 3 },
) {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  async function updatePedido(pedido: PedidoModel): Promise<PedidoModel> {
    const data = {
      observacao: pedido.observacao,
      validadeCotacao: pedido.validadeCotacao,
      tabelaPrecoUuid: pedido.tabelaPrecoUuid || null,
      condicaoPagamentoUuid: pedido?.condicaoPagamentoUuid || null,
      formaPagamentoUuid: pedido?.formaPagamentoUuid || null,
      funilVendaEtapaUuid: pedido?.funilVendaEtapaUuid || null,
      ajuste: pedido?.ajuste,
      descontoUuid: pedido?.descontoUuid || null,
      percentualDesconto: pedido?.percentualDesconto || null,
      descontos: pedido?.descontos || null,
    }

    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/pedido/${pedido.uuid}`,
      data,
      configs,
    )

    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => updatePedido(data),
    {
      onSuccess(pedido: PedidoModel) {
        if (pedido.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA) {
          notifications.notifySuccess(`A negociação foi marcada como perdida`)
        }
        queryClient.invalidateQueries(['PEDIDO', pedido.uuid])
      },
      onError(err, pedido: PedidoModel) {
        if (pedido.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA) {
          notifications.notifyException(err)
        }
      },
    },
  )
}

export function useSyncEtiquetas(
  configs: { attempts: number } = { attempts: 3 },
) {
  const queryClient = useQueryClient()
  // const notifications = useNotification()

  async function syncEtiquetas(
    pedidoUuid: string,
    etiquetas: Array<EtiquetaModel>,
  ): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedidoUuid}/sync-etiquetas`,
      { etiquetas },
      configs,
    )

    return response.data
  }

  return useMutation<
    PedidoModel,
    AxiosError,
    { pedidoUuid: string; etiquetas: Array<EtiquetaModel> }
  >((data) => syncEtiquetas(data.pedidoUuid, data.etiquetas), {
    onSuccess(pedido: PedidoModel) {
      queryClient.invalidateQueries(['PEDIDO', pedido.uuid])
    },
    // onError(err, pedido: PedidoModel) {
    // },
  })
}

export function useSubmitItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  async function saveItemPedido(
    pedidoUuid: string,
    item: ItemPedidoModel,
  ): Promise<HooksResultInterface<ItemPedidoModel>> {
    const { fatorPrecificacao, descontoAplicado, ...toSubmit } = item
    if (descontoAplicado) {
      toSubmit.mixProdutoUuid = descontoAplicado.promomixUuid
    }
    if (fatorPrecificacao) {
      toSubmit.fatorComissaoChave = fatorPrecificacao.uuid
    }
    if (isExists(item, 'uuid')) {
      const response = await api.put<ItemPedidoModel>(
        `vendas/pedidos/item-pedido/${item.uuid}`,
        toSubmit,
      )
      return { data: response.data, operation: 'update' }
    }

    const response = await api.post(
      `vendas/pedidos/${pedidoUuid}/item-pedido/`,
      toSubmit,
    )
    return { data: response.data, operation: 'create' }
  }

  return useMutation<
    HooksResultInterface<ItemPedidoModel>,
    AxiosError,
    {
      pedidoUuid: string
      item: ItemPedidoModel
    }
  >((data) => saveItemPedido(data.pedidoUuid, data.item), {
    onSuccess(result: HooksResultInterface<ItemPedidoModel>) {
      const acao = buildActionText(result.operation, 'male')
      // toast.dismiss()
      notifications.notifySuccess(`Item ${acao} com sucesso`)
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError() {
      notifications.notifyError(
        'Ocorreu um erro ao tentar processar a requisição',
      )
    },
  })
}

async function salvarItensPedido(
  pedidoUuid: string,
  itens: Array<ItemPedidoModel>,
): Promise<Array<ItemPedidoModel>> {
  const result = await api.post(
    `vendas/pedidos/${pedidoUuid}/itens-pedido`,
    itens,
  )
  return result.data
}

export function useSalvarItensPedido() {
  return useMutation<
    Array<ItemPedidoModel>,
    AxiosError,
    {
      pedidoUuid: string
      itens: Array<ItemPedidoModel>
    }
  >((data) => salvarItensPedido(data.pedidoUuid, data.itens), {
    // onSuccess() {
    //   notifications.notifySuccess(`Operação realizada com sucesso`)
    //   queryClient.invalidateQueries(['PEDIDO'])
    // },
    // onError() {},
  })
}

export function useSubmitCodigoBarras() {
  async function submitCodigoBarras(data: RequestCodigoBarras): Promise<any> {
    const response = await api.post(
      `/vendas/pedidos/${data.pedidoUuid}/barcode`,
      {
        codigoBarras: data.codigoBarras,
        prontaEntrega: data.prontaEntrega,
      },
    )
    return response.data
  }

  return useMutation<RequestCodigoBarras, AxiosError, any>((data) =>
    submitCodigoBarras(data),
  )
}

export async function updateBloqueioItemPedido(
  itensPedidoSelecionados: Array<ItensPedidoSelecionados>,
): Promise<ItemPedidoModel> {
  const response = await api.put(
    `vendas/pedidos/item-pedido/bloqueio-item-pedido`,
    itensPedidoSelecionados,
  )
  return response.data
}

export function useUpdateBloqueioItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    ItemPedidoModel,
    AxiosError,
    {
      itensPedidoSelecionados: Array<ItensPedidoSelecionados>
    }
  >((data) => updateBloqueioItemPedido(data.itensPedidoSelecionados), {
    onSuccess(_: ItemPedidoModel) {
      notifications.notifySuccess('Item alterado com sucesso')
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useUpdateCotacao() {
  const queryClient = useQueryClient()

  async function updateCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const data = {
      observacao: pedido.observacao,
      validadeCotacao: pedido.validadeCotacao,
      tabelaPrecoUuid: pedido.tabelaPrecoUuid || null,
      condicaoPagamentoUuid: pedido?.condicaoPagamentoUuid || null,
      formaPagamentoUuid: pedido?.formaPagamentoUuid || null,
    }

    const response = await api.put<PedidoModel>(
      `/vendas/cotacoes/cotacao/${pedido.uuid}`,
      data,
      { attempts: 3 },
    )

    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => updateCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError() {},
    },
  )
}

export function useSubmitConcluirCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function concluirCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/concluir-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => concluirCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi aprovada com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitAnalisarCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function analisarCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/analisar-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => analisarCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(
          `A situação da cotação foi atualizada para "Análise"`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitFecharNegociacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function fecharNegociacao(
    pedido: PedidoModel,
    descontoAgregado?: DescontoAgregadoModel | null,
  ): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/fechar-negociacao`,
      {
        descontoAgregado,
      },
    )
    return response.data
  }

  return useMutation<
    PedidoModel,
    AxiosError,
    { pedido: PedidoModel; descontoAgregado?: DescontoAgregadoModel | null }
  >((data) => fecharNegociacao(data.pedido, data?.descontoAgregado), {
    onSuccess(_: PedidoModel) {
      notifications.notifySuccess(`A negociação foi fechada com sucesso"`)
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError(error) {
      const errorData = notifications.extractAxiosErrorData(error)
      if (errorData?.code !== 'E_LIMITE_ESTOURADO') {
        notifications.notifyException(error)
      }
    },
  })
}

export function useSubmitReabrirCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function reabrirCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/reabrir-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => reabrirCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi reaberta com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitSolicitarCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function solicitarCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/solicitar-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => solicitarCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi solicitada com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitCancelarSolicitacaoCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function cancelarSolicitacaoCotacao(
    pedido: PedidoModel,
  ): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/cancelar-solicitacao-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => cancelarSolicitacaoCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(
          `A solicitação de cotação foi cancelada com sucesso`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDeleteItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deletarItemPedido(
    data: ItemPedidoModel,
  ): Promise<ItemPedidoModel> {
    await api.delete(`/vendas/pedidos/item-pedido/${data?.uuid}`)
    return data
  }

  return useMutation<any, AxiosError, ItemPedidoModel>(deletarItemPedido, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['PEDIDO'])
      notifications.notifySuccess('O item do pedido foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function useDeletePedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deletarPedido(data: PedidoModel): Promise<PedidoModel> {
    await api.delete(`/vendas/pedidos/${data?.uuid}`)
    return data
  }

  return useMutation<any, AxiosError, PedidoModel>(deletarPedido, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['PEDIDOS'])
      notifications.notifySuccess('O pedido foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function usePerderItem() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function perderItem(
    itemPerdido: ItemPerdidoRequestModel,
  ): Promise<PerderItemModel> {
    const response = await api.post<ItemPerdidoRequestModel>(
      `/crm/item-perdido`,
      itemPerdido,
    )
    return response.data
  }

  return useMutation<PerderItemModel, AxiosError, ItemPerdidoRequestModel>(
    (data) => perderItem(data),
    {
      onSuccess(_: PerderItemModel) {
        notifications.notifySuccess(
          `O item foi marcado como perdido com sucesso`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useVincularEtiquetas() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function vincularEtiquetas(
    data: LinkEtiquetaPedidoModel,
  ): Promise<LinkEtiquetaPedidoModel> {
    await api.post(`/vendas/pedidos/vincular-etiquetas`, data)
    return data
  }

  return useMutation<any, AxiosError, LinkEtiquetaPedidoModel>(
    vincularEtiquetas,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['ETIQUETAS_VINCULADAS'])
        queryClient.invalidateQueries(['ETIQUETAS_NAO_VINCULADAS'])
        notifications.notifySuccess('Etiqueta vinculada com sucesso')
      },
      onError: (err) => {
        notifications.notifyException(err)
      },
    },
  )
}

export function useDesvincularEtiquetas() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function desvincularEtiquetas(
    data: LinkEtiquetaPedidoModel,
  ): Promise<LinkEtiquetaPedidoModel> {
    await api.post(`/vendas/pedidos/desvincular-etiquetas`, data)
    return data
  }

  return useMutation<any, AxiosError, LinkEtiquetaPedidoModel>(
    desvincularEtiquetas,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['ETIQUETAS_NAO_VINCULADAS'])
        queryClient.invalidateQueries(['ETIQUETAS_VINCULADAS'])
        notifications.notifySuccess('Etiqueta desvinculada com sucesso')
      },
      onError: (err) => {
        notifications.notifyException(err)
      },
    },
  )
}

async function obterEtiquetasVinculadas(
  id: string,
): Promise<LinkEtiquetaPedidoModel[]> {
  const response = await api.get(`vendas/pedidos/etiquetas-vinculadas/${id}`)
  return response.data
}

export function useQueryObterEtiquetasVinculadas(id: string) {
  return useTQuery<
    LinkEtiquetaPedidoModel[],
    AxiosError<ErrorInterface, ErrorInterface>
  >(['ETIQUETAS_VINCULADAS', id], () => {
    return obterEtiquetasVinculadas(id)
  })
}

async function obterEtiquetasNaoVinculadas(
  id: string,
): Promise<EtiquetaModel[]> {
  const response = await api.get(
    `vendas/pedidos/etiquetas-nao-vinculadas/${id}`,
  )
  return response.data
}

export function useQueryObterEtiquetasNaoVinculadas(id: string) {
  return useTQuery<EtiquetaModel[], AxiosError<ErrorInterface, ErrorInterface>>(
    ['ETIQUETAS_NAO_VINCULADAS', id],
    () => {
      return obterEtiquetasNaoVinculadas(id)
    },
  )
}

async function obterPedidosInfinite(
  { pageParam = 1 }: { pageParam: number },
  params: HttpRequestInterface<PedidoFilters1>,
  sort: SortProps,
) {
  const { pagination: _pagination, queryParams } = params
  const response = await obterPedidos(
    {
      pagination: { page: pageParam, pageSize: _pagination.pageSize },
      queryParams: queryParams,
    },
    sort,
  )

  const data: PedidoModel[] = response.data
  const pagination = response?.pagination

  return {
    data,
    nextCursor:
      pagination?.lastPage && pageParam < pagination.lastPage
        ? pageParam + 1
        : undefined,
  }
}

export function useInfiniteQueryObterPedidos(
  params: HttpRequestInterface<PedidoFilters1>,
  sort: SortProps,
) {
  return useInfiniteQuery<any, AxiosError>({
    queryKey: ['PEDIDOS', params, sort],
    queryFn: ({ pageParam }) =>
      obterPedidosInfinite({ pageParam }, params, sort),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}
