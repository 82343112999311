import { Stack, StackProps, useTheme } from '@mui/material'

export default function Footer(props: StackProps) {
  const theme = useTheme()
  return (
    <Stack
      component="footer"
      position="absolute"
      direction="row"
      alignItems="center"
      bottom={0}
      left={0}
      right={0}
      padding="8px 16px"
      borderTop={`solid 1px ${theme.palette.grey[300]}`}
      height="48px"
      sx={{ backgroundColor: theme.palette.background.paper }}
      {...props}
    />
  )
}
