import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { OportunidadesPerdidasFilter1 } from 'hooks/queries/useRelatorioOportunidadesPerdidas'
import { useEffect, useMemo } from 'react'

export default function FilterForm() {
  const { user, temPermissao } = useUserContext()
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilter<OportunidadesPerdidasFilter1>()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <Stack spacing={2}>
      <DateRangePicker
        onChange={(value) => {
          changeFilterValue('dateRange', value)
        }}
        value={filterValues?.dateRange}
      />
      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="vendedorUuid"
        label="Vendedor"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        placeholder="Selecione um vendedor"
        InputLabelProps={{ shrink: true }}
      />
      <ClientesTransporter
        label="Cliente"
        name="clienteUuid"
        value={filterValues?.cliente || null}
        vendedorUuid={
          permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
        }
        onChange={(cliente) => {
          changeFilterValue('cliente', cliente)
        }}
        placeholder="Selecione um cliente"
        InputLabelProps={{ shrink: true }}
      />
      <Autocomplete
        multiple
        options={formattedEtiquetas || []}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.etiquetaKeyValue || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) =>
          changeFilterValue('etiquetaKeyValue', value)
        }
        renderInput={(params) => <TextField {...params} label="Etiquetas" />}
        loading={isLoadingEtiquetas}
        loadingText={<CircularProgress />}
      />
    </Stack>
  )
}
