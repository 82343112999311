import { CurrencyCellStyle, DataTable } from '@data-c/ui'
import { Box } from '@mui/material'
import useClientes from 'hooks/queries/useClientes'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo } from 'react'

export interface TableProps {
  clienteUuid: string
}

export default function Table(props: TableProps) {
  const { clienteUuid } = props
  const { useQueryObterPontos } = useClientes()

  const {
    data: cliente,
    isLoading,
    isFetching,
    error,
  } = useQueryObterPontos(clienteUuid)

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoPedido',
        label: 'Cód Pedido',
        options: {
          setCellProps: () => ({
            style: {
              width: '80px',
            },
          }),
        },
      },
      {
        name: 'venda',
        label: 'Data Pedido',
        options: {
          setCellProps: () => ({
            style: {
              width: '80px',
            },
          }),
        },
      },
      {
        name: 'situacaoPedido',
        label: 'Situação Pedido',
        options: {
          setCellProps: () => ({
            style: {
              width: '80px',
            },
          }),
        },
      },
      {
        name: 'totalPedido',
        label: 'Total Pedido',
        options: {
          setCellProps: () => ({
            style: {
              width: '80px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'pontos',
        label: 'Pontos',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'situacaoAsText',
        label: 'Situação',
        options: {
          setCellProps: () => ({
            style: {
              width: '80px',
              textAlign: 'center',
            },
          }),
        },
      },
    ],
    [],
  )

  const pontos = cliente?.pontos?.map((p) => ({
    codigoPedido: p.pedido.codigo,
    totalPedido: p.pedido.totalLiquidoAsBrazilianCurrency,
    situacaoPedido: p.pedido.situacaoAsText,
    venda: p.pedido.vendaAsBrazilianDate,
    // pontos: parseFloat(p.pontos.toString()).toFixed(0),
    pontos: p.pontos.toString(),
    situacao: p.situacao,
    situacaoAsText: p.situacaoAsText,
  }))
  return (
    <Box>
      <DataTable
        error={error?.message}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        data={pontos || []}
      />
    </Box>
  )
}
