import { Stack, TextField } from "@mui/material";

import {
    useFilter,
    useFilterApi,
  } from 'components/MioCandidate/Filter/FilterContext'

export function FilterForm()
{
    const { filterValues } = useFilter()
    const { changeFilterValue } = useFilterApi()

    return (
    
      <Stack spacing={2}>
        <TextField
          name="codigo"
          value={filterValues?.codigo ||''}
          label="codigo"
          onChange={(e) =>{
            changeFilterValue('codigo', e.target.value);
          }}
        />
        <TextField
          name="nome"
          value={filterValues?.nome ||''}
          label="nome"
          onChange={(e) => {
            changeFilterValue('nome', e.target.value);
          }}
        />
      </Stack>
        
    )
}