import { Container } from '@mui/material'
import Content from 'components/Layouts/Content'
import Assistant from 'components/MioCandidate/Assistant'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import { filters1 } from 'hooks/queries/useRelatorioABCProdutos'
import Filter from './components/Filter'
import Table from './components/Table'

export default function ABCProdutos() {
  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="28963057562388" />
      </Assistant.Root>

      <MioFilter.Provider
        filterValues={{
          percentualA: filters1.percentualA,
          percentualB: filters1.percentualB,
          percentualC: filters1.percentualC,
          situacaoPedido: filters1.situacaoPedido,
          dateRange: filters1.dateRange,
          metrica: filters1.metrica,
        }}
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[{ label: 'Curva ABC de Produtos', link: '/abc-produtos' }]}
          ></Breadcrumbs>
          <Filter />
        </ContentTitle.Root>
        <Content>
          <Table />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
