import { useEffect } from 'react'
import { Grid2 as Grid, TextField } from '@mui/material'

import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useValidations from 'hooks/useValidations'

import * as yup from 'yup'
import FormProps from 'interfaces/FormProps'
import useEtiqueta, { EtiquetaModel } from 'hooks/queries/useEtiqueta'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
})

export default function Form(props: FormProps<EtiquetaModel>) {
  const { data: etiqueta, view = 'dialog' } = props

  const { onChangeFormValue, changeValues, closeForm, toggleSubmit } =
    useFormApi<EtiquetaModel>()
  const { formValues: data } = useFormData<EtiquetaModel>()

  useEffect(() => {
    if (etiqueta) {
      changeValues(etiqueta)
    }
  }, [])

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useEtiqueta()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Etiqueta',
        disableRestoreFocus: true,
      }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            <TextField
              autoFocus
              name="nome"
              label="Nome"
              required
              value={data?.nome || ''}
              onChange={onChangeFormValue}
              inputProps={{ maxLength: 255 }}
              {...validationProps('nome')}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}