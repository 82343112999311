import { Typography, TypographyProps, useTheme } from '@mui/material'

export default function Title(props: TypographyProps) {
  const theme = useTheme()

  return (
    <Typography
      component="h2"
      variant="button"
      sx={{
        fontSize: '14px',
        color: theme.palette.grey[500],
        whiteSpace: 'nowrap',
      }}
      {...props}
    />
  )
}
