import { MenuItem, Stack, TextField } from "@mui/material";

import {
    useFilter,
    useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext';
import { AtivoEnum } from "hooks/queries/useFormaPagamento";

export function FilterForm(){
      const { filterValues} = useFilter();
      const { changeFilterValue } = useFilterApi();
    return (
      <Stack spacing={2}>
        <TextField
          autoFocus
          name="codigo"
          value={filterValues?.codigo ||''}
          label="Codigo"
          onChange={(e) =>{changeFilterValue('codigo', e.target.value)}}
        />
        <TextField
          name="nome"
          value={filterValues?.nome||''}
          label="nome"
          onChange={(e) =>{changeFilterValue('nome', e.target.value)}}
        />
        <TextField
          name="ativo"
          onChange={(e) =>{changeFilterValue('ativo', e.target.value)}}
          value={filterValues?.ativo|| ''}
          label="ativo"
          select
        >
          <MenuItem value={AtivoEnum.TODOS}>Todos</MenuItem>
          <MenuItem value={AtivoEnum.ATIVOS}>Ativos</MenuItem>
          <MenuItem value={AtivoEnum.INATIVOS}>Inativos</MenuItem>
        </TextField>
      </Stack>
    )
}