import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import { useUserContext } from 'components/Contexts/UserContext'
import MioFilter from 'components/MioCandidate/Filter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { RelatorioMetaVendasFilters1 } from 'hooks/queries/useRelatorioMetaVendas'
import { useEffect, useMemo } from 'react'
import FilterForm from './FilterForm'
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import { situacoes } from 'hooks/queries/usePedidos'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { filterValues, isOpen, appliedValues } =
    useFilter<RelatorioMetaVendasFilters1>()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Meta de Vendas"
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="tipoData"
            label={`${
              appliedValues?.tipoData === 'emissao'
                ? 'Data de Faturamento'
                : 'Data de venda'
            }`}
          >
            <RadioGroup
              name="tipoData"
              value={filterValues?.tipoData || 'venda'}
              defaultValue="venda"
              row
              onChange={(e) => {
                const key = e.target.value
                changeFilterValue('tipoData', key)
              }}
            >
              <FormControlLabel
                value="venda"
                label="Venda"
                control={<Radio />}
              />
              <FormControlLabel
                value="emissao"
                label="Faturamento"
                control={<Radio />}
              />
            </RadioGroup>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="dateRange"
            label={`${
              appliedValues?.dateRange?.tipo === 'day'
                ? `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
                : 'Periodo: ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                    appliedValues?.dateRange?.secondDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
            }`}
          >
            <fieldset
              style={{
                border: '1px solid #cfcfcf',
                borderRadius: '4px',
              }}
            >
              <legend
                style={{
                  color: '#808080',
                  fontSize: '0.7rem',
                  paddingLeft: '4px',
                  paddingRight: '16px',
                }}
              >
                Período
              </legend>

              <Stack
                sx={{
                  gap: 2,
                  borderRadius: '4px',
                }}
              >
                <DateRangePicker
                  renderFieldSet={false}
                  onChange={(value) => {
                    changeFilterValue('dateRange', value)
                  }}
                  value={filterValues?.dateRange}
                />
              </Stack>
            </fieldset>
          </MioFilter.Chip>
          <MioFilter.Chip
            name="situacao"
            label={
              (appliedValues?.situacao?.length === 1
                ? 'Situação - '
                : 'Situações - ') +
              appliedValues?.situacao?.map((value) => {
                return value.label.toString()
              })
            }
          >
            <Autocomplete
              multiple
              options={situacoes}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.situacao || []}
              disableCloseOnSelect
              onChange={(_, value) => {
                changeFilterValue('situacao', value)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Situação" />
              )}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="representante"
            label={`${'Vendedor - ' + appliedValues?.representante?.nome}`}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="representante"
              label="Representante"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="etiquetaKeyValue"
            label={
              (appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta: '
                : 'Etiquetas: ') +
              appliedValues?.etiquetaKeyValue?.map((value) => {
                return value.nome
              })
            }
          >
            <Autocomplete
              multiple
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>
        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
