import { ButtonProps as MuiButtonProps } from '@mui/material'
import { Button } from '@data-c/ui'

type ButtonProps = {
  isLoading: boolean
} & MuiButtonProps

export default function DialogActionCancelar({
  children,
  ...rest
}: ButtonProps) {
  return (
    <Button variant="contained" {...rest}>
      Salvar
    </Button>
  )
}
