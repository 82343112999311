import {
  SortProps,
  formatCurrency,
  useDialog,
  usePagination,
} from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import { Button, Dialog } from '@data-c/ui'

import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import { useQuery } from 'hooks/queries/useConfiguracoes'

import {
  PedidoFilters1,
  PedidoModel,
  SituacaoPedidoEnum,
  useQueryObterPedidos,
} from 'hooks/queries/usePedidos'

import _ from 'lodash'
import { DataTable, GridColDef } from 'components/MioCandidate/DataTable'

import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Table() {
  const [sort] = useState<SortProps>({
    column: 'venda',
    direction: 'desc',
  })
  const { data: configuracoes } = useQuery()
  const { pagination, changePageSize, changePage } = usePagination()
  const { appliedValues, searchId } = useFilter<PedidoFilters1>()

  const { representante, cliente, dateRange, ...rest } = appliedValues || {}

  const nFilters: PedidoFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }
  const { openConfirm } = useFormApi()
  const {
    data: dataDialog,
    openDialog: openDialog,
    isOpen: isOpenDialog,
    closeDialog: closeDialog,
  } = useDialog()
  const { data, isLoading, isFetching, error } = useQueryObterPedidos(
    {
      queryParams: nFilters,
      pagination,
      requestId: searchId,
    },
    sort,
  )

  const navigate = useNavigate()

  const pedidos = useMemo(() => {
    if (!data?.data) return []
    return data.data.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo || ''} - ${pedido.cliente?.nome}`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
      totalComImposto: formatCurrency(pedido?.totalComImposto || ''),
      // pesoTotal: `${new Intl.NumberFormat('pt-BR', {
      //   minimumFractionDigits: 3,
      // }).format(pedido?.pesoTotal || 0)}Kg`,
      observacao: _.truncate(pedido.observacao, { length: 30 }),
      pedidoRelacionadoCodigo: pedido.pedidoRelacionado?.codigo || '',
      condicaoPagamento: pedido.condicaoPagamento?.nome || '',
      formaPagamento: pedido.formaPagamento?.nome || '',
    }))
  }, [data])

  const totais = useMemo(() => {
    return data?.data?.reduce(
      (acumulador, valorAtual) => {
        const { total, totalNota, pesoTotal } = acumulador
        return {
          total: total + valorAtual.totalLiquido,
          totalNota: totalNota + valorAtual.totalComImposto,
          pesoTotal: pesoTotal + (valorAtual.pesoTotal || 0),
        }
      },
      { total: 0, totalNota: 0, pesoTotal: 0 },
    )
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: PedidoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/pedidos/pedido/${data.uuid}`)
          break
        case 'delete':
          const situacoesQuePodemSerExcluidas = [
            SituacaoPedidoEnum.WEB,
            SituacaoPedidoEnum.MOBILE,
            SituacaoPedidoEnum.COTACAO_SOLICITACAO,
          ]

          if (
            data?.situacao &&
            situacoesQuePodemSerExcluidas.includes(data?.situacao)
          ) {
            return openConfirm(data)
          }

          openDialog(data.situacaoAsText)
          break
      }
    },
    [navigate],
  )

  // function riscarNegociacaoPerdida(indexNegociacao: number) {
  //   const situacao = pedidos[indexNegociacao].situacao
  //   return situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA
  //     ? 'line-through'
  //     : 'none'
  // }

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'codigo',
        headerName: 'Código',
      },
      {
        field: 'pedidoRelacionadoCodigo',
        headerName: 'Cód. Origem',
      },
      {
        field: 'venda',
        headerName: 'Data',
        valueGetter: (_, row: PedidoModel) => {
          return row.vendaAsBrazilianDate
        },
      },
      {
        field: 'situacaoAsText',
        headerName: 'Situação',
      },
      {
        field: 'representante',
        headerName: 'Vendedor',
      },
      {
        field: 'cliente',
        headerName: 'Cliente',
      },
      {
        field: 'formaPagamento',
        headerName: 'Forma Pagamento',
      },
      {
        field: 'condicaoPagamento',
        headerName: 'Condição Pagamento',
      },
      {
        field: 'observacao',
        headerName: 'Observação',
      },
      {
        field: 'pesoTotal',
        type: 'number',
        headerName: `Peso (${new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 3,
        }).format(totais?.pesoTotal || 0)}Kg)`,
        // valueFormatter: (value) =>
        //   new Intl.NumberFormat('pt-BR', {
        //     minimumFractionDigits: 3,
        //   }).format(value || 0),
      },
      {
        field: 'carga',
        type: 'number',
        headerName: `Núm. Carga`,
      },
      {
        field: 'totalLiquido',
        type: 'number',
        headerName: `Total (R$${formatCurrency(totais?.total || 0)})`,
      },
      {
        field: 'totalComImposto',
        type: 'number',
        headerName: `Total Nota (R$${formatCurrency(totais?.totalNota || 0)})`,
      },
      {
        field: 'uuid',
        headerName: 'Ações',
        type: 'actions',
        getActions: ({ row }) => {
          console.log('ROW', row)
          return [
            <DataTable.CellActions
              onClick={(event) => handleClickItem(event, row)}
            >
              <DataTable.CellActionEdit />
              <DataTable.CellActionDelete />
            </DataTable.CellActions>,
          ]
        },
      },
    ],
    [data?.data, handleClickItem, configuracoes],
  )
  return (
    <>
      <DataTable.Root>
        {/* <DataTable.Toolbar>
          <DataTable.Title titleDivider>Negociações</DataTable.Title>
          <DataTable.SelectionCounter countTitle="Produto" />
          <DataTable.Actions breakpoints="lg">
            <DataTable.ExportCsvButton />
          </DataTable.Actions>
        </DataTable.Toolbar> */}
        <DataTable.Table
          data={pedidos || []}
          columns={columns}
          error={error?.message}
          isLoading={isLoading}
          isFetching={isFetching || isLoading}
          onCellKeyDown={handleClickItem}
          onRowDoubleClick={handleClickItem}
          // checkboxSelection
          // onRowSelectionChange={setRowsSelectedId}
          // onClearRowsSelectedId={setRowsSelectedId}
        />
        <DataTable.Footer>
          <DataTable.Pagination
            pagination={data?.pagination}
            onChangePage={changePage}
            onChangePageSize={changePageSize}
          />
        </DataTable.Footer>
      </DataTable.Root>

      <Dialog
        title={`A negociação não está em aberto`}
        maxWidth="xs"
        type="info"
        open={isOpenDialog}
        onClose={closeDialog}
        actions={
          <Button variant="contained" onClick={closeDialog}>
            Voltar
          </Button>
        }
      >
        A situação da negociação é: <strong>{`${dataDialog}`}</strong>.
        <br />
        <br />
        Apenas as negociações <strong>Em aberto</strong> podem ser excluídas!
      </Dialog>
    </>
  )
}
