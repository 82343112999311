import { useMemo, useCallback } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  CurrencyCellStyle,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
//import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useMixProdutos, {
  MixProdutoDescontoModel,
} from 'hooks/queries/useMixProdutos'
import { formatCurrency } from '@data-c/hooks'
import Surface from 'components/Surface'

interface TableProps {
  mixProdutoUuid: string
}

export default function Table(props: TableProps) {
  const { mixProdutoUuid } = props
  const { openForm, openConfirm } = useFormApi()
  const { useQueryObterDescontos } = useMixProdutos()
  const { data, isLoading, isFetching, error } =
    useQueryObterDescontos(mixProdutoUuid)

  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: MixProdutoDescontoModel) => d.uuid === value)
    },
    [data],
  )

  const descontos = useMemo(() => {
    return data?.data.map((d) => {
      return {
        ...d,
        preco: formatCurrency(d.preco),
        pdesconto: formatCurrency(d.pdesconto),
      }
    })
  }, [data?.data])

  const tipoDesconto =
    Array.isArray(data?.data) && data.data.length > 0
      ? data.data[0].pdesconto > 0
        ? 'percentual'
        : 'preco'
      : ''

  const handleClickItem = useCallback(
    (event: 'delete', data: MixProdutoDescontoModel) => {
      switch (event) {
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'quantidadeMinima',
        label: 'Quantidade Mínima',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'pdesconto',
        label: 'Desconto(%)',
        options: {
          display: tipoDesconto === 'percentual',
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'preco',
        label: 'Preço',
        options: {
          display: tipoDesconto === 'preco',
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayDeleteButton={true}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={descontos || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        pagination: false,
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
