import { Box, Typography } from '@mui/material'

interface CardItensProps {
  label: string
  conteudo: string | number
}

export function ItemValues({ label, conteudo }: CardItensProps) {
  return (
    <Box textAlign={'center'}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body1">{conteudo}</Typography>
    </Box>
  )
}
