import { Grid2 as Grid, TextField } from '@mui/material'
import { Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import useValidations from 'hooks/useValidations'

import * as yup from 'yup'
import useMotivosDePerda, {
  MotivosDePerdaModel,
} from 'hooks/queries/useMotivosDePerda'

const schema = yup.object().shape({
  descricao: yup.string().required('Informe a descrição'),
})

export default function Form() {
  const { onChangeFormValue, toggleSubmit, closeForm } =
    useFormApi<MotivosDePerdaModel>()

  const { formValues: data } = useFormData<MotivosDePerdaModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useMotivosDePerda()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Motivos de Perda' }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <TextField
            name="descricao"
            label="Motivo"
            required
            value={data?.descricao || ''}
            onChange={onChangeFormValue}
            {...validationProps('descricao')}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <Checkbox
            label="Deve informar observação"
            name="informarObservacao"
            onChange={onChangeFormValue}
            checked={Boolean(data?.informarObservacao)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}