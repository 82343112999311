import { Grid2 as Grid, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import { Checkbox, FormContainer } from '@data-c/ui'
import useValidations from 'hooks/useValidations'
import useGrupoProdutos, {
  GrupoProdutosModel,
} from 'hooks/queries/useGrupoProdutos'
import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

export default function Form() {
  const { formValues: data } = useFormData<GrupoProdutosModel>()
  const { onChangeFormValue, toggleSubmit, closeForm } =
    useFormApi<GrupoProdutosModel>()
  const { useSubmit } = useGrupoProdutos()
  const { mutateAsync } = useSubmit()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmitForm}
      dialogProps={{
        maxWidth: 'sm',
        title: ' Grupo de Produtos',
        disableRestoreFocus: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 3, md: 2, lg: 2, xl: 2 }}>
          <TextField name="codigo" label="Código" disabled />
        </Grid>
        <Grid size={{ xs: 12, sm: 9, md: 10, lg: 10, xl: 10 }}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}