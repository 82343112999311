import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'

interface SliderProps {
  children: ReactNode
  sx?: SxProps
}

export default function Slider(props: SliderProps) {
  const { children, sx } = props

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        paddingY: 0.5,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
