import { Container } from '@mui/material'

import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'

import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import { filters1 } from 'hooks/queries/useRelatorioComparacaoPerformanceProdutos'

import Assistant from 'components/MioCandidate/Assistant'

export default function ComparacaoPerformanceProdutos() {
  return (
    <Container>
      <Assistant.Root>
        <Assistant.Article open={true} articleId="29374051203988" />
      </Assistant.Root>
      <MioFilter.Provider
        filterValues={{
          situacao: filters1.situacao,
          dateRange: filters1.dateRange,
          tipoDeCalculo: filters1.tipoDeCalculo,
        }}
      >
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Performance de Produtos',
                link: 'relatorio-performance-produtos',
              },
            ]}
          />
          <Filter />
        </ContentTitle.Root>
        <Content>
          <Table />
        </Content>
      </MioFilter.Provider>
    </Container>
  )
}
