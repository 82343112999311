import { GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid'

type CellActionProps = {
  event?: string
  onClick?: (event?: string) => void
} & GridActionsCellItemProps

export default function CellAction(props: CellActionProps) {
  const { event, onClick, ...rest } = props

  return (
    <GridActionsCellItem
      color="primary"
      onClick={() => {
        if (onClick) onClick(event)
      }}
      {...rest}
    />
  )
}
