import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import { Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import useTabs from 'hooks/useTabs'
import { HeaderPedido } from '../HeaderPedido'
import ItensPedido from '../ItensPedido'
import Section from 'components/MioCandidate/Section'
interface MostrarPedidosOuDetalhesProps {
  pedido: any
  configuracoes: any
  usuarioLogado: any
  isLoading: boolean
  isFetching: boolean
}

export function TabsMostrarPedidosOuDetalhes({
  pedido,
  configuracoes,
  usuarioLogado,
  isLoading,
  isFetching,
}: MostrarPedidosOuDetalhesProps) {
  const { value, handleChange } = useTabs()

  return (
    <>
      <Tabs
        value={value}
        sx={{
          height: '48px',
          width: '100%',
        }}
        onChange={handleChange}
        aria-label="tabs-navegacao-mobile"
      >
        <Tab
          icon={<ViewCompactIcon />}
          sx={{ height: '48px', width: '50%' }}
          label={'Detalhes'}
        />
        <Tab
          icon={<ImportContactsIcon />}
          sx={{ height: '48px', width: '50%' }}
          label={'Produtos'}
        />
      </Tabs>
      <Section sx={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <HeaderPedido pedido={pedido} configuracoes={configuracoes} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ItensPedido
            pedido={pedido}
            usuarioLogado={usuarioLogado || ({} as UsuarioModel)}
            isLoading={isLoading}
            isFetching={isFetching}
            configuracoes={configuracoes}
          />
        </TabPanel>
      </Section>
    </>
  )
}
