import {
  Grid2 as Grid,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Box,
  Badge,
  useTheme,
  Stack,
  IconButton,
} from '@mui/material'
import { ButtonContainer, Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import useValidations from 'hooks/useValidations'
import useCrmAtividades, {
  CrmAtividadesModel,
  TipoAtividade,
} from 'hooks/queries/useCrmAtividades'
import * as yup from 'yup'
import DatePicker from 'components/DatePicker'
import TimePicker from 'components/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import TimeVendasTranporter from 'components/Transporters/TimeVendasTranporter'
import { TimeVendasModel } from 'hooks/queries/useTimeVendas'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import { ClientesModel } from 'hooks/queries/useClientes'
import PedidoTransporter from 'components/Transporters/PedidosTransporter'
import { PedidoModel } from 'hooks/queries/usePedidos'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import LembreteNotificacao from './components/LembreteNotificacao'
import { useQueryClient } from '@tanstack/react-query'
import TabPanel from 'components/TabPanel'
import { useSearchParams } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import useDeviceDetect from 'hooks/useDeviceDetect'
import Comentarios from 'components/Comentarios'
import { DateTime } from 'luxon'
import TextField from 'components/Inputs/TextField'
import useTabs from 'hooks/useTabs'

const schema = yup.object().shape({
  assunto: yup.string().required('Informe o Assunto'),
  responsavelUuid: yup.string().nullable().required('Informe o Atribuído para'),
})

interface FormProps {
  pedido?: PedidoModel
  atividade?: CrmAtividadesModel
  plainMode?: boolean
  fullScreen?: boolean
}

export default function Form(props: FormProps) {
  const { pedido, plainMode, atividade, fullScreen } = props
  const { user } = useUserContext()
  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    toggleSubmit,
    openForm,
    closeForm,
  } = useFormApi<CrmAtividadesModel>()
  const { isWebView } = useDeviceDetect()
  const { formValues: data, isOpen } = useFormData<CrmAtividadesModel>()
  const { value: tabValue, handleChange, setValue: setTabValue } = useTabs()
  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useCrmAtividades()
  const { mutateAsync } = useSubmit()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (atividade) {
      changeValues(atividade)
    }
  }, [atividade])

  useEffect(() => {
    if (!Boolean(user?.isadmin) && isOpen) {
      changeValue('responsavel', user)
      changeValue('responsavelUuid', user?.uuid || null)
    }
  }, [user, isOpen])

  useEffect(() => {
    if (isOpen === false) {
      setTabValue(0)
    } else {
      if (searchParams.get('tab') === 'comentarios') {
        setTabValue(1)
      }
    }
  }, [isOpen, searchParams])

  useEffect(() => {
    if (isOpen === false) {
      searchParams.delete('id')
      searchParams.delete('tab')
      setSearchParams(searchParams)
    }
  }, [isOpen])

  useEffect(() => {
    if (data?.diaInteiro) {
      changeValue('horaInicio', '00:00')
      changeValue('horaFim', '23:59')
    }
  }, [data?.diaInteiro])

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const atividade = await mutateAsync(data)
        changeValue('uuid', atividade.data.uuid)
        queryClient.invalidateQueries(['LEMBRETE_ATIVIDADE'])
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  function handleOpenForm() {
    const curDate = DateTime.now().plus({ hour: 1 })
    const nextDate = DateTime.now().plus({ hour: 2 })
    const initialData: CrmAtividadesModel = {
      tipoAtividade: TipoAtividade.LIGAR,
      dataInicio: curDate.toFormat('yyyy-MM-dd'),
      dataFim: nextDate.toFormat('yyyy-MM-dd'),
      horaInicio: curDate.toFormat('HH:00'),
      horaFim: nextDate.toFormat('HH:00'),
      pedido: pedido || null,
      pedidoUuid: pedido?.uuid || null,
      responsavelUuid: pedido?.representante?.uuid || null,
      responsavel: pedido?.representante || null,
      leadUuid: pedido?.cliente?.uuid || null,
      lead: pedido?.cliente || null,
      status: 'SEM_ATIVIDADE',
    }
    openForm(initialData)
  }

  const exibeLembreteNotificacao = data?.uuid && !data?.concluido
  const exibeHoraInicioEFim = !data?.diaInteiro
  const atividadeFoiConcluida = data?.concluido === true

  const comentarioStatus: {
    totalComentarios: number
    totalNaoLidas: number
  } = data?.comentarioStatus
    ? data?.comentarioStatus
    : { totalComentarios: 0, totalNaoLidas: 0 }

  const badgeStyle = {
    position: 'absolute',
    top: '-2px',
    right: '-6px',
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Atividade',
        maxWidth: 'md',
        fullScreen: fullScreen,
      }}
      view={plainMode ? 'plain' : 'dialog'}
      onSubmitForm={handleSubmitForm}
      triggerButton={
        isWebView ? (
          <IconButton
            size="medium"
            onClick={handleOpenForm}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <Add fontSize="inherit" />
          </IconButton>
        ) : (
          <Button variant="contained" onClick={handleOpenForm}>
            Adicionar
          </Button>
        )
      }
      actions={
        <ButtonContainer>
          {tabValue === 0 && (
            <>
              <Button
                color="error"
                onClick={() => {
                  closeForm()
                }}
              >
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleSubmitForm}>
                Salvar
              </Button>
            </>
          )}
        </ButtonContainer>
      }
    >
      <Stack spacing={2}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="tabs vendedores"
          variant="fullWidth"
        >
          <Tab label="Cadastro" value={0} />
          <Tab
            label={
              <Box sx={{ position: 'relative' }}>
                Comentários
                {comentarioStatus?.totalNaoLidas > 0 && (
                  <Badge
                    sx={{
                      ...badgeStyle,
                      '& .MuiBadge-badge': {
                        color: theme.palette.background.paper,
                      },
                    }}
                    badgeContent={comentarioStatus?.totalNaoLidas}
                    color="error"
                  />
                )}
                {comentarioStatus?.totalNaoLidas <= 0 &&
                  comentarioStatus.totalComentarios > 0 && (
                    <Badge sx={badgeStyle} variant="dot" color="primary" />
                  )}
              </Box>
            }
            value={1}
          />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
              <TextField
                label="Atividade"
                name="tipoAtividade"
                onChange={onChangeFormValue}
                value={data?.tipoAtividade || TipoAtividade.LIGAR}
                select
                disabled={atividadeFoiConcluida}
              >
                <MenuItem value={TipoAtividade.LIGAR}>Ligação</MenuItem>
                <MenuItem value={TipoAtividade.REUNIAO}>Reunião</MenuItem>
                <MenuItem value={TipoAtividade.TAREFA}>Tarefa</MenuItem>
                <MenuItem value={TipoAtividade.PRAZO}>Prazo</MenuItem>
                <MenuItem value={TipoAtividade.EMAIL}>E-mail</MenuItem>
                <MenuItem value={TipoAtividade.ALMOCO}>Evento</MenuItem>
              </TextField>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
              <TextField
                label={'Assunto'}
                name="assunto"
                required
                onChange={onChangeFormValue}
                value={data?.assunto || ''}
                {...validationProps('assunto')}
                disabled={atividadeFoiConcluida}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
              <DatePicker
                label="Data Início"
                onChange={(dataInicio) => {
                  changeValue('dataInicio', dataInicio)
                }}
                value={data?.dataInicio || null}
                disabled={atividadeFoiConcluida}
                slotProps={{
                  textField: {
                    name: 'dataInicio',
                    ...validationProps('dataInicio'),
                  },
                }}
              />
            </Grid>
            {exibeHoraInicioEFim && (
              <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
                <TimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  label="Hora Início"
                  format="HH:mm"
                  onChange={(horaInicio) => {
                    changeValue('horaInicio', horaInicio)
                  }}
                  value={data?.horaInicio || null}
                  disabled={atividadeFoiConcluida}
                />
              </Grid>
            )}
            <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
              <DatePicker
                label="Data Fim"
                onChange={(dataFim) => {
                  changeValue('dataFim', dataFim)
                }}
                value={data?.dataFim || null}
                disabled={atividadeFoiConcluida}
                slotProps={{
                  textField: {
                    name: 'dataFim',
                    ...validationProps('dataFim'),
                  },
                }}
              />
            </Grid>
            {exibeHoraInicioEFim && (
              <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
                <TimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  label="Hora Fim"
                  format="HH:mm"
                  onChange={(horaFim) => {
                    changeValue('horaFim', horaFim)
                  }}
                  value={data?.horaFim || null}
                  disabled={atividadeFoiConcluida}
                />
              </Grid>
            )}
            {exibeLembreteNotificacao && (
              <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                <LembreteNotificacao
                  entidadeUuid={data.uuid || ''}
                  notificacoes={data?.notificacoes || []}
                />
              </Grid>
            )}
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <Checkbox
                label="Dia inteiro"
                name="diaInteiro"
                onChange={onChangeFormValue}
                disabled={atividadeFoiConcluida}
                checked={Boolean(data?.diaInteiro)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <TextField
                label="Descrição"
                name="descricao"
                onChange={onChangeFormValue}
                value={data?.descricao || ''}
                multiline
                minRows={4}
                disabled={atividadeFoiConcluida}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <PedidoTransporter
                label="Pedido / Negociação"
                name="pedidoUuid"
                onChange={(value: PedidoModel | null) => {
                  changeValues({
                    ...data,
                    pedidoUuid: value?.uuid || null,
                    pedido: value || null,
                    responsavelUuid: value?.vendedor || null,
                    responsavel: value?.representante || null,
                    leadUuid: value?.pessoa || null,
                    lead: value?.cliente || null,
                  })
                }}
                value={data?.pedido || null}
                disabled={!!pedido?.uuid || atividadeFoiConcluida}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <TimeVendasTranporter
                required
                disabled={!user?.isadmin || atividadeFoiConcluida}
                label="Atribuído para"
                name="responsavelUuid"
                onChange={(value: TimeVendasModel | null) => {
                  changeValues({
                    ...data,
                    responsavelUuid: value?.uuid || null,
                    responsavel: value || null,
                  })
                }}
                value={data?.responsavel || null}
                {...validationProps('responsavelUuid')}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <ClientesTransporter
                label="Cliente / lead"
                name="leadUuid"
                disabled={Boolean(data?.pedido) || atividadeFoiConcluida}
                onChange={(value: ClientesModel | null) => {
                  changeValues({
                    ...data,
                    leadUuid: value?.uuid || null,
                    lead: value || null,
                  })
                }}
                value={data?.lead || null}
                vendedorUuid={data?.responsavelUuid || undefined}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2, md: 2, lg: 2, xl: 2 }}>
              <Checkbox
                label="Marcar concluído"
                name="concluido"
                onChange={onChangeFormValue}
                checked={Boolean(data?.concluido)}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {data?.uuid && <Comentarios atividadeUuid={data?.uuid || ''} />}
        </TabPanel>
      </Stack>
    </FormContainer>
  )
}