//import { useFilterApi, useFilterData } from '@data-c/providers'
import { MenuItem, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import DropDownCrmSituacao from 'components/Inputs/DropDownCrmSituacao'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import PedidoTransporter from 'components/Transporters/PedidosTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import {
  CrmAtividadesFilters1,
  TipoAtividade,
} from 'hooks/queries/useCrmAtividades'
import { useEffect } from 'react'

import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { DateTime } from 'luxon'
import { FilterForm } from './FilterForm'

export default function Filter() {
  const { onChangeFilterValue, changeFilterValue, applyFilterValues } =
    useFilterApi()
  const { user, temPermissao } = useUserContext()
  const { appliedValues, filterValues, isOpen } =
    useFilter<CrmAtividadesFilters1>()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('responsavel', user)
    }
  }, [permissaoParaLerClientes, isOpen])
  return (
    <>
      <MioFilter.Root>
        <MioFilter.Container>
          <MioFilter.SearchTextField
            onChange={(query) => {
              changeFilterValue('plainQuery', query)
              applyFilterValues()
            }}
            fullWidth
            autoFocus
            placeholder="Pesquisar por Atividades"
          />

          <MioFilter.Chips>
            <MioFilter.Chip
              name="dateRange"
              label={
                appliedValues?.dateRange?.tipo === 'day'
                  ? DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd-MM-yyyy')
                  : ` Período - ${DateTime.fromFormat(
                      appliedValues?.dateRange?.firstDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                      appliedValues?.dateRange?.secondDate || '',
                      'yyyy-MM-dd',
                    ).toFormat('dd/MM/yyyy')}`
              }
            >
              <DateRangePicker
                onChange={(value) => {
                  changeFilterValue('dateRange', value)
                }}
                value={filterValues?.dateRange}
              />
            </MioFilter.Chip>
            <MioFilter.Chip name="assunto" label={appliedValues?.assunto}>
              <TextField
                name="assunto"
                onChange={onChangeFilterValue}
                value={filterValues?.assunto || ''}
                label="Assunto"
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              name="tipoAtividade"
              label={'Tipo de Atividade -' + appliedValues?.tipoAtividade}
            >
              <TextField
                name="tipoAtividade"
                onChange={onChangeFilterValue}
                value={filterValues?.tipoAtividade || ''}
                label="Tipo de Atividade"
                select
              >
                <MenuItem value={TipoAtividade.TODAS}>Todas</MenuItem>
                <MenuItem value={TipoAtividade.LIGAR}>Ligação</MenuItem>
                <MenuItem value={TipoAtividade.REUNIAO}>Reunião</MenuItem>
                <MenuItem value={TipoAtividade.TAREFA}>Tarefa</MenuItem>
                <MenuItem value={TipoAtividade.PRAZO}>Prazo</MenuItem>
                <MenuItem value={TipoAtividade.EMAIL}>E-mail</MenuItem>
                <MenuItem value={TipoAtividade.ALMOCO}>Evento</MenuItem>
              </TextField>
            </MioFilter.Chip>
            <MioFilter.Chip
              name="situacao"
              label={'Situação - ' + appliedValues?.situacao}
            >
              <DropDownCrmSituacao
                name="situacao"
                value={filterValues?.situacao || ''}
                label="situação"
                onChange={(e) => changeFilterValue('situacao', e.target.value)}
              />
            </MioFilter.Chip>
            <MioFilter.Chip name="pedido" label={appliedValues?.pedido?.uuid}>
              <PedidoTransporter
                name="pedidoUuid"
                value={filterValues?.pedido || null}
                label="Id"
                onChange={(pedido) => {
                  changeFilterValue('pedido', pedido)
                }}
                slotProps={{ inputLabel: { shrink: true } }}
              />
            </MioFilter.Chip>
            <MioFilter.Chip
              name="responsavel"
              label={appliedValues?.responsavel?.nome}
            >
              <TimeVendasTransporter
                name="vendedorUuid"
                disabled={temPermissao('clientes.read') === 'pessoal'}
                label="Atribuido para"
                value={filterValues?.responsavel || null}
                onChange={(responsavel) => {
                  changeFilterValue('responsavel', responsavel)
                }}
                slotProps={{ inputLabel: { shrink: true } }}
              />
            </MioFilter.Chip>
            <MioFilter.Chip name="lead" label={appliedValues?.lead?.nome || ''}>
              <ClientesTransporter
                label="Lead"
                name="lead"
                value={filterValues?.lead || null}
                vendedorUuid={
                  permissaoParaLerClientes === 'pessoal'
                    ? user?.uuid
                    : undefined
                }
                onChange={(lead) => {
                  changeFilterValue('lead', lead)
                }}
                slotProps={{ inputLabel: { shrink: true } }}
              />
            </MioFilter.Chip>
          </MioFilter.Chips>
          <MioFilter.AdvancedButton />
        </MioFilter.Container>
        <MioFilter.AdvancedContainer>
          <FilterForm />
          <MioFilter.AdvancedActionsContainer>
            <MioFilter.ResetButton />
            <MioFilter.ApplyButton />
          </MioFilter.AdvancedActionsContainer>
        </MioFilter.AdvancedContainer>
      </MioFilter.Root>
    </>
  )
}
