import { ButtonContainer } from '@data-c/ui'
import {
  Container,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { memo, useState } from 'react'

import { Groups, Hail } from '@mui/icons-material'
import Icon from 'components/MioCandidate/Icon'
import Maps from './components/Maps'
import TableClientes from './components/TableClientes'
import TableVendedores from './components/TableVendedores'

function Table() {
  const [view, setView] = useState<'analitico' | 'sintetico' | 'visaoMapa'>(
    'analitico',
  )

  return (
    <Container>
      <ButtonContainer>
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={(_, value) => {
            setView(value)
          }}
          aria-label="text alignment"
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              minWidth: '120px',
            },
          }}
        >
          <ToggleButton
            size="small"
            value="analitico"
            aria-label="left aligned"
            color="primary"
          >
            <Stack direction="row" spacing={1}>
              <Groups />
              <Typography variant="button">Clientes</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value="sintetico" color="primary">
            <Stack direction="row" spacing={1}>
              <Hail /> <Typography variant="button">Vendedor</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value="visaoMapa" color="primary">
            <Stack direction="row" spacing={1}>
              <Icon.Maps />
              <Typography variant="button">Geolocalização</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonContainer>

      {view === 'analitico' && <TableClientes />}
      {view === 'sintetico' && <TableVendedores />}
      {view === 'visaoMapa' && <Maps />}
    </Container>
  )
}

export default memo(Table)
