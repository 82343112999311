import { useEffect, useMemo, useState } from 'react'

import { Grid2 as Grid } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, Flag, FormContainer } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import SearchTextField from 'components/SearchTextField'
import useVendedores from 'hooks/queries/useVendedores'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { useFormApi, useFormData } from '@data-c/providers'

interface FormProps {
  vendedorUuid: string
}

function temClientesSelecionados(
  indexClientesSelecionados: Array<string | undefined>,
): boolean {
  return indexClientesSelecionados.length > 0
}

export default function Form(props: FormProps) {
  const { vendedorUuid } = props

  const [query, setQuery] = useState('')
  const [indexClientesSelecionados, setIndexClientesSelecionados] = useState<
    number[]
  >([])

  const { isOpen } = useFormData()
  const { toggleSubmit, closeForm } = useFormApi()
  const { pagination, changePage, changePageSize } = usePagination()
  const { useQueryObterClientesNaoVinculados, useVincularClientes } =
    useVendedores()
  const {
    data: clientesNaoVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryObterClientesNaoVinculados({
    plainQuery: query,
    vendedorUuid,
    pagination,
  })

  const { mutateAsync: vincularClientes } = useVincularClientes()

  useEffect(() => {
    if (isOpen === false) {
      setIndexClientesSelecionados([])
    }
  }, [isOpen])

  const formattedData =
    useMemo(
      () =>
        clientesNaoVinculados?.data?.map((clienteNaoVinculado) => {
          return {
            ...clienteNaoVinculado,
            cnpjcpf: clienteNaoVinculado.cnpjcpf
              ? formatToCPFOrCNPJ(clienteNaoVinculado.cnpjcpf)
              : '',
          }
        }),
      [clientesNaoVinculados],
    ) || []

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },
      {
        name: 'nome',
        label: 'Razão Social',
      },
      {
        name: 'usual',
        label: 'Nome Fantasia',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  async function handleVincularClientes() {
    const clientesSelecionadosIds = indexClientesSelecionados.map(
      (index) => clientesNaoVinculados?.data[index]?.uuid || '',
    )

    if (vendedorUuid && temClientesSelecionados(clientesSelecionadosIds)) {
      toggleSubmit(true)
      try {
        await vincularClientes({
          vendedorUuid,
          clientesSelecionadosIds,
        })
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  return (
    <FormContainer
      onSubmitForm={handleVincularClientes}
      triggerButtonLabel="Vincular"
      confirmButtonLabel="Vincular"
      dialogProps={{ title: 'Clientes', maxWidth: 'lg' }}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <SearchTextField
            placeholder="Pesquisar clientes"
            onSearch={handleSearch}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <DataTable
            error={error?.message}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            data={formattedData}
            changePage={changePage}
            changePageSize={changePageSize}
            pagination={clientesNaoVinculados?.pagination}
            options={{
              rowsPerPageOptions: [15, 50, 100],
              rowsPerPage: 15,
              selectableRows: 'multiple',
              selectableRowsOnClick: true,
              rowsSelected: indexClientesSelecionados,
              onRowSelectionChange: (_, __, rowsSelected: any) =>
                setIndexClientesSelecionados(rowsSelected),
              selectToolbarPlacement: 'none',
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}