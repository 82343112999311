import { FilterContainer } from '@data-c/ui'
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { OportunidadesPerdidasFilter1 } from 'hooks/queries/useRelatorioOportunidadesPerdidas'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'

import MioFilter from 'components/MioCandidate/Filter'
import FilterForm from './FilterForm'

export default function Filter() {
  const { user, temPermissao } = useUserContext()
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { filterValues, isOpen, appliedValues } =
    useFilter<OportunidadesPerdidasFilter1>()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Previsões de entrega"
        />
        <MioFilter.Chips>
          <MioFilter.Chip
            name="dateRange"
            label={
              appliedValues?.dateRange?.tipo === 'day'
                ? 'Dia: ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
                : 'Periodo: ' +
                  `${DateTime.fromFormat(
                    appliedValues?.dateRange?.firstDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
                    appliedValues?.dateRange?.secondDate || '',
                    'yyyy-MM-dd',
                  ).toFormat('dd/MM/yyyy')}`
            }
          >
            <DateRangePicker
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={filterValues?.dateRange}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="representante"
            label={'Vendedor - ' + appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="vendedorUuid"
              label="Vendedor"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              placeholder="Selecione um vendedor"
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="cliente"
            label={'Cliente - ' + appliedValues?.cliente?.nome}
          >
            <ClientesTransporter
              label="Cliente"
              name="clienteUuid"
              value={filterValues?.cliente || null}
              vendedorUuid={
                permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
              }
              onChange={(cliente) => {
                changeFilterValue('cliente', cliente)
              }}
              placeholder="Selecione um cliente"
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>
          <MioFilter.Chip
            name="etiquetaKeyValue"
            label={
              (appliedValues?.etiquetaKeyValue?.length === 1
                ? 'Etiqueta: '
                : 'Etiquetas: ') +
              appliedValues?.etiquetaKeyValue?.map((value) => {
                return value.label.toString()
              })
            }
          >
            <Autocomplete
              multiple
              options={formattedEtiquetas || []}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.etiquetaKeyValue || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('etiquetaKeyValue', value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Etiquetas" />
              )}
              loading={isLoadingEtiquetas}
              loadingText={<CircularProgress />}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
