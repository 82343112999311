import { Close } from '@mui/icons-material'
import { Drawer, IconButton, Stack } from '@mui/material'
import Divider from 'components/Divider'
import { Data } from 'hooks/queries/usePositivacaoClientes'

import ListagemPedidosporCliente from './components/ListagemPedidosPorCliente'
import InfoCliente from './components/InfoCliente'
import { FormProvider } from '@data-c/providers'
import { useDeletePedido } from 'hooks/queries/usePedidos'
import Delete from 'components/Delete'

interface DrawerListagemPedidosProps {
  isOpenDrawer: boolean
  onClose: () => void
  cliente?: Data
}
export default function DrawerListagemPedidos(
  props: DrawerListagemPedidosProps,
) {
  const { isOpenDrawer, onClose, cliente } = props
  const { mutateAsync: handleDelete } = useDeletePedido()

  return (
    <Drawer
      variant="temporary"
      open={isOpenDrawer}
      onClose={onClose}
      anchor="right"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        '& .MuiDrawer-paper': {
          zIndex: (theme) => theme.zIndex.drawer + 3,
          overflow: 'scroll',
          scrollbarWidth: 'none',
        },
      }}
      PaperProps={{ style: { maxWidth: '426px' } }}
    >
      <FormProvider>
        <Stack alignItems="center" direction={'row'}>
          <Stack p={1}>
            <InfoCliente
              clienteProcurado={cliente || ({} as Data)}
            ></InfoCliente>
          </Stack>
          <Stack>
            <IconButton
              onClick={onClose}
              sx={{ position: 'absolute', right: 1, top: 1 }}
            >
              <Close />
            </IconButton>
          </Stack>
        </Stack>
        <Divider></Divider>
        <ListagemPedidosporCliente clienteId={cliente?.uuid || ''} />
        <Delete onDelete={handleDelete}></Delete>
      </FormProvider>
    </Drawer>
  )
}
