import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from './Form'
import Filter from './Filter'

import { useUserContext } from 'components/Contexts/UserContext'
import DuplicatasTable from 'components/Tables/DuplicatasTable'
import MioFilter from 'components/MioCandidate/Filter'
import { filtersDuplicatas } from 'hooks/queries/useClientes'

interface TabDuplicatasProps {
  pessoaUuid: string
}

export default function TabDuplicatas(props: TabDuplicatasProps) {
  const { temPermissao } = useUserContext()
  return (
    <Box>
      <MioFilter.Provider
        filterValues={{
          dateRange: filtersDuplicatas.dateRange?.value,
          tipoData: filtersDuplicatas.tipoData.value,
        }}
      >
        <ButtonContainer sx={{ mb: 2 }}>
          <Filter />
          {temPermissao('clientes.liberar_inadimplencia_cliente') && (
            <Form pessoaUuid={props.pessoaUuid} />
          )}
        </ButtonContainer>
        <DuplicatasTable pessoaUuid={props.pessoaUuid} />
      </MioFilter.Provider>
    </Box>
  )
}
