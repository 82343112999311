import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'

import useFatorPrecificacao from 'hooks/queries/useFatorPrecificacao'

import Delete from 'components/Delete'
import Content from 'components/Layouts/Content'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import Form from './components/Form'
import Table from './components/Table'

export default function FatorPrecificacao() {
  const { useDelete } = useFatorPrecificacao()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider>
       <MioFilter.Provider filterValues={{}}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Fator de Precificação',
                link: '/fator-precificacao',
              },
            ]}
          />
          <ButtonContainer>
            <Form />
          </ButtonContainer>
        </ContentTitle.Root>
        <Content>
          <Table />
          <Delete onDelete={handleDelete} />
        </Content>     
       </MioFilter.Provider>
      </FormProvider>        
    </Container>
  )
}
