import { Button, ButtonProps } from '@mui/material'
import { DialogComponent, useDialogs } from '@toolpad/core/useDialogs'

interface DialogAdicionarButtonProps<T> extends Omit<ButtonProps, 'onClick'> {
  dialogComponent: DialogComponent<any, any>
  payload: T
}

export default function DialogAdicionarButton<T>(
  props: DialogAdicionarButtonProps<T>,
) {
  const { dialogComponent: DialogComponent, payload, ...rest } = props
  const dialogs = useDialogs()

  return (
    <Button
      variant="contained"
      onClick={() => {
        dialogs.open(DialogComponent, payload)
      }}
      {...rest}
    >
      Adicionar
    </Button>
  )
}
