import { Stack, Typography } from '@mui/material'

interface ItemValueProps {
  label?: string
  conteudo: string | number
  direction?: 'column' | 'row'
}

export function ItemValues({
  label,
  conteudo,
  direction = 'column',
}: ItemValueProps) {
  return (
    <Stack spacing={0.5} direction={`${direction}`}>
      {label && <Typography variant="subtitle2">{label}</Typography>}
      <Typography variant="body1">{conteudo}</Typography>
    </Stack>
  )
}
